import { useRef } from 'react';

import { 
  If, 
  createComponent, 
  toClassName, 
  IntrinsicProps 
} from '@/common/util/templateHelpers';
import useInput from '@/common/hooks/input';
import { toBase64 } from '@/common/util/toBase64';

import Field, { FieldHelp, FieldLabel } from '@/common/components/controls/Field';
import Control from '@/common/components/controls/Control';

export enum InputType {
  ANY = '',
  COLOR = 'color',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  DECIMAL = 'decimal',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  NUMBER = 'number',
  MONTH = 'month',
  PASSWORD = 'password',
  PHONE_NUMBER = 'phoneNumber',
  SEARCH = 'search',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week'
}

export enum InputFileFormat {
  FORMDATA = 'formData',
  BASE64 = 'base64'
}

export enum InputFileType {
  IMAGE = 'image/*',
  VIDEO = 'video/*',
  AUDIO = 'audio/*'
}

interface InputAttributes { 
  min?: number
  max?: number
  step?: number
  multiple?: boolean
  accept?: InputFileType
  dataAttrs?: Record<string, string|number|boolean>
}

function getAttrs (type, props) {
  const params = {
    type: 'text',
    inputMode: null,
    attrs: {} as InputAttributes,
    dataAttrs: {}
  }

  if(typeof props.dataAttrs === 'object') {
    for(const [key, value] of Object.entries(props.dataAttrs as Record<string, string|number|boolean>)) {
      // Reject any supposed data attributes that don't start with "data-"
      if(!key.startsWith('data-')) continue;
  
      params.dataAttrs[key] = value;
    }
  }

  switch (type) {
    case InputType.COLOR:
      params.type = 'color';
      break;

    case InputType.DATE:
      params.type = 'date';
      break;

    case InputType.DATE_TIME:
      params.type = 'datetime-local';
      break;

    case InputType.DECIMAL:
      params.type = 'number';
      params.inputMode = 'decimal';
      params.attrs = {
        min: props.min || 0,
        max: props.max || Number.MAX_VALUE,
        step: props.step || 1
      };
      break;

    case InputType.EMAIL:
      params.type = 'email';
      params.inputMode = 'email';
      break;

    case InputType.FILE:
      params.type = 'file';
      params.attrs = {
        multiple: props.multiple || props.numFiles > 1
      };
      let accept = props.accept;
      if (accept && !Array.isArray(accept)) accept = [ accept ];
      if (accept) params.attrs.accept = accept.join(',');
      break;

    case InputType.HIDDEN:
      params.type = 'hidden';
      break;

    case InputType.NUMBER:
      params.type = 'number';
      params.inputMode = 'numeric';
      params.attrs = {
        min: props.min || 0,
        max: props.max || Number.MAX_SAFE_INTEGER,
        step: props.step || 1
      };
      break;

    case InputType.MONTH:
      params.type = 'month';
      break;

    case InputType.PASSWORD:
      params.type = 'password';
      break;

    case InputType.PHONE_NUMBER:
      params.type = 'tel';
      params.inputMode = 'tel';
      break;

    case InputType.TIME:
      params.type = 'time';
      break;

    case InputType.SEARCH:
      params.type = 'search';
      params.inputMode = 'search';
      break;

    case InputType.URL:
      params.inputMode = 'url';
      break;

    case InputType.WEEK:
      params.type = 'week';
      break;
  }

  return params;
}

/* --- */ 

export interface InputProps extends IntrinsicProps {
  rounded?: boolean
  type: InputType
  numFiles?: number
  multiple?: boolean
  fileFormat?: InputFileFormat
  readOnly?: boolean
  disabled?: boolean
  step?: number
  value?: any
  placeholder?: string
  dataAttrs?: Record<string, string|number|boolean>
  onChange?: (value: any) => void
  onFocus?: (value: any) => void
  onBlur?: (value: any) => void
  onKeyUp?: (value: any) => void
  onKeyDown?: (value: any) => void
  onKeyPress?: (value: any) => void
}
 
const inputStates = [
  'rounded'
];

const Input = createComponent<InputProps>('Input', { classStates: inputStates }, function Input ({ mergeClassNames, style }, props) {
  const {
    states,
    focus,
    blur,
    change,
    keyPress
  } = useInput(props);

  const className = mergeClassNames(toClassName('Input', states, props));

  const { type, inputMode, attrs, dataAttrs } = getAttrs(props.type, props);

  const fileData = useRef(undefined);

  const onChange = async (e) => {
    if (props.type === InputType.FILE) {
      const files = e.target.files;
      const numFiles = props.multiple
        ? -1
        : (props.numFiles || 1);

      const limit = numFiles === -1
        ? files.length
        : numFiles;

      if (!props.fileFormat) return files.slice(0, limit);

      if (props.fileFormat === InputFileFormat.FORMDATA) fileData.current = new FormData();
      if (props.fileFormat === InputFileFormat.BASE64) fileData.current = [];

      for (let i = 0; i < limit; i++) {
        const file = files[i];
        const fileName = file.name.replace(/\s/g, '_');

        if (props.fileFormat === InputFileFormat.FORMDATA) fileData.current.append(fileName, file);
        if (props.fileFormat === InputFileFormat.BASE64) fileData.current.push({ name: fileName, data: file });
      }
      
      if (props.fileFormat === InputFileFormat.BASE64) {
        for (let i = 0; i < fileData.current.length; i++) {
          fileData.current[i].data = await toBase64(fileData.current[i].data);
        }
      }

      if (props.onChange) props.onChange(fileData.current);
    } else {
      change(e);
    }
  };

  const onBlur = (e) => {
    if (props.type === InputType.FILE) {
      if (props.onBlur) props.onBlur(fileData.current);
    } else {
      blur(e);
    }
  };

  return (
    <input
      type={type}
      inputMode={inputMode}
      className={className} 
      style={style}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      onFocus={focus}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={keyPress}
      onKeyDown={keyPress}
      onKeyPress={keyPress}
      placeholder={props.placeholder}
      {...attrs}
      {...dataAttrs}
    />
  );
});
export default Input;

/* --- */ 

interface InputFieldProps extends InputProps {
  name: string,
  form: any
}

export const InputField = createComponent<InputFieldProps>('InputField', {}, function InputField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const attrs = {} as InputAttributes;
  if (typeof schema?.minValue === 'number') attrs.min = schema.minValue;
  if (typeof schema?.maxValue === 'number') attrs.max = schema.maxValue;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
      <Control iconsRight={!!slots?.icon}>
        <Input
          {...props}
          key={key}
          type={props.type}
          value={form.data[key]} 
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          step={props.step}
          {...attrs}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
        />
        {
          If(form.errors[key], () => (
            <FieldHelp error>{form.errors[key]}</FieldHelp>
          ))
          .ElseIf(slots?.help, () => (
            <FieldHelp>{slots.help}</FieldHelp>
          ))
          .EndIf()
        }
        {slots?.icon}
      </Control>
    </Field>
  )
});

/* --- */ 

interface InputUncontrolledFieldProps extends InputProps {
  name: string,
  form: any
  accept?: InputFileType
}

export const InputUncontrolledField = createComponent<InputUncontrolledFieldProps>('InputField', {}, function InputField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const attrs = {} as InputAttributes;
  if (typeof schema?.minValue === 'number') attrs.min = schema.minValue;
  if (typeof schema?.maxValue === 'number') attrs.max = schema.maxValue;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
      <Control iconsRight={!!slots.icon}>
        <Input
          {...props}
          key={key}
          type={props.type}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          step={props.step}
          {...attrs}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
        />
        {
          If(form.errors[key], () => (
            <FieldHelp error>{form.errors[key]}</FieldHelp>
          ))
          .ElseIf(slots?.help, () => (
            <FieldHelp>{slots.help}</FieldHelp>
          ))
          .EndIf()
        }
        {slots?.icon}
      </Control>
    </Field>
  )
});
