import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';

import { App } from '@/common/components';
import { AuthProvider, useAuthStore, verify, ready } from '@/stores/auth';
import { RecentGamesProvider } from '@/stores/recentGames';
import AppModals from '@/components/AppModals';
import { ThemeProvider, useThemeStore } from '@/stores/theme';
import { FavoriteGamesProvider, useFavoriteGamesStore, reset, fetchFavoriteGames } from '@/stores/favoriteGames';
import { useEffectOnce } from '@/common/hooks/effectOnce';
import { DrupalProvider } from '@/contexts/Drupal.context';
import { ComScore } from '@/common/components/head';
import { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';

function AppInner({ Component, pageProps }) {
  const [themeState] = useThemeStore();
  const [authState] = useAuthStore();
  const [favoriteGamesState, dispatch] = useFavoriteGamesStore();
  const router = useRouter();
  const hasLoadedAdScriptRef = useRef(false);

  useEffectOnce(() => {
    const isEmbedPage = router.asPath.includes('/embed/');

    const loadAdScript = () => {
      return; 
      if (hasLoadedAdScriptRef.current) return;

      hasLoadedAdScriptRef.current = true;

      const scriptRef = document.getElementsByTagName('script')[0];
      const ympbScript = document.createElement('script');

      ympbScript.src = process.env.PROJECT_CONFIG.YOLLA_SCRIPT_URL;
      scriptRef.parentNode.insertBefore(ympbScript, scriptRef);

      if (isEmbedPage) return;

      window.removeEventListener('scroll', loadAdScript);
      window.removeEventListener('mousemove', loadAdScript);
      window.removeEventListener('touchstart', loadAdScript);
    };

    if (isEmbedPage) {
      loadAdScript();
      return;
    }

    if (!authState?.ready) {
      return false;
    }

    if (!authState?.user?.hasGamePass) {
      window.addEventListener('scroll', loadAdScript);
      window.addEventListener('mousemove', loadAdScript);
      window.addEventListener('touchstart', loadAdScript);
    }

    if (!authState?.user?.uid) {
      dispatch(reset);
      return false;
    } else {
      dispatch(fetchFavoriteGames, authState?.user?.uid);
      return true;
    }
  }, () => {
    return authState?.user?.uid !== favoriteGamesState?.userId;
  },
  [authState?.ready, authState?.user?.uid, favoriteGamesState?.userId]);

  // Use useEffect to call ramp.spaNewPage on route changes
  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window.ramp !== 'undefined' && typeof window.ramp.spaNewPage === 'function') {
     //   console.log('called spaNewPage');
     //   window.ramp.spaNewPage();
      }
    };



    // Call on initial load
    handleRouteChange();

    // Attach event handler for route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup event handler on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <App
      theme={themeState.theme}
      readyAction={ready}
      verifyAction={verify}
      useAuthStore={useAuthStore}
    >
      <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
      <AppModals />
      <Component {...pageProps} />
    </App>
  );
}

function _app({ Component, pageProps }) {
  return (
    <App.Provider authProvider={AuthProvider}>
      <DrupalProvider>
        <ThemeProvider>
          <RecentGamesProvider>
            <FavoriteGamesProvider>
              <AppInner Component={Component} pageProps={pageProps} />
            </FavoriteGamesProvider>
          </RecentGamesProvider>
        </ThemeProvider>
      </DrupalProvider>
    </App.Provider>
  );
}

export default _app;
