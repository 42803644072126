// Third-party
import { 
  useEffect, 
  useState 
} from 'react';
import { useRouter } from 'next/router';
import { 
  faEnvelope, 
  faLock, 
  faUser 
} from '@fortawesome/free-solid-svg-icons';

// First-party
import { 
  Slot, 
  If, 
  createComponent,
  IntrinsicProps
} from '@/common/util/templateHelpers';
import { 
  useAuthStore
} from '@/stores/auth';
import userApi, { MAX_USERNAME_LENGTH } from '@/apis/drupal/user';
import { useForm } from '@/common/hooks/form';
import { useNotifications } from '@/common/hooks/notifications';

// Components
import Block from '@/common/components/Block';
import { IconFA } from '@/common/components/Icon';
import Button from '@/common/components/controls/Button';
import { 
  InputField,
  InputType 
} from '@/common/components/controls/Input';
import { 
  RecaptchaField, 
  RecaptchaTheme
} from '@/common/components/controls/Recaptcha';
import Form, { FieldType } from '@/common/components/forms/Form';
import { CheckboxField } from '@/common/components/controls/Checkbox';
import RouterLink from '@/common/components/RouterLink';
import register from '@/pages/account/register';

interface RegisterFormProps extends IntrinsicProps {
  captcha?: boolean
  buttonText?: string
  privacyHref?: string
  termsHref?: string
  onSuccess?: (data: RegisterFormData) => void
  onError?: (errors?: any) => void
  onSubmit?: (data: RegisterFormData) => void
}

interface RegisterFormData {
  username: string
  email: string
  password: string
  agreeToEmail: boolean
}

export default createComponent<RegisterFormProps>('RegisterForm', {}, function RegisterForm ({ className }, props) {
  const { notify } = useNotifications();
  const router = useRouter();

  const schema = {
    username: {
      value: '',
      schema: {
        type: FieldType.STRING,
        required: true,
        maxLength: MAX_USERNAME_LENGTH,
        allowedCharacters: '[a-zA-Z0-9_]'
      }
    },
    email: {
      value: '',
      schema: {
        type: FieldType.EMAIL,
        required: true,
        invalidCharacters: [ '+' ],
      }
    },
    password: {
      value: '',
      schema: {
        type: FieldType.PASSWORD,
        required: true,
        minLength: 8,
        criteria: {
          upperAndLower: true,
          number: true,
          specialChars: false,
        }
      }
    },
    passwordAgain: {
      schema: {
        type: FieldType.PASSWORD_VERIFY,
        checkAgainst: 'password'
      }
    },
    agreeToPrivacy: {
      value: 'dontAgree',
      schema: {
        type: FieldType.BOOLEAN,
        trueValue: 'agree',
        falseValue: 'dontAgree',
        required: true
      }
    },
    agreeToTerms: {
      value: 'dontAgree',
      schema: {
        type: FieldType.BOOLEAN,
        trueValue: 'agree',
        falseValue: 'dontAgree',
        required: true
      }
    },
    agreeToEmail: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        trueValue: true,
        falseValue: false,
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const form = useForm(schema);
  const [ state ] = useAuthStore();
  const [ loading, setLoading ] = useState(false);

  const submit = async () => {
    const { data, errors, hasErrors } = form.validate<RegisterFormData>();
    if (hasErrors) return props.onError && props.onError(errors);

    setLoading(true);

    const registerUser = await userApi.register(data.username, data.email, data.password, data.agreeToEmail);

    if (props.onSubmit) props.onSubmit(data);

    if (typeof registerUser == 'undefined' || registerUser.error) {
      if (registerUser.error) {
        const msg = registerUser?.error?.response?.data?.message?.toLowerCase();

        if (!msg) {
          notify('There was a problem creating your account. Please try again later.', 'error');
        } else if (msg.includes('username')) {
          if (msg.includes('is already taken')) {
            notify('That username is already taken. Please try another.', 'error');
          } else if (msg.includes('is too long')) {
            notify(`The username you selected is too long. Usernames must be ${MAX_USERNAME_LENGTH} characters or less.`, 'error');
          }
        } else if (msg.includes('email address')) {
          notify('That email address is already taken. Please try another.', 'error');
        } 
      } else {
        notify('There was a problem creating your account. Please try again later.', 'error');
      }
      if (props.onError) props.onError();
    }
    else {
      notify('Please check your email to activate your account.', 'success');
      if (props.onSuccess) props.onSuccess(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [ state ]);

  return (
    <Form className={className} onSubmit={submit}>
      <Block className='--textLeft'>
        <Block>
          <InputField name='username' type={InputType.ANY} form={form} placeholder='Username'>
            <Slot name='icon'><IconFA icon={faUser} /></Slot>
          </InputField>
          <InputField name='email' type={InputType.EMAIL} form={form} placeholder='Email'>
            <Slot name='icon'><IconFA icon={faEnvelope} /></Slot>
          </InputField>
          <InputField name='password' type={InputType.PASSWORD} form={form} placeholder='Password'>
            <Slot name='icon'><IconFA icon={faLock} /></Slot>
          </InputField>
          <InputField name='passwordAgain' type={InputType.PASSWORD} form={form} placeholder='Password (verify)'>
            <Slot name='icon'><IconFA icon={faLock} /></Slot>
          </InputField>
        </Block>
        <Block>
          <p>
            Passwords must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number, and must be at least 8 characters long.
          </p>
        </Block>
        <Block>
          <CheckboxField name='agreeToPrivacy' form={form}>
            <Slot name='description'>
              I agree to the <RouterLink className='Link' href={props.privacyHref || '/about/privacy-policy'} target='_blank'>privacy policy</RouterLink>
            </Slot>
          </CheckboxField>
          <CheckboxField name='agreeToTerms' form={form}>
            <Slot name='description'>
              I agree to the <RouterLink className='Link' href={props.termsHref || '/about/terms-use'} target='_blank'>terms and conditions</RouterLink>
            </Slot>
          </CheckboxField>
          <CheckboxField name='agreeToEmail' form={form}>
            <Slot name='description'>
              Check this box to opt-in to email communications from AG. You can change this setting later in your user profile.
            </Slot>
          </CheckboxField>
        </Block>
      </Block>
      
      <Block className='--textCenter'>
        {
          If(props.captcha, () => (
            <Block>
              <RecaptchaField theme={RecaptchaTheme.DARK} name='captchaResult' form={form} siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}>&nbsp;</RecaptchaField>
            </Block>
          ))
            .EndIf()
        }

        <Block>
          <Button 
            primary 
            submit 
            disabled={form.hasErrors} 
            loading={loading}
          >
            {props.buttonText || 'Register'}
          </Button>
        </Block>
      </Block>
    </Form>
  );
});
