import { If, createComponent, toClassName, IntrinsicProps } from '@/common/util/templateHelpers';
import useInput from '@/common/hooks/input';
import Field, { FieldHelp, FieldLabel } from '@/common/components/controls/Field';
import Control from '@/common/components/controls/Control';

interface TextareaProps extends IntrinsicProps {
  fixedSize?: boolean
  readOnly?: boolean
  disabled?: boolean
  placeholder?: string
  value?: any
  onFocus?: (value: any) => void
  onBlur?: (value: any) => void
  onChange?: (value: any) => void
}

const textAreaStates = [
  'fixedSize'
];

const Textarea = createComponent<TextareaProps>('Textarea', { classStates: textAreaStates }, function Textarea ({ mergeClassNames, style }, props) {
  const {
    states, 
    focus,
    blur, 
    change
  } = useInput(props);

  const className = mergeClassNames(toClassName('Textarea', states, props));

  return (
    <textarea
      className={className} 
      style={style}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onFocus={focus}
      onBlur={blur}
      onChange={change}
    />
  );
});
export default Textarea;

/* --- */

interface TextareaFieldProps extends TextareaProps {
  name: string,
  form: any,
}

export const TextareaField = createComponent<TextareaFieldProps>('TextareaField', {}, function TextareaField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
      <Control>
        <Textarea
          key={key}
          value={form.data[key]} 
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
        />
        {
          If(form.errors[key], () => (
            <FieldHelp error>{form.errors[key]}</FieldHelp>
          ))
          .ElseIf(slots.help, () => (
            <FieldHelp>{slots.help}</FieldHelp>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});
