import { Flex } from '@/common/components';
import { If, createComponent, IntrinsicProps, toClassName } from '@/common/util/templateHelpers';

import style from './index.module.scss';

export enum GridHeight {
  ONE_ROW_MOBILE = 'oneRowMobile',
  
  ONE_ROW = 'oneRow',
  TWO_ROWS = 'twoRows',

  PLAYLIST_ONE_ROW = 'playlistOneRow',
  PLAYLIST_TWO_ROWS = 'playlistTwoRows'
}

interface GameTileGridProps extends IntrinsicProps {
  restrictHeight?: GridHeight
  overflow?: boolean
  alignLeft?: boolean
}

export default createComponent<GameTileGridProps>('GameTileGrid', 
  { style }, 
  function GameTileGrid ({ mergeClassNames }, props) {
    const classState = {
      heightOneRowMobile: props.restrictHeight === GridHeight.ONE_ROW_MOBILE,
    
      heightOneRow: props.restrictHeight === GridHeight.ONE_ROW,
      heightTwoRows: props.restrictHeight === GridHeight.TWO_ROWS,

      heightPlaylistOneRow: props.restrictHeight === GridHeight.PLAYLIST_ONE_ROW,
      heightPlaylistTwoRows: props.restrictHeight === GridHeight.PLAYLIST_TWO_ROWS,
    };
    const className = mergeClassNames(toClassName('GameTileGrid', classState));
    const classNameOverflow = mergeClassNames(toClassName('GameTileGrid', classState)) + ' GameTileGrid__Overflow';
  
    return (
      <>
        {
          If(props.overflow, () => (
            <div className={classNameOverflow}>
              {props.children}
            </div>
          ))
            .Else(() => (
              <Flex 
                wrap
                justifyCenter={!props.alignLeft}
                className={className}
              >
                {props.children}
              </Flex>
            ))
            .EndIf()
        }
      </>
    );
  });
