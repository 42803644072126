import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

export interface ContentProps extends IntrinsicProps {
  large?: boolean
}

const contentClassStates = [
  'large'
];

export default createComponent<ContentProps>('Content', 
  { classStates: contentClassStates }, 
  function Content ({ className, style }, props) {
    return (
      <div className={className} style={style}>
        {props.children}
      </div>
    );
  });

export interface HTMLContentProps extends ContentProps {
  html: string
}

export const HTMLContent = createComponent<HTMLContentProps>('Content', 
  { classStates: contentClassStates }, 
  function HTMLContent ({ className, style }, props) {
    return (
      <div 
        className={className} 
        style={style} 
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
    );
  });
