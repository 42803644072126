
import axios from '@/apis/drupal/instance';
import { formatGameBasic, GameBasic, Id } from '@/util/formatters';

interface DrupalGameBasicData {
  uuid: string
  drupal_internal__nid: string
  title: string
  published_at: string
  path: string
  status: string
  field_is_mobile: string
  field_is_mobile_playable: string
  field_thumbnail: string
  field_video_thumbnail: string
}

export default class BeforeYouGoGamesModel {
  static async fetch (): Promise<GameBasic[]> {
    try {
      const games = await axios.request({
        method: 'get',
        url: '/rest/block/before-you-go-games',
      }) as DrupalGameBasicData[];
      return games.map((game: DrupalGameBasicData) => formatGameBasic(game));
    } catch (error) {
      throw error;
    }
  }
}
