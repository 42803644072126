import { useRef, useEffect } from 'react';
import { For, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { slideUpIn, slideUpOut, slideUpEnterFrom } from '@/common/transitions/slideUp';
import { useNotifications } from '@/common/hooks/notifications';
import { TransitionAttributes, TransitionList } from '@/common/components/Transition';
import Delete from '@/common/components/controls/Delete';

enum NotificationStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

interface NotificationProps extends IntrinsicProps {
  warning?: boolean
  success?: boolean
  error?: boolean
  enterFrom?: TransitionAttributes
  enter?: TransitionAttributes
  leave?: TransitionAttributes
  onDismiss: () => void
}

const notificationStates = [
  'warning',
  'success',
  'error'
];

const Notification = createComponent<NotificationProps>('Notification', 
{ classStates: notificationStates }, 
function Notification ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onDismiss}>
      {props.children}
    </div>
  );
});
export default Notification;

/* --- */

interface NotificationsProps extends IntrinsicProps {
  topRight?: boolean
  middle?: boolean
  enterFrom?: TransitionAttributes
  enter?: TransitionAttributes
  leave?: TransitionAttributes
}

const notificationsStates = [
  'topRight',
  'middle'
];

export const Notifications = createComponent<NotificationsProps>('Notifications', 
{ classStates: notificationsStates }, 
function Notifications ({ className, style }, props) {
  const { state, dismiss } = useNotifications();

  const enterFrom = props.enterFrom || slideUpEnterFrom();
  const enter = props.enter || slideUpIn();
  const leave = props.leave || slideUpOut();

  const updateIntervalRef = useRef(null);
  useEffect(() => {
    clearInterval(updateIntervalRef.current);

    const notificationUpdateInterval = 100;
    updateIntervalRef.current = setInterval(() => {
      const notification = state.notifications[0];
      if (!notification) return

      notification.aliveFor -= notificationUpdateInterval;
      if (notification.aliveFor <= 0) {
        const index = state.notifications.indexOf(notification);
        dismiss(index);
      }
    }, notificationUpdateInterval);

    return () => {
      clearInterval(updateIntervalRef.current);
    };
  }, [state]);

  return (
    <div className={className} style={style}>
      <TransitionList visible={true} enterFrom={enterFrom} enter={enter} leave={leave} target='.Notification'>
        {
          For(state.notifications, (item, i) => (
            <Notification 
              key={item.id} 
              success={item.type === NotificationStatus.SUCCESS}
              warning={item.type === NotificationStatus.WARNING}
              error={item.type === NotificationStatus.ERROR}
              onDismiss={() => dismiss(i)}
            >
              {item.message}
              <Delete />
            </Notification>
          ))
        }
      </TransitionList>
    </div>
  );
});
