import { useEffect, useState } from 'react';

export function useSupportsTouch () {
  const [ supportsTouch, setSupportsTouch ] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    let result = false;
    
    if ('maxTouchPoints' in navigator) {
      result = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      result = navigator[ 'msMaxTouchPoints' ] > 0;
    } else {
      const mediaQuery = window.matchMedia && window.matchMedia('(hover: none)');
      if (mediaQuery && mediaQuery.media === '(hover: none)') {
        result = !!mediaQuery.matches;
      } else if ('orientation' in window) {
        result = true;
      }
    }

    setSupportsTouch(result);
  }, []);

  return supportsTouch;
}
