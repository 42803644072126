import { createStore, CacheStrategy } from '@/common/util/store';
import apiInstance from '@/apis/drupal/instance';
import authApi from '@/apis/drupal/auth';
import userApi from '@/apis/drupal/user';
import Cookies from 'js-cookie';
import { NumericId, User } from '../util/formatters';

export type CsrfToken = string

export interface Auth {
  uid: string
  csrfToken: string
  logoutToken: string
}

export interface AuthState {
  user: User | null
  auth: Auth | null
  token: CsrfToken | null
  ready: boolean
}

function setToken (token: CsrfToken) {
  apiInstance.defaults.headers[ 'X-CSRF-Token' ] = token;
}

function clearToken () {
  delete apiInstance.defaults.headers[ 'X-CSRF-Token' ];
}

function setSwagCookies (displayName: string, id: NumericId) {
  const settings = {
    domain: '.addictinggames.com',
    secure: true,
  };

  Cookies.set('ag_user', displayName, settings);
  Cookies.set('ag_uid', id, settings);
}

function clearSwagCookies () {
  Cookies.remove('ag_user');
  Cookies.remove('ag_uid');
}

// Mutations

function SET_AUTH (state, auth) {
  return {
    ...state,
    auth,
    ready: false
  };
}

function SET_READY (state, ready) {
  return {
    ...state,
    ready
  };
}

function VERIFY_AUTH (state, { user, token }) {
  return {
    ...state,
    user,
    token,
    ready: true
  };
}

function REFRESH_USER (state, { user }) {
  return {
    ...state,
    user,
  };
}

function UNSET_AUTH (state) {
  return {
    ...state,
    auth: null,
    user: null,
    token: null,
    ready: true
  };
}

// Actions

export async function authorize (options, mutate, state) {
  const error = (err) => {
    clearToken();
    clearSwagCookies();
    mutate(UNSET_AUTH);
    if (options?.onError) options.onError(err);
  };

  const success = () => {
    mutate(SET_AUTH, {
      uid: loginResult.current_user.uid,
      csrfToken: loginResult.csrf_token, 
      logoutToken: loginResult.logout_token
    });
    if (options?.onSuccess) options.onSuccess();
  };

  const loginResult = await authApi.login(options.username, options.password);
  if (loginResult.error) {
    if (state.auth?.logoutToken) {
      const result = await authApi.logout(state.auth.logoutToken);
      if (result.error && options?.onError) options.onError(result.error);
    }
    return error(loginResult.error);
  }

  return success();
}

export async function verify (options, mutate, state) {
  const reset = () => {
    clearToken();
    clearSwagCookies();
    mutate(UNSET_AUTH);
  };

  const error = (err) => {
    reset();
    if (options?.onError) options.onError(err);
  };

  const verify = await authApi.verify();
  if (verify.error) return error(verify.error);
  if (!verify) return reset();

  const token = await authApi.token();
  if (token.error) return error(token.error);
  setToken(token);

  const user = await userApi.getByUid(state.auth.uid);
  if (user.error) return error(user.error);
  setSwagCookies(user.displayName, user.uid);

  if (user.hasGamePass) {
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof YMPB !== 'undefined') {
      // @ts-ignore
      if (YMPB.clear) YMPB.clear();
    }
  }

  mutate(VERIFY_AUTH, { user, token });
}

export async function ready (options, mutate) {
  mutate(SET_READY, true);
}

export async function deauthorize (options, mutate, state) {
  if (state.auth.logoutToken) { 
    const result = await authApi.logout(state.auth.logoutToken);

    if (result.error && options?.onError) {
      if (options.onError) options.onError(result.error);
    } else {
      clearToken();
      clearSwagCookies();
      mutate(UNSET_AUTH);
      if (options.onLogout) options.onLogout();
    }
  }
  else {
    if (options?.onError) {
      if (options.onError) options.onError(new Error('No logout token set.'));
    }
  }
}

export async function refreshUser (options, mutate, state) {
  const user = await userApi.getByUid(state.auth.uid);
  if (user.error) return user.error;

  mutate(REFRESH_USER, { user });
}

// Export

export const {
  Provider: AuthProvider,
  useStore: useAuthStore
} = createStore<AuthState>({
  defaultState: {
    user: null,
    auth: null,
    token: null,
    ready: false
  },
  cacheKey: 'auth',
  cacheStrategy: CacheStrategy.LOCAL
});