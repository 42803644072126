import { 
  createComponent, 
  IntrinsicProps 
} from '@/common/util/templateHelpers';

/* --- */ 

interface FieldProps extends IntrinsicProps {
  addons?: boolean
  addonsCentered?: boolean
  addonsRight?: boolean
  addonsFullwidth?: boolean
  grouped?: boolean
  groupedCentered?: boolean
  groupedRight?: boolean
  groupedMultiline?: boolean
  horizontal?: boolean
}

const fieldStates = [
  'addons',
  'addonsCentered',
  'addonsRight',
  'addonsFullwidth',
  'grouped',
  'groupedCentered',
  'groupedRight',
  'groupedMultiline',
  'horizontal'
];

const Field = createComponent<FieldProps>('Field', { classStates: fieldStates }, function Field ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
export default Field;

/* --- */ 

interface FieldLabelProps extends IntrinsicProps {
  small?: boolean
  medium?: boolean
  large?: boolean
}

const fieldLabelStates = [
  'small',
  'medium',
  'large'
];

export const FieldLabel = createComponent<FieldLabelProps>('FieldLabel', { classStates: fieldLabelStates }, function FieldLabel ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

/* --- */ 

interface FieldHelpProps extends IntrinsicProps {
  small?: boolean
  medium?: boolean
  large?: boolean
  success?: boolean
  warning?: boolean
  error?: boolean
}

const fieldHelpStates = [
  'small',
  'medium',
  'large',
  'success',
  'warning',
  'error'
];

export const FieldHelp = createComponent<FieldHelpProps>('FieldHelp', { classStates: fieldHelpStates }, function FieldHelp ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

/* --- */ 

interface FieldBodyProps extends IntrinsicProps {
  small?: boolean
  medium?: boolean
  large?: boolean
}

const fieldBodyLabelStates = [
  'small',
  'medium',
  'large'
];

export const FieldBodyLabel = createComponent<FieldBodyProps>('FieldLabel', { classStates: fieldBodyLabelStates }, function FieldLabel ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

/* --- */ 

export const FieldBody = createComponent<IntrinsicProps>('FieldBody', {}, function FieldBody ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
