import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { googleAnalytics, universalAnalytics } from '../util/googleAnalytics';

export const useGoogleAnalytics = (options: {
  ga4key?: string,
  uakey?: string
}) => {
  const router = useRouter();

  const logPageView = () => {
    if (options.ga4key) googleAnalytics.logPageView();
    if (options.uakey) universalAnalytics.logPageView();
  };

  useEffect(() => {
    if (options.ga4key) googleAnalytics.initialize(options.ga4key);
    if (options.uakey) universalAnalytics.initialize(options.uakey);
    
    // The `routeChangeComplete` event won't fire on the initial page load unless the query 
    // string is hydrated. Log a page view if this is the first render with no query string.
    if (!router.asPath.includes('?')) logPageView();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ options.ga4key, options.uakey ]);

  useEffect(() => {
    // Listen for page changes on navigation changes or when the query changes
    router.events.on('routeChangeComplete', logPageView);
    return () => {
      router.events.off('routeChangeComplete', logPageView);
    };    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.events ]);

  return <></>;
};
