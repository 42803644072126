import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import log, { LogLevels } from '@/common/util/dev/log';

type InstanceOrUrl = AxiosInstance | string

interface RequestOptions extends AxiosRequestConfig {
  transform: (data: any) => any
}

export type Response<T> = T & {
  error?: AxiosError
}

export function createNetworkApi<T> (instanceOrURL: InstanceOrUrl, methods, options: AxiosRequestConfig = {}) {
  let instance;
  if (typeof instanceOrURL === 'string') {
    instance = axios.create({
      baseURL: instanceOrURL,
      ...options,
    });
  } else {
    instance = instanceOrURL;
    for (const key in options) {
      instance.defaults[ key ] = options[ key ];
    }
  }

  const request = (options: RequestOptions) => {
    return new Promise((resolve) => {
      const resolver = (response) => {
        if (options.transform) response = options.transform(response);
        resolve(response);
      };
      instance.request(options)
        .then(resolver)
        .catch((error: AxiosError) => {
          const e = error?.response?.data?.errors ?? error;
          
          if (process.env.NODE_ENV === 'development') {
            log(`Request Error: ${e}`, LogLevels.ERROR);
          }

          resolve({ error: e, httpResponseCode: error?.response?.status });
        });
    });
  };

  const api = {} as T;

  for (const key in methods) {
    api[ key ] = function () {
      const options = methods[ key ].apply(null, Array.from(arguments));
      return request(options);
    };
  }

  return api;
}

function getPairs (obj, keys = []) {
  return Object.entries(obj).reduce((pairs, [ key, value ]) => {
    if (Array.isArray(value)) pairs.push([ [ ...keys, key ], value.join(',') ]);
    else if (typeof value === 'object') pairs.push(...getPairs(value, [ ...keys, key ]));
    else pairs.push([ [ ...keys, key ], value ]);

    return pairs;
  }, []);
}

export function toQuery (url, params) {
  const query = getPairs(params)
    .map(([ [ key0, ...keysRest ], value ]) => `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
    .join('&');

  return url + '?' + query;
}
