import { createNetworkApi, toQuery, Response } from '@/common/util/networkRequest';
import { SearchToolbarState } from '@/pages/free-games';
import { formatGameBasic, formatGame, formatGameTitles, Game, GameBasic, Id, NumericId } from '@/util/formatters';

import apiInstance from './instance';

export const gameQuery = {
  fields: {
    'node--game': [ 
      'uid',
      'drupal_internal__nid',
      'title',
      'published_at',
      'path',
      'status',
      'field_ratingb',
      'field_is_mobile',
      'field_is_mobile_playable',
      'field_video_thumbnail', 
      'field_thumbnail', 
      'field_category_featured',
      'field_before_you_go_featured',
      'field_hits',
    ],
  },
  include: [ 
    'field_video_thumbnail', 
    'field_thumbnail',
  ]
};

const gameQueryFull = {
  fields: {
    'node--game': [ 
      'uid',
      'drupal_internal__nid',
      'body', 
      'title',
      'created',
      'changed',
      'published_at',
      'path',
      'status',
      'field_video_thumbnail', 
      'field_thumbnail', 
      'field_body',
      'field_ratingb',
      'field_total_votes',
      'field_hits',
      'field_embed',
      'field_is_mobile',
      'field_is_mobile_playable',
      'field_preferred_orientation',
      'field_game_pass_only_',
      'field_instructions',
      'field_genre',
      'field_game_developer',
      'field_tags',
      'field_video_walkthroughs',
      'field_html5_game_url',
      'field_suggest_a_random_game',
      'field_alternate_game_suggestion',
      'field_external_game_link',
      'field_maturity_rating',
      'field_is_flash_game',
      'field_flash_file',
      'field_flash_file_url',
      'field_hide_from_listings',
      'field_display_width',
      'field_display_height',
    ],
  },
  include: [ 
    'field_video_thumbnail', 
    'field_thumbnail',
    'field_genre',
    'field_genre.field_genre_image',
    'field_tags',
    'field_tags.field_tag_image',
    'field_alternate_game_suggestion',
    'field_maturity_rating',
    'field_flash_file'
  ]
};

export const GAMES_LIMIT = process.env.APP_CONFIG.DEFAULT_GAME_LIMIT;
export const FAVORITES_LIMIT = 24;
export const FAVORITES_LIMIT_MAX = 50;

interface GameApi {
  getById: (gameId: Id, trackView?: boolean) => Promise<Response<Game>>
  getByNid: (nid: NumericId) => Promise<Response<Game>>

  listAll: (limit: number, offset: number) => Promise<Response<Game[]>>

  listByGenre: (genre: string, limit?: number, offset?: number) => Promise<Response<Game[]>>
  listByGenreSorted: (genre: string, sortBy: 'newest' | 'rating', limit?: number, offset?: number) => Promise<Response<Game[]>>
  listByGenreNew: (genre: string, limit: number, offset: number) => Promise<Response<Game[]>>
  listByGenreTop: (genre: string, limit: number, offset: number) => Promise<Response<Game[]>>
  listByGenreHot: (genre, filter: 'popular' | 'top', limit?: number, offset?: number) => Promise<Response<Game[]>>
  listByGenreMobileOnly: (genre: string, limit: number, offset: number) => Promise<Response<Game[]>>

  listByTag: (tag: string, limit: number, offset: number) => Promise<Response<Game[]>>
  listByTagNew: (tag: string, limit: number, offset: number) => Promise<Response<Game[]>>
  listByTagTop: (tag: string, limit: number, offset: number) => Promise<Response<Game[]>>
  listByTagMobileOnly: (tag: string, limit: number, offset: number) => Promise<Response<Game[]>>

  listByFeaturedMultiplayer: (limit?: number, offset?: number) => Promise<Response<Game[]>>

  listNew: (limit: number, offset: number) => Promise<Response<Game[]>>

  listByTitle: (title: string, sort: string, genre: string | null, tag: string| null, limit: number, offset: number) => Promise<Response<Game[]>>

  searchFreeGames: (search: SearchToolbarState, limit: number, offset: number) => Promise<Response<Game[]>>

  listAllGamesByTitle: () => Promise<Response<any>>

  listAllGamesByAlphabet: () => Promise<Response<any>>

  rateGame: (gameId: Id, userId: NumericId, rating: number) => Promise<Response<Game>>
  getRatingForUser: (gameNid: NumericId, userId: NumericId) => Promise<Response<{ vote_value: number }>>
  getNumViews: (gameNid: NumericId) => Promise<Response<number>>

  listFavoriteGamesForUser: (userId: NumericId, limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  addFavoriteGameForUser: (gameId: NumericId) => Promise<Response<{ error?: any }>>
  removeFavoriteGameForUser: (gameId: NumericId) => Promise<Response<{ error?: any }>>

  listSiteMapGames: (offset: number) => Promise<Response<any>>
  stickyFooterGames: () => Promise<Response<GameBasic[]>>

  getBeforeYouGoFeaturedGames: () => Promise<Response<GameBasic[]>>
  getRecommendedMobileGames: (genre: string, currentGameId: NumericId) => Promise<Response<Game[]>>
}

export default createNetworkApi<GameApi> (apiInstance, {
  getById: (gameId, trackView) => ({
    method: 'get',
    url: toQuery(`/jsonapi/node/game/${gameId}${trackView ? '?trackGameView=1' : ''}`, gameQueryFull),
    transform: (result) => formatGame(result.data, result.included, result.meta)
  }),

  getByNid: (nid) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQueryFull,
      filter: {
        nid
      }
    }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))[ 0 ]
  }),

  listAll: (limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_hide_from_listings': 0
      },
      sort: '-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByGenre: (genre, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_hide_from_listings': 0
      },
      sort: '-field_category_featured,-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByGenreSorted: (genre, sortBy, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_hide_from_listings': 0
      },
      sort: sortBy === 'rating'
        ? '-field_ratingb,-published_at'
        : '-published_at,-field_ratingb',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByGenreNew: (genre, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_hide_from_listings': 0
      },
      sort: '-published_at,-field_ratingb',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByGenreTop: (genre, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_hide_from_listings': 0
      },
      sort: '-field_ratingb,-field_total_votes,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByGenreHot: (genre, filter: 'popular' | 'top', limit = GAMES_LIMIT, offset = 0) => {
    const url = {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_hide_from_listings': 0
      },
      sort: '-published_at',
      page: {
        limit,
        offset
      }
    };

    if (genre !== '') {
      url.filter[ 'field_genre.name' ] = encodeURIComponent(genre);
    }

    if (filter === 'popular') {
      url.sort = '-field_hits,-published_at';
    } else {
      url.sort = '-field_likes,-published_at';
    }

    return {
      method: 'get',
      url: toQuery('/jsonapi/node/game', url),
      transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
    };
  },

  listByGenreMobileOnly: (genre, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_is_mobile': 1,
        'field_hide_from_listings': 0
      },
      sort: '-field_category_featured,-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByTag: (tag, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_tags.name': encodeURIComponent(tag),
        'field_hide_from_listings': 0
      },
      sort: '-field_category_featured,-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByTagNew: (tag, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_tags.name': encodeURIComponent(tag),
        'field_hide_from_listings': 0
      },
      sort: '-published_at,-field_ratingb',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByTagTop: (tag, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_tags.name': encodeURIComponent(tag),
        'field_hide_from_listings': 0
      },
      sort: '-field_ratingb,-field_total_votes,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByTagMobileOnly: (tag, limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_tags.name': encodeURIComponent(tag),
        'field_is_mobile': 1,
        'field_hide_from_listings': 0
      },
      sort: '-field_category_featured,-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  listByFeaturedMultiplayer: (limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      fields: {
        'node--game': [
          ...gameQueryFull.fields[ 'node--game' ],
          'field_featured_multiplayer',
          'field_mmo_slideshow_image',
        ]
      },
      include: [
        ...gameQueryFull.include,
        'field_mmo_slideshow_image',
      ],
      filter: {
        'status': 1,
        'field_featured_multiplayer': 1,
        'field_hide_from_listings': 0
      },
      sort: '-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  listNew: (limit = GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_hide_from_listings': 0
      },
      sort: '-field_ratingb,-published_at',
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  //On AG it only allows for querying by one tag
  listByTitle: (title = null, sort, genre = null, tag = null, limit =  GAMES_LIMIT, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQueryFull,
      filter: {
        status: 1,
        'field_hide_from_listings': 0,
        ...(genre ? { 'field_genre.name' : encodeURIComponent(genre) } : {}),
        ...(tag ? { 'field_tags.name' : encodeURIComponent(tag) } : {}),
        ...(title ? {
          'title-filter' : {
            condition: {
              path: 'title',
              operator: 'CONTAINS',
              value: title
            }
          }
        } : {})
      },
      sort: sort,
      page: {
        limit,
        offset
      }
    }),
    transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
  }),

  searchFreeGames: (search: SearchToolbarState, limit = GAMES_LIMIT, offset = 0) => {
    const filter = {
      status: 1,
      'field_hide_from_listings': 0,
      'title-filter': {
        condition: {
          path: 'title',
          operator: 'CONTAINS',
          value: search.query
        },
      },
      'html5-filter': {
        condition: {
          path: 'field_html5_game_url',
          operator: 'IS NOT NULL'
        }
      }
    };

    if (search.mobile) filter[ 'field_is_mobile' ] = 1;
    if (search.tag) filter[ 'field_tags.name' ] = encodeURIComponent(search.tag);
    if (search.genre) filter[ 'field_genre.name' ] = encodeURIComponent(search.genre);

    let sort: string = '-field_ratingb,-published_at';
    if (search.sortBy === 'rating') 
      sort = '-field_ratingb';
    else if (search.sortBy === 'newest')
      sort = '-published_at';
    else if (search.sortBy === 'votes')
      sort = '-field_total_votes';
    else if (search.sortBy === 'plays')
      sort = '-field_hits';

    const page = {
      limit,
      offset
    };
  
    return {
      method: 'get',
      url: toQuery('/jsonapi/node/game', {
        ...gameQueryFull,
        filter,
        sort,
        page,
      }),
      transform: (result) => result.data.map((game) => formatGame(game, result.included, result.meta))
    };
  },
  
  listAllGamesByTitle: () => ({
    method: 'get',
    url: '/ag-api/get-all-games',
    transform: (result) => formatGameTitles(result)
  }),

  listAllGamesByAlphabet: () => ({
    method: 'get',
    url: '/ag-api/get-all-games',
    transform: (result) => result
  }),

  rateGame: (gameId, userId, rating) => ({
    url: '/ag/api/vote/cast',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      _format: 'json'
    },
    data: {
      entity_type: 'node',
      entity_id: gameId,
      user_id: userId,
      vote_value: rating
    },
    transform: (data) => data
  }),

  getRatingForUser: (gameNid, userId) => ({
    url: '/ag/api/vote/user-status',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      _format: 'json'
    },
    data: {
      entity_type: 'node',
      entity_id: gameNid,
      user_id: userId,
    },
    transform: (data) => data
  }),

  getNumViews: (gameNid) => ({
    url: `/ag/api/node-views/${gameNid}`,
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      _format: 'json'
    },
    transform: (data) => {
      return data[ 0 ]?.viewcount || 0;
    }
  }),

  listFavoriteGamesForUser: (userId: NumericId, limit = FAVORITES_LIMIT, offset = 0) => ({
    method: 'get',
    headers: {
      'accept': 'application/json'
    },
    url: toQuery(`/ag/api/user-favorite-games/${userId}`, {
      items_per_page: limit,
      offset
    }),
    transform: (results) => (results || []).map(result => formatGameBasic(result))
  }),

  addFavoriteGameForUser: (gameId: NumericId) => ({
    url: '/ag/api/add-user-favorite',
    method: 'post',
    params: {
      _format: 'json'
    },
    data: {
      game_id: gameId
    }
  }),

  removeFavoriteGameForUser: (gameId: NumericId) => ({
    url: '/ag/api/delete-user-favorite',
    method: 'post',
    params: {
      _format: 'json'
    },
    data: {
      game_id: gameId
    }
  }),

  listSiteMapGames: (offset = 0) => ({
    method: 'get',
    url: `/ag-api/sitemap-all-games/json?items_per_page=1000&offset=${offset}`,
    transform: (result) => result
  }),

  stickyFooterGames: () => ({
    url: '/admin/index-footer-queue/json',
    method: 'get',
    transform: (results) => (results || []).map(result => formatGameBasic(result))
  }),

  getBeforeYouGoFeaturedGames: () => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_hide_from_listings': 0,
        'field_before_you_go_featured': 1,
      },
      page: {
        limit: 6,
        offset: 0
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),

  getRecommendedMobileGames: (genre, currentGameId) => ({
    method: 'get',
    url: toQuery('/jsonapi/node/game', {
      ...gameQuery,
      filter: {
        'status': 1,
        'field_genre.name': encodeURIComponent(genre),
        'field_hide_from_listings': 0,
        'mobile': {
          'group': {
            'conjunction': 'OR'
          }
        },
        'field_is_mobile': {
          'condition': {
            'path': 'field_is_mobile',
            'value': 1,
            'memberOf': 'mobile'
          }
        },
        'field_is_mobile_playable': {
          'condition': {
            'path': 'field_is_mobile_playable',
            'value': 1,
            'memberOf': 'mobile'
          }
        },
        'not_nid': {
          'condition': {
            'path': 'nid',
            'value': currentGameId,
            'operator': '<>'
          }
        }
      },
      sort: '-field_before_you_go_featured,-field_ratingb,-published_at',
      page: {
        limit: 6,
        offset: 0
      }
    }),
    transform: (result) => result.data.map((game) => formatGameBasic(game, result.included))
  }),
});
