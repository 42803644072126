import { createComponent, IntrinsicProps, toClassName } from '@/common/util/templateHelpers';

const Ad = createComponent('Ad', {}, function Ad ({ className }, props) {
  return (
    <div id={props.id} className={className}>{props.children}</div>
  );
});
export default Ad; 

/* --- */

export enum AdContainerSize {
  BANNER = 'banner',
  MOBILE_BANNER = 'mobileBanner',
  HALF_PAGE = 'halfPage',
  LARGE_LEADERBOARD = 'largeLeaderboard',
  LARGE_RECT = 'largeRect',
  LEADERBOARD = 'leaderboard',
  MEDIUM_RECT = 'mediumRect',
  MOBILE_LEADERBOARD = 'mobileLeaderboard',
  SKYSCRAPER = 'skyscraper',
  SMALL_SQUARE = 'smallSquare',
  SQUARE = 'square',
  WIDE_SKYSCRAPER = 'wideSkyscraper'
}

interface AdContainerProps extends IntrinsicProps {
  banner?: boolean
  mobileBanner?: boolean
  halfPage?: boolean
  inlineRect?: boolean
  largeLeaderboard?: boolean
  largeRect?: boolean
  leaderboard?: boolean
  mediumRect?: boolean
  mobileLeaderboard?: boolean
  skyscraper?: boolean
  smallSquare?: boolean
  square?: boolean
  wideSkyscraper?: boolean
  size: AdContainerSize
}

const adContainerClassStates = [
  'banner',
  'mobileBanner',
  'halfPage',
  'inlineRect',
  'largeLeaderboard',
  'largeRect',
  'leaderboard',
  'mediumRect',
  'mobileLeaderboard',
  'skyscraper',
  'smallSquare',
  'square',
  'wideSkyscraper'
];

export const AdContainer = createComponent<AdContainerProps>('AdContainer', 
{}, 
function AdContainer ({ mergeClassNames, style }, props) {
  const className = mergeClassNames(toClassName('AdContainer', adContainerClassStates, { [props.size]: true }));

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
