import ReactUA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { debugLog } from '../util/logEvent';

export const universalAnalytics = {
  initialize (gaID: string) {
    debugLog('[Google-UA] Initialized');
    ReactUA.initialize(gaID);
  },
  
  logPageView () {
    debugLog(`[Google-UA] PATH: ${window.location.pathname}`);
    ReactUA.set({ page: window.location.pathname });
    ReactUA.pageview(window.location.pathname);
  },
  
  logEvent (category = '', action = '') {
    if (category && action) {
      ReactUA.event({ category, action });
    }
  },
  
  logException (description = '', fatal = false) {
    if (description) {
      ReactUA.exception({ description, fatal });
    }
  }
};

export const googleAnalytics = {
  initialize (gaID: string) {
    debugLog('[Google-GA4] Initialized');
    ReactGA4.initialize(gaID);
  },

  logPageView () {
    debugLog(`[Google-GA4]: PATH ${window.location.pathname}`);
    ReactGA4.set({ page: window.location.pathname });
    ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  },

  logEvent (category = '', action = '') {
    if (category && action) {
      ReactGA4.event({ category, action });
    }
  },
};
