import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconProps extends IntrinsicProps<HTMLSpanElement> {
  small?: boolean
  medium?: boolean
  large?: boolean
}

const iconStates = [
  'small',
  'medium',
  'large'
];

const Icon = createComponent<IconProps>('Icon', { classStates: iconStates }, function Icon ({ className, style }, props) {
  return (
    <span className={className} style={style} onClick={props.onClick}>
      {props.children}
    </span>
  );
});
export default Icon;

/* --- */

export const IconWithText = createComponent<IconProps>('IconWithText', {}, function IconWithText ({ className, style }, props) {
  return (
    <span className={className} style={style} onClick={props.onClick}>
      {props.children}
    </span>
  );
});

/* --- */

interface IconFAProps extends IconProps {
  icon: IconProp
}

export const IconFA = createComponent<IconFAProps>('IconFA', {}, function IconFA ({ className, style }, props) {
  return (
    <Icon {...props} className={className} style={style}>
      <FontAwesomeIcon icon={props.icon} />
    </Icon>
  );
});
