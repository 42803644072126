import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface HamburgerProps extends IntrinsicProps {
  rounded?: boolean
}

const hamburgerStates = [
  'rounded'
];

export default createComponent<HamburgerProps>('Hamburger', 
{ classStates: hamburgerStates }, 
function Hamburger ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onClick}>
      <span />
    </div>
  );
});
