/* eslint-disable no-console */

import axios, { AxiosResponse } from 'axios';

type ReportEventResponseData = 
  { ok: false } |
  { ok: false, error: Error } |
  { ok: true }

export type LogLevel = 'debug' | 'info' | 'warn' | 'error' | 'fatal';

export function debugLog (msg: string, level: LogLevel = 'debug', meta?: any) {
  let style;

  switch(level) {
  case 'fatal':
  case 'error':
    style = 'color: #D33;';
    break;
  case 'warn':
    style = 'color: #DD3;';
    break;
  case 'info':
    style = 'color: #18F';
    break;
  default:
    style = 'color: #777; font-style: italic';
  }
  
  console.log(`%c [addictinggames.com-react] ${msg}`, style, meta);
}

export interface ReportErrorData {
  label: string
  fatal: boolean
  name: string
  message: string
  stack: string
  meta?: any
}

export async function logErrorEvent (
  label: string, 
  fatal: boolean = false,
  error: Error,
  meta?: any
): Promise<boolean> {
  debugLog(label, 'error', error);

  const reportOnLevels = process.env.NEXT_PUBLIC_REPORT_LOG_ON_LEVELS.split(',');

  if ((!fatal && reportOnLevels.includes('error')) || (fatal && reportOnLevels.includes('fatal'))) {
    const data: ReportErrorData = {
      label,
      fatal,
      name: error.name,
      message: error.message,
      stack: error.stack,
    };
    if (meta) data.meta = meta;

    const result = await axios.request<any, AxiosResponse<ReportEventResponseData>>({
      url: process.env.NEXT_PUBLIC_BASE_URL + '/api/report/error',
      method: 'post',
      data,
    });

    return result.data.ok;
  }

  return true;
}

export interface ReportEventData {
  label: string
  level: Exclude<LogLevel, 'error'>
  meta: any
}

export async function logEvent (
  label: string, 
  level: Exclude<LogLevel, 'error' | 'fatal'> = 'debug',
  meta: any = false
): Promise<boolean> {
  debugLog(label, level, meta);

  const reportOnLevels = process.env.NEXT_PUBLIC_REPORT_LOG_ON_LEVELS.split(',');

  if (reportOnLevels.includes(level)) {
    const result = await axios.request<any, AxiosResponse<ReportEventResponseData>>({
      url: process.env.NEXT_PUBLIC_BASE_URL + '/api/report/event',
      method: 'post',
      data: {
        label,
        level,
        meta,
      }
    });

    return result.data.ok;
  }

  return true;
}
