
import axios from '@/apis/drupal/instance';
import { NumericId } from '@/util/formatters';

export interface UserAvatar {
  filename: string
  fid: string
  uri: string
}

export default class UserAvatarModel {
  static async fetchAvatarList (): Promise<UserAvatar[] | { error: any }> {
    let avatars: UserAvatar[];

    try {
      avatars = await axios.request({
        method: 'get',
        url: '/ag/api/user-avatars',
      });
    } catch (error) {
      return { error: JSON.stringify(error) };
    }

    return avatars;
  }
}
