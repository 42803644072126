import { CsrfToken } from '@/stores/auth';
import { createNetworkApi, Response } from '@/common/util/networkRequest';

import apiInstance from './instance';

interface AuthApi {
  login: (username: string, password: string) => Promise<Response<{
    current_user: {
      uid: string
    }
    csrf_token: string
    logout_token: string
  }>>

  logout: (logoutToken: string) => Promise<Response<string>>

  verify: () => Promise<Response<string>>

  token: () => Promise<Response<string>>

  requestReset: (email: string) => Promise<Response<{
    message: string
  }>>
  
  resetPassword: (token: CsrfToken, username: string, password: string) => Promise<Response<{
    message: string
  }>>
}

export default createNetworkApi<AuthApi> (apiInstance, {
  login: (name, pass) => {
    return {
      method: 'post',
      url: '/user/login',
      params: {
        _format: 'json'
      },
      data: {
        name,
        pass
      },
      transform: (data) => data
    };
  },

  logout: (logoutToken) => ({
    method: 'post',
    url: '/user/logout',
    headers: {
      'content-type': 'application/json'
    },
    params: {
      _format: 'json',
      token: logoutToken
    },
    withCredentials: true,
    transform: (data) => data
  }),

  token: () => ({
    method: 'get',
    url: '/session/token',
    transform: (data) => data
  }),
  
  verify: () => ({
    method: 'get',
    url: '/user/login_status',
    params: {
      _format: 'json'
    },
    transform: (data) => data
  }),

  requestReset: (mail) => ({
    method: 'post',
    url: '/user/lost-password',
    params: {
      _format: 'json'
    },
    data: {
      mail,
      forgot_pass_link: `${process.env.NEXT_PUBLIC_BASE_URL}/account/reset`
    },
    transform: (data) => data
  }),

  resetPassword: (token, username, password) => ({
    method: 'post',
    url: '/user/lost-password-reset',
    params: {
      _format: 'json'
    },
    data: {
      name: username,
      token, 
      new_pass: password
    },
    transform: (data) => data
  })
});
