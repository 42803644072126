import { createContext, useContext, ReactNode } from 'react';
import { formatTag, Tag } from '@/util/formatters';
import tagData from '@/data/tags.json';

const tags = tagData.data
  .map((tag) => formatTag(tag, tagData.included, tagData.meta ))
  .sort((a, b) => a.name.localeCompare(b.name));

interface ContextType {
  tags: Tag[]
}

export const DrupalContext = createContext<ContextType | null>(null);

export const DrupalProvider = ({ children }: { children: ReactNode }) => {
  return (
    <DrupalContext.Provider value={{ tags }}>
      {children}
    </DrupalContext.Provider>
  );
};

export const useDrupalContext = (): ContextType => {
  const context = useContext(DrupalContext);

  if (context == null) 
    throw new Error('useDrupalContext must be used within a DrupalProvider');

  return context;
};
