import Image from 'next/image';
import Link from 'next/link';
import { useRef, useEffect, MouseEventHandler, MutableRefObject, useState } from 'react';

import { For, createComponent, IntrinsicProps, If, toClassName } from '@/common/util/templateHelpers';
import Content from '@/common/components/Content';
import Spacer, { ASPECT_16_10, SpacerImage } from '@/common/components/Spacer';
import log from '../util/dev/log';
import { useSupportsTouch } from '../hooks/supportsTouch';

interface GameTileProps extends IntrinsicProps {
  featured?: boolean
  fullWidth?: boolean
  stretch?: boolean
  disabled?: boolean
  hidden?: boolean
  finished?: boolean
  href?: string
  innerRef?: MutableRefObject<typeof Element>
  prefetch?: boolean
  spacer?: SpacerImage
  onClick?: MouseEventHandler
  onMouseDown?: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}

const gameTileStates = [
  'featured',
  'fullWidth',
  'stretch',
  'disabled',
  'hidden',
  'finished'
];

const GameTile = createComponent<GameTileProps>('GameTile', 
  { classStates: gameTileStates }, 
  function GameTile ({ className, style, slots }, props) {
    const href = props.href
      ? (props.disabled ? '#' : props.href)
      : '#';

    const gameTileRef = useRef(null);

    const mouseEnter = (evt) => {
      if (props.onMouseEnter) props.onMouseEnter(evt);
    };

    const mouseLeave = (evt) => {
      if (props.onMouseLeave) props.onMouseLeave(evt);
    };

    const mouseDown = (evt) => {
      if (props.onMouseDown) props.onMouseDown(evt);
    };

    useEffect(() => {
      if (props.innerRef) props.innerRef.current = gameTileRef.current;
    }, [ props.innerRef ]);

    return (
      <Link 
        href={href} 
        passHref={true}
        prefetch={props.prefetch}
      >
        <a 
          className={className}
          style={style}
          ref={gameTileRef}
          onClick={props.onClick}
          onMouseDown={mouseDown}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          data-id={props.id}
        >
          {If(props.spacer, () => (<Spacer wide spacer={props.spacer} />)).EndIf()}
          {If(slots?.preview, () => (<div className='GameTile__Preview'>{slots.preview}</div>)).EndIf()}
          {If(slots?.description, () => (<div className='GameTile__Description'>{slots.description}</div>)).EndIf()}
          {If(slots?.status, () => (<div className='GameTile__Status'>{slots.status}</div>)).EndIf()}
        </a>
      </Link>
    );
  });
export default GameTile;

/* --- */

interface GameTileThumbnailProps extends IntrinsicProps {
  src: string
  alt?: string
  priority?: boolean
}

export const GameTileThumbnail = createComponent<GameTileThumbnailProps>('GameTileThumbnail', 
  {}, 
  function GameTileThumbnail ({ className, style }, props) {
    return (
      <Image
        className={className}
        src={props.src}
        alt={props.alt}
        layout='fill'      
        objectFit='cover'
        priority={props.priority}
        quality={100}
      />
    );
  });

/* --- */

interface GameTileVideoThumbnailProps extends IntrinsicProps {
  alt?: string
  poster: string
  priority?: boolean
  src: string[]
}

export const GameTileVideoThumbnail = createComponent<GameTileVideoThumbnailProps>('GameTileVideoThumbnail', 
  {}, 
  function GameTileThumbnail ({ className, mergeClassNames, style }, props) {
    const ext = (src) => {
      const parts = src.split('.');
      return parts[ parts.length - 1 ];
    };

    const SWIPE_THRESHOLD = 60; // px

    const [ computedClassName, setComputedClassName ] = useState(className);
    const video:React.RefObject<HTMLVideoElement> = useRef();
    const debounceRef = useRef(null);
    const touchStartX = useRef(0);
    const supportsTouch = useSupportsTouch();

    const onTouchStart = (e) => {
      touchStartX.current = e.changedTouches[ 0 ]?.screenX;
    };

    const onTouchEnd = (e) => {
      const touchEndX = e.changedTouches[ 0 ]?.screenX;

      // Positive delta indicates left swipe
      const swipeDeltaX = touchStartX.current - touchEndX;

      if(swipeDeltaX > SWIPE_THRESHOLD) {
      // Make sure the video only plays once if we're on mobile
        video.current.loop = false;

        playVideo();

        // Call stopVideo to put the thumbnail back in place
        video.current.addEventListener('ended', stopVideo, { once: true });
      }

      touchStartX.current = 0;
    };

    const onMouseEnter = () => {
      if (supportsTouch) return;
      playVideo();
    };

    const onMouseLeave = () => {
      if (supportsTouch) return;
      stopVideo();
    };

    const playVideo = () => {
      clearTimeout(debounceRef.current);

      // Give video a slight head start so it's playing when the poster fades out
      video.current.play().catch((e) => {
      // If the video playback gets interrupted, keep the thumbnail in place
        clearTimeout(debounceRef.current);

        setComputedClassName(className);

        log(`video playback exception ${e}`);
      });

      debounceRef.current = setTimeout(() => {
        setComputedClassName(mergeClassNames(toClassName(className, { play: true }), className));
      }, 250);
    };

    const stopVideo = () => {
      if(!video?.current) return;

      clearTimeout(debounceRef.current);

      setComputedClassName(className);
      video.current.pause();
      video.current.currentTime = 0;
    };

    return (
      <div 
        className={computedClassName} 
        style={style} 
        onMouseEnter={onMouseEnter} 
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <Image
          className='GameTileVideoThumbnail__Poster'
          src={props.poster}
          alt={props.alt}
          layout='fill'      
          objectFit='cover'
          priority={props.priority}
          quality={100}
          width={376}
          height={250}
        />
        <video 
          className='GameTileVideoThumbnail__Video'
          loop
          muted
          playsInline
          preload='none'
          ref={video}
          disableRemotePlayback
        >
          {
            For(props.src, (src) => (
              <source key={src} src={src} type={`video/${ext(src)}`} />
            ))
          }
        </video>
      </div>
    );
  });

/* --- */

export const GameTileDescription = createComponent('GameTileDescription', 
  {}, 
  function GameTileDescription ({ className, style }, props) {
    return (
      <Content className={className} style={style}>
        {props.children}
      </Content>
    );
  });

/* --- */

export const GameTileStatus = createComponent('GameTileStatus', 
  {}, 
  function GameTileStatus ({ className, style }, props) {
    return (
      <div className={className} style={style}>
        {props.children}
      </div>
    );
  });
