import { Flex, LoaderFill } from '@/common/components';
import { useNotifications } from '@/common/hooks/notifications';
import { createComponent, For, If, IntrinsicProps } from '@/common/util/templateHelpers';
import UserAvatarModel, { UserAvatar } from '@/models/UserAvatar';
import { useEffect, useState } from 'react';

import style from './index.module.scss';

interface AvatarPickerProps extends IntrinsicProps {
  onChange: (avatar: UserAvatar) => void
}

export default createComponent<AvatarPickerProps>('AvatarPicker', 
  { style }, 
  function AvatarPicker ({ className }, props) {
    const baseUrl = process.env.NEXT_PUBLIC_IMAGE_DOMAIN;

    const { notify } = useNotifications();
    const [ loading, setLoading ] = useState(true);
    const [ avatars, setAvatars ] = useState<UserAvatar[]>([]);

    useEffect(() => {
      (async () => {
        setLoading(true);

        const avatars = await UserAvatarModel.fetchAvatarList();
        
        if ((avatars as { error: any }).error) {
          notify('There was an error fetching the list of avatars.', 'error');
          return;
        }

        setAvatars(avatars as UserAvatar[]);
        setLoading(false);
      })();
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
      <div className={className}>
        {
          If(loading, () => (
            <LoaderFill large />
          ))
            .Else(() => (
              <Flex container gap0 wrap className='AvatarPicker__Avatars'>
                {
                  For(avatars, (avatar: UserAvatar, index) => (
                    <img 
                      key={index}
                      src={`${baseUrl}${avatar.uri}`} 
                      alt={avatar.filename} 
                      onClick={() => props.onChange(avatar)}
                    />
                  ))
                }
              </Flex>
            ))
            .EndIf()
        }
      </div>
    );
  });
