import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

export type SpacerImage = [ 
  width: number, 
  height: number, 
  imageData: string
]

interface SpacerProps extends IntrinsicProps {
  wide?: boolean
  tall?: boolean
  spacer: SpacerImage
}

const spacerStates = [
  'wide',
  'tall'
];

const Spacer = createComponent<SpacerProps>('Spacer', 
{ classStates: spacerStates }, 
function Spacer ({ className, style }, props) {
  const [ width, height, imageData ] = props.spacer;

  return (
    <img 
      className={className} 
      style={style} 
      src={imageData} 
      aria-hidden={true} 
      width={width} 
      height={height}
    />
  );
});
export default Spacer;

export const ASPECT_1_1: SpacerImage = [ 1, 1, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC' ];

export const ASPECT_4_3: SpacerImage = [ 4, 3, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBlcCBAAAAAICg/KlnOLYAADMAAcyCH7AAAAAASUVORK5CYII=' ];

export const ASPECT_2_1: SpacerImage = [ 2, 1, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAALSURBVHgBY2CAAgAACQABzwe78AAAAABJRU5ErkJggg==' ];

export const ASPECT_37_20: SpacerImage = [ 37, 20, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAUCAYAAAAKuPQLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAaSURBVHgB7cAxAQAAAMKg9U9tDQ8oAADgyQALpAABkoFzuQAAAABJRU5ErkJggg==' ];

export const ASPECT_16_9: SpacerImage = [ 16, 9, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAA7KqwyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VMr4TTYAgICSQABhN4O5AAAAABJRU5ErkJggg==' ];

export const ASPECT_16_10: SpacerImage = [ 16, 10, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VOr4TDYCgQCigABFmtWywAAAABJRU5ErkJggg==' ];

export const ASPECT_21_9: SpacerImage = [ 21, 9, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAJCAYAAADdA2d2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAWSURBVHgB7cABAQAAAEAg/1cbQrBFCQL9AAEJ+XC8AAAAAElFTkSuQmCC' ];

export const ASPECT_9_16: SpacerImage = [ 9, 16, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VMr4TTYOgICUAABfwAT2wAAAABJRU5ErkJggg==' ];

export const ASPECT_6_5: SpacerImage = [ 6, 5, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAFCAYAAABmWJ3mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOEYBAH0AAWpzv30AAAAASUVORK5CYII=' ];
