import { GameTile, GameTileThumbnail, GameTileVideoThumbnail, Icon, IconFA } from '@/common/components';
import { createComponent, If, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import { Game, GameBasic } from '@/util/formatters';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { decode } from 'html-entities';
import { useEffect, useState } from 'react';

import style from './index.module.scss';

const classStates = [
  'small'
];

interface AgGameTileProps extends IntrinsicProps {
  game: GameBasic | Game
  small?: boolean
  highRes?: boolean
  priority?: boolean
  recentGameTile?: boolean
  instantPlay?: boolean
  showLabel?: boolean
}

function getLabel (game: GameBasic) {
  if (game.totalCount >= 1_000_000) {
    return [ 'popular', 'Most Popular' ];
  }

  else if (game.flags?.classic) {
    return [ 'classic', 'Classic' ];
  }

  else if (game.flags?.featured) { 
    return [ 'featured', 'Featured' ];
  }

  else if (game.flags?.trending) {
    return [ 'trending', 'Trending' ];
  }

  return null;
}

export default createComponent<AgGameTileProps>('AgGameTile', 
  { classStates, style }, 
  function AgGameTile ({ className, slots }, props) {
    const thumbnailUrl = props.game.thumbnailUrl;
    const videoThumbnailUrl = props.game.videoThumbnailUrl;

    const [ windowWidth, setWindowWidth ] = useState(9999);
    useEffect(() => {
      setWindowWidth(window.innerWidth);
    }, []);

    const label = props.showLabel
      ? getLabel(props.game)
      : null;

    return (
      <GameTile
        className={className}
        href={props.game.path + (props.instantPlay ? '?play=instant' : '')}
        prefetch={props.priority}
      >
        <Slot name='preview' key={props.game.nid}>
          {
            If(props.game.videoThumbnailUrl, () => (
              <GameTileVideoThumbnail 
                src={[ videoThumbnailUrl ]} 
                alt={props.game.title}
                poster={thumbnailUrl}
                priority={props.priority}
                key={'video_thumbnail_' + props.game.nid}
              />
            ))
              .ElseIf(props.game.thumbnailUrl, () => (
                <GameTileThumbnail 
                  src={thumbnailUrl} 
                  alt={props.game.title}
                  priority={props.priority}
                  key={'game_thumbnail_' + props.game.nid}
                />
              ))
              .EndIf()
          }
        </Slot>
        <Slot name='description'>
          {decode(props.game.title)}
        </Slot>
        {
          If(label, () => (
            <Slot name='status'>
              <span className={`AgGameTile__Label AgGameTile__Label--${label[ 0 ]}`}>
                {label[ 1 ]}
              </span>
              <></>
            </Slot>
          ))
            .ElseIf(slots?.status, () => (
              <Slot name='status'>
                <>{slots.status}</>
              </Slot>
            ))
            .ElseIf(!props.game.supportsMobile && windowWidth <= process.env.APP_CONFIG.BREAKPOINTS.tablet, () => (
              <Slot name='status'>
                <span className='AgGameTile__DesktopOnly'>
                  <IconFA icon={faDesktop} /> Desktop Only
                </span>
              </Slot>
            ))
            .EndIf()
        }
      </GameTile>
    );
  });
