import { useRef } from 'react';

export function useLoadScripts (scripts) {
  const scriptsLoaded = useRef(false);
  
  if (Array.isArray(scripts) && typeof window !== 'undefined' && !scriptsLoaded.current) {
    scripts.forEach((script) => {
      const element = document.createElement('script');
      element.src = script;
      element.type = 'text/javascript';
      const position = document.querySelector('body');
      position.appendChild(element);
    });
    scriptsLoaded.current = true;
  }
}
