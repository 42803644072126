// Third-party
import React, { 
  MouseEventHandler,
  useEffect, 
  useState 
} from 'react';
import { 
  faLock, 
  faUser 
} from '@fortawesome/free-solid-svg-icons';

// First-party
import { 
  Slot, 
  If, 
  createComponent,
  IntrinsicProps
} from '@/common/util/templateHelpers';
import { 
  useAuthStore, 
  authorize 
} from '@/stores/auth';
import { useForm } from '@/common/hooks/form';
import { useNotifications } from '@/common/hooks/notifications';

// Components
import Block from '@/common/components/Block';
import { IconFA } from '@/common/components/Icon';
import Button from '@/common/components/controls/Button';
import { InputField, InputType } from '@/common/components/controls/Input';
import { RecaptchaField, RecaptchaTheme } from '@/common/components/controls/Recaptcha';
import Form, { FieldType } from '@/common/components/forms/Form';
import Flex from '@/common/components/Flex';
import RouterLink from '@/common/components/RouterLink';
import { swagSdkEventListener } from '@/hooks/useSwagMessages';

interface LoginFormProps extends IntrinsicProps {
  captcha?: boolean
  buttonText?: string
  onSuccess?: (data: LoginFormData) => void
  onError?: (errors: any) => void
  onSubmit?: (data: LoginFormData) => void
  onRegisterClicked?: () => void | boolean
  onRecoverPasswordClicked?: () => void | boolean
}

interface LoginFormData {
  username: string,
  password: string
}

export default createComponent<LoginFormProps>('LoginForm', {}, function LoginForm ({ className }, props) {
  const schema = {
    username: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    },
    password: {
      value: '',
      schema: {
        type: FieldType.PASSWORD,
        required: true
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const form = useForm(schema);
  const { notify } = useNotifications();
  const [ state, dispatch ] = useAuthStore();
  const [ loading, setLoading ] = useState(false);

  const submit = async () => {
    const { data, errors, hasErrors } = form.validate<LoginFormData>();
    if (hasErrors) return props.onError && props.onError(errors);

    setLoading(true);

    await dispatch(authorize, {
      ...data,
      onError () {
        notify('Invalid username or password.', 'error');
      },
      onSuccess () {
        if (props.onSuccess) props.onSuccess(data);
        swagSdkEventListener.trigger('sendToken');
        notify('You are now logged in.', 'success');
      }
    });

    if (props.onSubmit) props.onSubmit(data);
  };

  useEffect(() => {
    setLoading(false);
  }, [ state ]);

  const captureRegister = (evt: React.MouseEvent) => {
    if (props.onRegisterClicked) props.onRegisterClicked() && evt.preventDefault();
  };

  const captureRecoverPassword = (evt: React.MouseEvent) => {
    if (props.onRecoverPasswordClicked) props.onRecoverPasswordClicked() && evt.preventDefault();
  };

  return (
    <Form className={className} onSubmit={submit}>
      <Block>
        <InputField name='username' type={InputType.ANY} form={form} placeholder='Username'>
          <Slot name='icon'><IconFA icon={faUser} /></Slot>
        </InputField>
        <InputField name='password' type={InputType.PASSWORD} form={form} placeholder='Password'>
          <Slot name='icon'><IconFA icon={faLock} /></Slot>
        </InputField>
        {
          If(props.captcha, () => (
            <RecaptchaField theme={RecaptchaTheme.DARK} name='captchaResult' form={form} siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}>&nbsp;</RecaptchaField>
          )).EndIf()
        }
      </Block>
      <Block>
        <Button primary submit disabled={form.hasErrors} loading={loading}>{props.buttonText || 'Login'}</Button>
      </Block>
      <Block>
        <Flex gap0 container directionColumn>
          <Flex justifyCenter>
            <p>Don't have an account? <RouterLink className='Link' href='/account/register' onClick={captureRegister}>Register Here</RouterLink></p>
          </Flex>
          <Flex justifyCenter>
            <p>Forgot your password? <RouterLink className='Link' href='/account/password' onClick={captureRecoverPassword}>Recover Password</RouterLink></p>
          </Flex>
        </Flex>
      </Block>
    </Form>
  );
});
