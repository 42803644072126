import uniqid from 'uniqid';
import { createStore } from '@/common/util/store';

const LIFE_SPAN = 2000

export interface Notification {
  id: string
  message: string
  aliveFor: number
  type?: 'warning' | 'success' | 'error'
}

export interface NotificationsState {
  notifications: Notification[]
}

// Mutations

function PUSH (state, payload) {
  const notifications = [ ...state.notifications ];
  notifications.push(payload);

  return {
    notifications
  };
}

function SPLICE (state, index) {
  const notifications = [ ...state.notifications ];
  notifications.splice(index, 1);

  return {
    notifications
  };
}

// Actions

function showNotification (payload, mutate, state) {
  const { message, lifeSpan, type } = payload;

  mutate(PUSH, {
    id: uniqid(),
    message,
    aliveFor: state.notifications.length 
      ? (lifeSpan / 2) 
      : lifeSpan,
    type,
  });
}

function dismissNotification (payload, mutate) {
  mutate(SPLICE, payload);
}

// Store

const {
  Provider,
  useStore
} = createStore<NotificationsState>({
  defaultState: {
    notifications: []
  }
});

// Main export

export function useNotifications ({ lifeSpan } = { lifeSpan: LIFE_SPAN }) {
  const [ state, dispatch ] = useStore();

  const notify = (message: string, type?: 'warning' | 'success' | 'error') => {
    dispatch(showNotification, { message, lifeSpan, type });
  };

  const dismiss = (index: number) => {
    dispatch(dismissNotification, index);
  };

  return {
    state,
    notify,
    dismiss,
    Provider
  };
}
