import { If } from '@/common/util/templateHelpers';
import { Game, GameBasic } from '@/util/formatters';
import Head from 'next/head';
import { useRouter } from 'next/router';
import rating from '@/common/util/rating';

interface LDAggregateProps {
  '@type': string;
  name: string;
  ratingValue: number;
  ratingCount: number;
  bestRating: number;
  worstRating: number;
}

interface LDOffersProps {
  '@type': string;
  availability: string;
  price: string;
  priceCurrency: 'USD' | 'CAD';
}

interface LDScreenshotProps {
  '@type': string;
  thumbnailUrl: string;
}

interface LDCreatorProps {
  '@type': string;
  '@id'?: string;
  name: string;
  url?: string;
}

interface LDItemListProps {
  '@type': string;
  position: number;
  name?: string;
  item: string | {
    '@id': string;
    name: string;
    item?: string;
  };
}

interface LDPotentialActionProps {
  '@type': string;
  name: string;
  target: string;
  'query-input': string;
}

interface LDMainEntityProps {
  '@type': string;
  name: string;
  acceptedAnswer?: {
    '@type': string;
    text: string;
  }
}

interface LDContactPoint {
  '@type': 'ContactPoint',
  contactType: string;
  url: string;
  email: string;
}

interface LDProps {
  '@context': string;
  '@type': string | string[];
  '@id': string;
  name: string;
  description: string;
  image: string;
  logo: string;
  url: string;
  email: string;
  breadcrumb: Partial<LDProps>
  applicationCategory: string;
  applicationSubCategory: string;
  operatingSystem: string;
  sameAs: string[];
  contactPoint: LDContactPoint[];
  inLanguage: string[];
  mainEntity: LDMainEntityProps[];
  itemListElement: LDItemListProps[];
  aggregateRating: Partial<LDAggregateProps>;
  potentialAction: Partial<LDPotentialActionProps>;
  offers: Partial<LDOffersProps>;
  screenshot: Partial<LDScreenshotProps>;
  publisher: Partial<LDCreatorProps>;
  genre: string[];
  gamePlatform: string[];
  creator: Partial<LDCreatorProps>;
}

export interface MetaProps {
  title: string;
  titleTag: string;
  name?: string;
  description?: string;
  keywords?: string;
  image?: string;
  itemDescription?: string;
  profile?: string;
  profilePicture?: string;
  itemImage?: string;
  thumbnail?: string;
  openGraph?: Partial<{
    site_name: string;
    title: string;
    type: string;
    url: string;
    image: string;
    video: string;
    locale: string;
    region: string;
    country_name: string;
    updated_time: string;
    'person:username': string;
    'profile:username': string;
    'article:published_time': string;
    'article:modified_time': string;
    'image:width': string;
    'image:height': string;
    'image:type': string;
    description?: string;
  }>;
  twitter?: Partial<{
    title: string;
    description: string;
    image: string;
    url: string;
    card: string;
  }>;
  ldjson?: Partial<LDProps>[];
  sitemapProps?: {
    priority?: string;
    excludeFromSitemap?: boolean
  };
  noIndex?: boolean
}

export interface MetaData extends MetaProps {}

export const generateGamePageLDJSON = (game: Game): Partial<LDProps>[] => {
  
  return [
    {
      '@context':'https://schema.org/',
      '@type': 'WebApplication',
      name: String(game?.title || '').trim(),
      image: game.thumbnailUrl,
      aggregateRating:{
        '@type':'AggregateRating',
        ratingValue: Number(rating(game?.rating)),
        ratingCount: Number(game?.totalVotes || 1),
        bestRating: 5,
        worstRating: 1,
      },
      applicationCategory: 'Game',
      applicationSubCategory: String(game?.genre?.name || '').trim(),
      description: String(game?.descriptionSimple || '').trim(),
      'offers': {
        '@type': 'Offer',
        'availability': 'http://schema.org/InStock',
        'price': '0',
        'priceCurrency': 'USD'
      },
      creator: {
        '@type':'Person',
        name: String(game?.developer || '').trim()
      },
      operatingSystem: game.supportsMobile ? 'Windows, Linux, MacOS, iOS, Android, any' : 'Windows, Linux, MacOS',
      'screenshot':{
        '@type':'ImageObject',
        thumbnailUrl: game.thumbnailUrl
      }
    },
    {
      '@context':'http://schema.org',
      '@type':'BreadcrumbList',
      itemListElement: [
        {
          '@type':'ListItem',
          position:1,
          item:{
            '@id': process.env.APP_CONFIG.URL,
            name:'Games'
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': process.env.APP_CONFIG.URL + game.genre.path,
            name: String(game?.genre?.name || '').trim()
          }
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': process.env.APP_CONFIG.URL + game.path,
            name: String(game?.title || '').trim()
          }
        }
      ]
    },
    {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type':'Question',
          name:`How do you play ${String(game?.title || '').trim()}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: String(game?.instructions || '').trim() || ''
          }
        },
        {
          '@type':'Question',
          name: `Who developed ${game?.title || ''}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: String(game?.developer || '').trim()
          }
        },
        {
          '@type':'Question',
          name:`When was ${String(game?.title || '')} released?`,
          acceptedAnswer:{
            '@type':'Answer',
            text:`Released date: ${String(game?.published || '')}`
          }
        },
        {
          '@type':'Question',
          name:`Are there any tips for ${String(game?.title || '')}?`,
          acceptedAnswer:{
            '@type':'Answer',
            text: String(game.instructions || '').trim()
          }
        }
      ]
    }
  ];
};

export const generateGameSimpleLDJSON = (game: Game): Partial<LDProps>[] => {
  const gameLDJSON = {
    '@context':'https://schema.org/',
    '@type': 'WebApplication',
    name: String(game?.title || '').trim(),
    image: game.thumbnailUrl,
    url: process.env.APP_CONFIG.URL + game.path,
    aggregateRating:{
      '@type':'AggregateRating',
      ratingValue: Number(rating(game?.rating)),
      ratingCount: Number(game?.totalVotes || 1),
      bestRating: 5,
      worstRating: 1,
    },
    applicationCategory: 'Game',
    description: String(game?.descriptionSimple || '').trim(),
    operatingSystem: game.supportsMobile ? 'Windows, Linux, MacOS, iOS, Android, any' : 'Windows, Linux, MacOS',
    screenshot:{
      '@type':'ImageObject',
      thumbnailUrl: game.thumbnailUrl
    }
  };

  if (typeof game?.thumbnailUrl === 'undefined') {
    delete gameLDJSON.screenshot;
    delete gameLDJSON.image;
  }

  if (typeof game?.supportsMobile === 'undefined')
    delete gameLDJSON.operatingSystem;

  return [ gameLDJSON ];
};

export const generateGameListLDJSON = (data): Partial<LDProps>[] => {
  return [
    {
      '@type': 'ItemList',
      itemListElement: data.map((game, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: process.env.APP_CONFIG.URL + game.path,
        item: generateGameSimpleLDJSON(game)[ 0 ]
      }))
    }
  ];
};

export const generateCategoryListLDJSON = (basePath: string, data): Partial<LDProps>[] => {
  return [
    {
      '@type': 'ItemList',
      itemListElement: data.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: basePath + item.path,
        item: generateCategoryLDJSON(basePath, item)[ 0 ]
      }))
    }
  ];
};

export const generateCategoryLDJSON = (basePath: string, category): Partial<LDProps>[] => {
  const catLDJSON = {
    '@context':'https://schema.org/',
    '@type':'WebPage',
    name: String(category?.title || '').trim(),
    image: category.thumbnailUrl.trim(),
    url: basePath + category.path,
  };

  return [ catLDJSON ];
};

export const generatePlaylistListLDJSON = (basePath: string, data): Partial<LDProps>[] => {
  return [
    {
      '@type': 'ItemList',
      itemListElement: data.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: basePath + item.path,
        item: generateCategoryLDJSON(basePath, item)[ 0 ]
      }))
    }
  ];
};

export const generatePlaylistLDJSON = (basePath: string, category): Partial<LDProps>[] => {
  const catLDJSON = {
    '@context':'https://schema.org/',
    '@type':'WebPage',
    name: String(category?.title || '').trim(),
    image: category.thumbnailUrl,
    url: basePath + category.path,
  };

  return [ catLDJSON ];
};


export const generateTagFAQLDJSON = (
  name: string, 
  gamesList: GameBasic[], 
  gamesMobileList: GameBasic[],
  description: string,
): Partial<LDProps>[] => {

  let stringGames: string = '', stringMobileGames: string = '';
  gamesList.map((game) => stringGames += `<li><span><a href='${process.env.APP_CONFIG.URL + game.path}'>${game.title.trim()}</a></span></li>`);
  gamesMobileList.map((game) => stringMobileGames += `<li><span><a href='${process.env.APP_CONFIG.URL + game.path}'>${game.title.trim()}</a></span></li>`);

  return [
    {
      '@context':'https://schema.org',
      '@type':'FAQPage',
      mainEntity:[
        {
          '@type':'Question',
          name: `What are the most popular ${String(name || '').trim()} Games?`,
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<ol>${stringGames}</ol>`
          }
        },
        {
          '@type':'Question',
          name:`What are the best ${String(name || '').trim()} Games to play on phones?`,
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<ol>${stringMobileGames}</ol>`
          }
        },
        {
          '@type':'Question',
          name:`What are ${String(name || '').trim()} Games?`,
          acceptedAnswer: {
            '@type': 'Answer',
            text: description
          }
        }
      ]
    }
  ];
};

export const generateGenreFAQLDJSON = (
  name: string, 
  gamesList: GameBasic[], 
  gamesMobileList: GameBasic[],
  description?: string | boolean
): Partial<LDProps>[] => {

  let stringGames: string = '', stringMobileGames: string = '';
  gamesList.map((game) => stringGames += `<li><span><a href='${process.env.APP_CONFIG.URL + game.path}'>${game.title.trim()}</a></span></li>`);
  gamesMobileList.map((game) => stringMobileGames += `<li><span><a href='${process.env.APP_CONFIG.URL + game.path}'>${game.title.trim()}</a></span></li>`);
  const result = [
    {
      '@context':'https://schema.org',
      '@type':'FAQPage',
      mainEntity:[
        {
          '@type':'Question',
          name: `What are the most popular ${String(name || '').trim()} Games?`,
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<ol>${stringGames}</ol>`
          }
        },
        {
          '@type':'Question',
          name:`What are the best ${String(name || '').trim()} Games to play on phones?`,
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<ol>${stringMobileGames}</ol>`
          }
        },
      ]
    }
  ];

  if (description) {
    result[ 0 ].mainEntity.push(
      {
        '@type':'Question',
        // @ts-ignore
        name:`What are ${String(name || '').trim()} Games?`,
        acceptedAnswer: {
          '@type': 'Answer',
          text: String(description || '').trim()
        }
      }
    );
  }
  return result;
};

export const generateBreadcrumbListLDJSON = (data: { url: string, name: string }[]): Partial<LDProps>[] => {
  return [
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: process.env.APP_CONFIG.DEFAULT_TITLE,
          item: process.env.APP_CONFIG.URL
        },

        ...data.map((breadcrumb, index) => ({
          '@type': 'ListItem',
          position: index + 2,
          name: breadcrumb.name.trim(),
          item: process.env.APP_CONFIG.URL + breadcrumb.url
        }))
      ]
    }
  ];
};

export const generateTaxonomyBreadcrumbListLDJSON = (name, image, description, path, data: { url: string, name: string }[]): Partial<LDProps>[] => {
  return [
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: `${name} Games`, 
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: process.env.APP_CONFIG.DEFAULT_TITLE,
            item: process.env.APP_CONFIG.URL
          },
  
          ...data.map((breadcrumb, index) => ({
            '@type': 'ListItem',
            position: index + 2,
            name: breadcrumb.name.trim(),
            item: process.env.APP_CONFIG.URL + breadcrumb.url
          }))
        ]
      },
      image: `${process.env.APP_CONFIG.URL}${image}`,
      description: (description || '').trim(),
      publisher: {
        '@type': 'Organization',
        '@id': 'https://www.addictinggames.com',
        name: 'Addicting Games',
        url: 'https://www.addictinggames.com'
      },
      url: `${process.env.APP_CONFIG.URL}${path}`
    },
  ];
};

export const generateContactLDJSON = (path): Partial<LDProps>[] => {
  return [
    {
      '@context': 'https://schema.org/',
      '@type': 'ContactPage',
      name: process.env.APP_CONFIG.DEFAULT_TITLE,
      url: process.env.APP_CONFIG.URL + path
    }
  ];
};

export const generateWebsiteLDJSON = (path: string): Partial<LDProps>[] => {
  const formattedData = {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: process.env.APP_CONFIG.DEFAULT_TITLE,
    url: process.env.APP_CONFIG.URL + path,
  };

  if (process.env.APP_CONFIG.SEARCH_URL) {
    // @ts-ignore
    formattedData.potentialAction = {
      '@type': 'SearchAction',
      target: `${process.env.APP_CONFIG.SEARCH_URL}{search_term_string}`,
      'query-input': 'required name=search_term_string'
    };
  }

  return [ formattedData ];
};

export const generateOrganizationLDJSON = (): Partial<LDProps>[] => {
  return [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: process.env.APP_CONFIG.ORGANIZATION.NAME,
      url: process.env.APP_CONFIG.ORGANIZATION.URL,
      sameAs: [
        ...(process.env.APP_CONFIG.ORGANIZATION.SOCIAL_LINKS || [])
      ]
    }
  ];
};

const Meta = (props: Partial<MetaProps>) => {

  const router = useRouter();

  /*
    The idea here is to use the props to generate the core of the meta tags
    and then if a prop is non-existant, we will have a default fallback.

    This doesn't apply to LD+JSON which is a bit more complex
  */
  const titleValue = props.title || props.name || process.env.APP_CONFIG.DEFAULT_TITLE || null;

  const json: MetaProps = {
    title: props.titleTag,
    name: props.name || props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
    description: props.description || process.env.APP_CONFIG.DESCRIPTION || null,
    keywords: props.keywords || process.env.APP_CONFIG.KEYWORDS || null,
    itemDescription: props?.itemDescription || null,
    itemImage: props?.itemImage || null,
    thumbnail: props?.thumbnail || null,
    profile: props?.profile || null,
    profilePicture: props?.profilePicture || null,
    openGraph: {
      site_name: process.env.APP_CONFIG.APP_TITLE || null,
      description: props.openGraph?.description || null,
      title: props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
      type: props.openGraph?.type! || 'game' || null,
      url: props.openGraph?.url || process.env.APP_CONFIG.URL || null,
      region: props.openGraph?.region || 'United States',
      locale: props.openGraph?.locale || 'en_US',
      country_name: props.openGraph?.country_name || 'United States',
      updated_time: props.openGraph?.updated_time || null,
      image: props.image || null,
      'person:username': props.openGraph?.[ 'person:username' ] || null,
      'article:published_time': props.openGraph?.[ 'article:published_time' ] || null,
      'article:modified_time': props.openGraph?.[ 'article:modified_time' ] || null,
      'image:width': props.openGraph?.[ 'image:width' ] || null,
      'image:height': props.openGraph?.[ 'image:height' ] || null,
      'image:type': props.openGraph?.[ 'image:type' ] || null,
    },
    twitter: {
      title:  props.twitter?.title || props.title || process.env.APP_CONFIG.DEFAULT_TITLE || null,
      description: props.twitter?.description || props.description || process.env.APP_CONFIG.DESCRIPTION || null,
      url: props.twitter?.url || process.env.APP_CONFIG.URL || null,
      image: props.image || null,
      card: props.twitter?.card! || 'summary' || null,
    },
    ldjson: [ ...(props?.ldjson || []) ],
    titleTag: ''
  };
  
  return (
    <Head>
      {/* Configuration */}
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover' />
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta httpEquiv='content-language' content='en' />
      <meta httpEquiv='x-ua-compatible' content='IE=edge' />
      <meta httpEquiv='audience' content='General' />

      {/** meta tags */}
      <meta name='description' content={process.env.APP_CONFIG.DESCRIPTION} key='description' />
      <meta name='abstract' content={process.env.APP_CONFIG.DESCRIPTION} key='abstract' />
      <meta name='keywords' content={process.env.APP_CONFIG.KEYWORDS} key='keywords'/>
      <link rel='canonical' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='canonical' />
      <link rel='shortlink' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='shortlink' />
      <meta property='og:type' content='game' key='og-type'/>
      <meta property='og:site_name' content={process.env.APP_CONFIG.APP_TITLE} key='og-sitename' />
      <meta property='og:url' content={`${process.env.APP_CONFIG.URL}${router.asPath}`} key='og-url'/>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:url' content={`${process.env.APP_CONFIG.URL}${router.asPath}`} />
      {
        If(props.noIndex, () => (
          <meta name='robots' content='noindex' />
        ))
          .EndIf()
      }

      {/* Favicons */}
      <link href='/favicon.ico' rel='icon' />
      <link href='/favicon.png' rel='shortcut icon' type='image/png' />
      <link href='/images/app/favicon-16.png' rel='icon' type='image/png' sizes='16x16' />
      <link href='/images/app/favicon-32.png' rel='icon' type='image/png' sizes='32x32' />
      <link href='/images/app/favicon-48.png' rel='icon' type='image/png' sizes='48x48' />
      <link href='/images/app/favicon-48.png' rel='mask-icon' color={process.env.APP_CONFIG.THEME} />

      {/* Orientation  */}
      <meta name='screen-orientation' content='portrait' />

      {/* Android */}
      <meta name='theme-color' content='#171717' />
      <meta name='mobile-web-app-capable' content='yes' />
      <link href='/images/app/icon-128.png' rel='icon' sizes='128x128' />
      <link href='/images/app/icon-192.png' rel='icon' sizes='192x192' />

      {/* iOS */}
      <meta name='apple-mobile-web-app-title' content={process.env.APP_CONFIG.DEFAULT_TITLE} />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <link href='/images/app/icon-76.png' rel='apple-touch-icon' />
      <link href='/images/app/icon-76.png' rel='apple-touch-icon' sizes='76x76' />
      <link href='/images/app/icon-120.png' rel='apple-touch-icon' sizes='120x120' />
      <link href='/images/app/icon-152.png' rel='apple-touch-icon' sizes='152x152' />
      <link href='/images/app/splash-1024.png' rel='apple-touch-startup-image' />

      {/* Overrides */}
      {If(json.title, () => <title>{json.title.trim()}</title>).EndIf()}
      {If(json.name, () => <meta itemProp='name' content={json.name.trim()} />).EndIf()}
      {If(json.itemDescription, () => <meta itemProp='description' content={json.itemDescription.trim()} />).EndIf()}
      {If(json.itemImage, () => <meta itemProp='image' content={json.itemImage} />).EndIf()}
      {If(json.thumbnail, () => <meta name='thumbnail' content={json.thumbnail} />).EndIf()}
      {If(json.description, () => <meta name='description' content={json.description.trim()} key='description'/>).EndIf()}
      {If(json.keywords, () => <meta name='keywords' content={json.keywords.trim()} key='keywords'/>).EndIf()}
      {If(json.openGraph.site_name, () => <meta property='og:site_name' content={json.openGraph.site_name.trim()} />).EndIf()}
      {If(json.openGraph.title, () => <meta property='og:title' content={json.openGraph.title.trim()} />).EndIf()}
      {If(json.openGraph.type, () => <meta property='og:type' content={json.openGraph.type.trim()} key='og-type'/>).EndIf()}
      {If(json.openGraph.url, () => <meta property='og:url' content={json.openGraph.url.trim()} key='og-url'/>).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.description, () => <meta property='og:description' content={json.openGraph.description.trim()} />).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image:url' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.image, () => <meta property='og:image:secure_url' content={json.openGraph.image} />).EndIf()}
      {If(json.openGraph.country_name, () => <meta property='og:country_name' content={json.openGraph.country_name.trim()} />).EndIf()}
      {If(json.openGraph.region, () => <meta property='og:region' content={json.openGraph.region.trim()} />).EndIf()}
      {If(json.openGraph.locale, () => <meta property='og:locale' content={json.openGraph.locale.trim()} />).EndIf()}
      {If(json.openGraph.updated_time, () => <meta property='og:updated_time' content={json.openGraph.updated_time.trim()} />).EndIf()}
      {If(json.profilePicture, () => <link property='image_src' href={json.profilePicture.trim()} />).EndIf()}
      {If(json.profilePicture, () => <meta name='twitter:image' content={json.profilePicture.trim()} />).EndIf()}
      {If(json.profilePicture, () => <meta property='og:image' content={json.profilePicture.trim()} key='og-image' />).EndIf()}
      {If(json.profile, () => <meta property='profile:username' content={json.profile.trim()} />).EndIf()}
      {If(json.openGraph[ 'profile:username' ], () => <meta property='profile:username' content={json.openGraph[ 'profile:username' ].trim()} />).EndIf()}

      {If(json.openGraph[ 'article:modified_time' ], () => <meta property='article:modified_time' content={json.openGraph[ 'article:modified_time' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'article:published_time' ], () => <meta property='article:published_time' content={json.openGraph[ 'article:published_time' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:width' ], () => <meta property='og:image:width' content={json.openGraph[ 'image:width' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:height' ], () => <meta property='og:image:height' content={json.openGraph[ 'image:height' ].trim()} />).EndIf()}
      {If(json.openGraph[ 'image:type' ], () => <meta property='og:image:type' content={json.openGraph[ 'image:type' ].trim()} />).EndIf()}
      {If(json.twitter.title, () => <meta name='twitter:title' content={json.twitter.title.trim()} />).EndIf()}
      {If(json.twitter.description, () => <meta name='twitter:description' content={json.twitter.description.trim()} />).EndIf()}
      {If(json.twitter.card, () => <meta name='twitter:card' content={json.twitter.card.trim()} />).EndIf()}
      {If(json.twitter.image, () => <meta name='twitter:image' content={json.twitter.image} />).EndIf()}

      {/* LD JSON */}
      {
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ 
            __html: `[ ${json.ldjson.map(ld => JSON.stringify(ld, null, 2) )} ]` //JSON.stringify(, null, 2) 
          }} 
        />  
      }
    </Head>
  );
};

export default Meta;