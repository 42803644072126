import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import PageLoader from '@/common/components/PageLoader';
import { useEffect } from 'react';
import { useGoogleAnalytics } from '../hooks/googleAnalytics';

interface PageProps extends IntrinsicProps {
  theme?: string
}

export default createComponent<PageProps>('Page', 
  {}, 
  function Page ({ style }, props) {
    useGoogleAnalytics({
      uakey: process.env.APP_CONFIG.GOOGLE_UA_ID,
      // ga4key: process.env.APP_CONFIG.GOOGLE_GA4_ID,
    });

    useEffect(() => {
      document.body.dataset.theme = props.theme;
    }, [ props.theme ]);

    return (
      <div style={style}>
        <PageLoader />
        {props.children}
      </div>
    );
  });
