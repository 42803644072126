import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { MutableRefObject } from 'react';

interface FlexProps extends IntrinsicProps {
  inline?: boolean
  wrap?: boolean
  wrapReverse?: boolean
  directionColumn?: boolean
  directionColReverse?: boolean
  directionRow?: boolean
  directionRowReverse?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  alignBaseline?: boolean
  alignStretch?: boolean
  justifyStart?: boolean
  justifyEnd?: boolean
  justifyCenter?: boolean
  justifySpaceBetween?: boolean
  justifySpaceAround?: boolean
  justifySpaceEvenly?: boolean
  pullTop?: boolean
  pullRight?: boolean
  pullBottom?: boolean
  pullLeft?: boolean
  centerX?: boolean
  centerY?: boolean
  center?: boolean
  expand?: boolean
  fit?: boolean
  narrow?: boolean
  wide?: boolean
  tall?: boolean
  fill?: boolean
  rigid?: boolean
  threeQuarters?: boolean
  twoThirds?: boolean
  half?: boolean
  oneThird?: boolean
  oneQuarter?: boolean
  oneFifth?: boolean
  twoFifths?: boolean
  threeFifths?: boolean
  fourFifths?: boolean
  gap0?: boolean
  gap1?: boolean
  gap2?: boolean
  gap3?: boolean
  gap4?: boolean
  gap5?: boolean
  gap6?: boolean
  container?: boolean
  order?: number
  tag?: keyof JSX.IntrinsicElements
  gridGap?: string
  ref?: MutableRefObject<any>
  collapseMobile?: boolean
  collapseNarrow?: boolean
  collapseDesktop?: boolean
}

const flexStates = [
  'inline',
  'wrap',
  'wrapReverse',
  'directionColumn',
  'directionColReverse',
  'directionRow',
  'directionRowReverse',
  'alignStart',
  'alignEnd',
  'alignCenter',
  'alignBaseline',
  'alignStretch',
  'justifyStart',
  'justifyEnd',
  'justifyCenter',
  'justifySpaceBetween',
  'justifySpaceAround',
  'justifySpaceEvenly',
  'pullTop',
  'pullRight',
  'pullBottom',
  'pullLeft',
  'centerX',
  'centerY',
  'center',
  'expand',
  'fit',
  'narrow',
  'wide',
  'tall',
  'fill',
  'rigid',
  'threeQuarters',
  'twoThirds',
  'half',
  'oneThird',
  'oneQuarter',
  'oneFifth',
  'twoFifths',
  'threeFifths',
  'fourFifths',
  'gap0',
  'gap1',
  'gap2',
  'gap3',
  'gap4',
  'gap5',
  'gap6',
  'container',
  'collapseMobile',
  'collapseNarrow',
  'collapseDesktop'
];

const Flex = createComponent<FlexProps>('Flex', 
{ classStates: flexStates },
function Flex ({ className, style, ref }, props) {
  style = {
    ...style,
    order: props.order,
    gridGap: props.gridGap
  };

  const Tag = (props.tag || 'div');

  return (
    // @ts-ignore - because onClicks handler could be anything
    <Tag ref={ref} className={className} style={style} onClick={props.onClick}>
      {props.children}
    </Tag>
  );
});
export default Flex;
