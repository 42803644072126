
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import anime from 'animejs';

import { createComponent } from '@/common/util/templateHelpers';
import { randomRange } from '@/common/util/random';

export default createComponent('PageLoader', {}, function PageLoader () {
  const router = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;

    const handleRouteChangeStart = async () => {
      await anime({
        targets: el,
        opacity: {
          easing: 'linear',
          value: 1,
          duration: 0
        },
        scaleX: [0, randomRange(0.4, 0.8)],
        easing: 'easeInOutSine',
        duration: 200
      }).finished;
    };
    router.events.on('routeChangeStart', handleRouteChangeStart)

    const handleRouteChangeComplete = async () => {
      await anime({
        targets: el,
        scaleX: 1,
        easing: 'easeInOutSine',
        duration: 200
      }).finished;
      await anime({
        targets: el,
        opacity: {
          easing: 'easeInOutSine',
          value: 0,
          duration: 200
        }
      }).finished;
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  return (
    <div ref={ref} className='PageLoader' />
  );
});
