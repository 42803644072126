import { toClassName, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface ContainerProps extends IntrinsicProps {
  noPadding?: boolean
  wide?: boolean
}

const containerClassStates = [
  'noPadding'
];

export default createComponent<ContainerProps>('Container', 
{ classStates: containerClassStates }, 
function Container ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      <div className={toClassName('Container__Inner', [ 'wide' ], props)}>
        {props.children}
      </div>
    </div>
  );
});
