import { createComponent, For, IntrinsicProps } from '@/common/util/templateHelpers';
import { GameBasic } from '@/util/formatters';
import AgGameTile from '@/components/AgGameTile';
import GameTileGrid, { GridHeight } from '@/components/GameTileGrid';
import { useEffect, useState } from 'react';
import BeforeYouGoModel from '@/apis/drupal/game';
import homePageApi from '@/apis/drupal/homePage';
import BeforeYouGoGamesModel from '@/models/BeforeYouGoGames';

interface BeforeYouGoUpsellProps extends IntrinsicProps {
}

export default createComponent<BeforeYouGoUpsellProps>('BeforeYouGoUpsell', 
  {}, 
  function BeforeYouGoUpsell ({ className }, props) {
    const [ topTrendingGames, setTopTrendingGames ] = useState([]);
    const [ botttomTrendingGames, setBottomTrendingGames ] = useState([]);

    useEffect(() => {
      const apiCall = async () => {
        const initialGames = await BeforeYouGoGamesModel.fetch();
        const additionalGames = await homePageApi.getTrendingGames(6,0);

        const games = [
          ...initialGames,
          ...additionalGames,
        ].slice(0, 6);

        setTopTrendingGames(games.slice(0, 3));
        setBottomTrendingGames(games.slice(3,6));
      };
      apiCall();
    }, []);
  
    return (
      <>
        <GameTileGrid 
          restrictHeight={GridHeight.ONE_ROW_MOBILE} 
          overflow={false}
          className={className}
        >
          {
            For( topTrendingGames, (game: GameBasic) => (
              <AgGameTile game={game} key={game.nid}/>
            )) 
          }
        </GameTileGrid>
        <br />
        <GameTileGrid 
          restrictHeight={GridHeight.ONE_ROW_MOBILE} 
          overflow={false}
          className={className}
        >
          {
            For( botttomTrendingGames, (game: GameBasic) => (
              <AgGameTile game={game} key={game.nid}/>
            )) 
          }
        </GameTileGrid>
      </>
    );
  });
