import React, { useState, useEffect, useRef, FocusEventHandler } from 'react';
import { useRouter } from 'next/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { useClickOutside } from '@/common/hooks/clickOutside';
import { useNotifications } from '@/common/hooks/notifications';
import { For, If, createComponent, toClassName, IntrinsicProps } from '@/common/util/templateHelpers';

import { IconFA } from '@/common/components/Icon';
import Control from '@/common/components/controls/Control';
import Field from '@/common/components/controls/Field';
import Loader from '@/common/components/Loader';

interface AutocompleteProps extends IntrinsicProps {
  up?: boolean
  right?: boolean
  tag?: string
  placeholder?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  showMenu: boolean;
  onSearchUpdate: (query: string) => void
  onSearch: (query: string) => void
  onSelect: (item: string) => void
  findItemPath: (string: string) => string
  loading?: boolean
  noIcon?: boolean
}

const autocompleteStates = [
  'up',
  'right'
];

const Autocomplete = createComponent<AutocompleteProps>('Autocomplete', 
  { classStates: autocompleteStates }, 
  function Autocomplete ({ className, style }, props) {
    const [ activeIndex, setActiveIndex ] = useState(-1);
    const [ query, setQuery ] = useState('');
    const router = useRouter();
    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    const handleKeyUp = () => {
      window.clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        props.onSearchUpdate(query);
      }, 250);
    };
  
    const handleKeyPress = (e) => {
      switch (e.which) {
      case 13: // Enter key
        router.push(`/search/${query}`);
        break;
      case 38: // Up arrow
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0);
        break;
      case 8: // Delete key
        break;
      case 40: // Down arrow
        break;
      }
    };

    return (
      <div className={className} style={style}>
        <div className='Autocomplete__Trigger'>
          <Field>
            <Control iconsRight={!props.noIcon}>
              <input
                className={`Input ${props.loading ? 'Input--loading' : ''}`}
                type='text' 
                placeholder={props.placeholder}
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={handleKeyPress}
                onKeyUp={handleKeyUp}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
              />
              {
                If(props.noIcon, () => (
                  <></>
                ))
                  .ElseIf(props.loading, () => (
                    <div className='Icon'>
                      <Loader />
                    </div>
                  ))
                  .Else(() => (
                    <IconFA icon={faSearch} />
                  )).EndIf()
              }
            </Control>
          </Field>
        </div>
        {
          If(props.showMenu, () => (
            <div className='Autocomplete__Menu' role='menu'>
              <div className='Autocomplete__Content'>
                {props.children}
              </div>
            </div>
          )).EndIf()
        }
      </div>
    );
  });
export default Autocomplete;
