import { If, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { instantIn, instantOut } from '@/common/transitions/instant';
import { useModal } from '@/common/hooks/modal';

import Delete from '@/common/components/controls/Delete';
import Transition from '@/common/components/Transition';
import { TransitionAttributes } from '.';

interface ModalProps extends IntrinsicProps {
  enterFrom?: TransitionAttributes
  enter?: TransitionAttributes
  leave?: TransitionAttributes
  name: string
}

const Modal = createComponent<ModalProps>('Modal', 
{}, 
function Modal ({ className, style }, props) {
  const { state, currentModal, Teleporter } = useModal();

  const enterFrom = props.enterFrom || state.enterFrom;
  const enter = props.enter || state.enter || instantIn();
  const leave = props.leave || state.leave || instantOut();

  return (
    <>
    {
      If(Teleporter, () => (
        <Teleporter.Source key={`modal_${props.name}`} multiple>
          <Transition visible={currentModal && currentModal.name === props.name} enterFrom={enterFrom} enter={enter} leave={leave}>
            <div className={className} style={style}>
              {props.children}
            </div>
          </Transition>
        </Teleporter.Source>
      ))
      .EndIf()
    }
    </>
  );
});
export default Modal;

/* --- */

interface ModalsProps extends IntrinsicProps {
  bgEnter?: TransitionAttributes
  bgLeave?: TransitionAttributes
  modalEnter?: TransitionAttributes
  modalLeave?: TransitionAttributes
}

export const Modals = createComponent<ModalsProps>('Modals', 
{}, 
function Modals ({ className, style }, props) {
  const { Provider, Teleporter, closeModal, currentModal, setTransitions } = useModal();

  const enter = props.bgEnter || instantIn();
  const leave = props.bgLeave || instantOut();

  if (props.modalEnter && props.modalLeave) {
    setTransitions(props.modalEnter, props.modalLeave);
  }

  return (
    <div className={className} style={style}>
      <Provider>
        <Transition visible={!!currentModal} enter={enter} leave={leave}>
          <div className='Modals__Bg' onClick={closeModal} />
        </Transition>
        <div className='Modals__Content'>
        {
          If(Teleporter, () => (
            <Teleporter.Target />
          ))
          .Else(() => (
            <div />
          ))
          .EndIf()
        }
        </div>
        {
          If(currentModal && !currentModal.options.hideClose, () => (
            <Delete large onClick={closeModal} />
          ))
          .EndIf()
        }
      </Provider>
    </div>
  );
});

/* --- */

interface ModalCardProps extends IntrinsicProps {
  autoWidth?: boolean,
  halfHeight?: boolean
}

const modalCardClassStates = [
  'autoWidth',
  'halfHeight'
];

export const ModalCard = createComponent<ModalCardProps>('ModalCard', 
{ classStates: modalCardClassStates }, 
function ModalCard ({ className, style, slots }) {
  const { closeModal } = useModal();

  return (
    <div className={className} style={style}>
      {
        If(slots.title, () => (
          <header className='ModalCard__Head'>
            <p className='ModalCard__Title'>{slots.title}</p>
            <Delete onClick={closeModal} />
          </header>
        ))
        .EndIf()
      }
      <section className='ModalCard__Body'>
        {slots.defaultSlot}
      </section>
      {
        If(slots.footer, () => (
          <footer className='ModalCard__Foot'>
            {slots.footer}
          </footer>
        ))
        .EndIf()
      }
    </div>
  );
});
