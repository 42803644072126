import { GameBasic, NumericId } from '@/util/formatters';
import { createStore, CacheStrategy } from '@/common/util/store';
import gameApi, { FAVORITES_LIMIT_MAX } from '@/apis/drupal/game';

export interface FavoriteGamesState {
  games: GameBasic[],
  gameIds: NumericId[],
  userId: NumericId | undefined,
  fetched: Boolean
}

const defaultState = {
  games: [],
  gameIds: [],
  fetched: false,
  userId: undefined
};

// Mutations

function SET_GAMES (state: FavoriteGamesState, payload: { favoriteGames: GameBasic[], userId: NumericId }) {
  const gameIds = payload.favoriteGames.map(game => game.nid);

  return {
    ...state,
    games: payload.favoriteGames,
    gameIds,
    userId: payload.userId
  };
}

function ADD_GAME (state: FavoriteGamesState, payload: GameBasic) {
  if(state.gameIds.includes(payload.nid)) return { ...state };

  return {
    ...state,
    games: [...state.games, payload],
    gameIds: [...state.gameIds, payload.nid]
  };
}

function REMOVE_GAME (state: FavoriteGamesState, payload: NumericId) {
  const games = state.games.filter(game => game.nid !== payload);
  const gameIds = games.map(game => game.nid);

  return {
    ...state,
    games,
    gameIds
  };
}

function SET_FETCHED(state: FavoriteGamesState, payload: Boolean) {
  return {
    ...state,
    fetched: payload
  };
}

function SET_USER(state: FavoriteGamesState, payload: NumericId) {
  return {
    ...state,
    userId: payload
  };
}

function RESET() {
  return {
    ...defaultState
  };
}

// Actions

export function setFavoriteGames (payload: {favoriteGames: GameBasic[], userId: NumericId}, mutate) {
  mutate(SET_GAMES, payload);
}

export function addFavoriteGame (payload: GameBasic, mutate) {
  mutate(ADD_GAME, payload);
}

export function removeFavoriteGame (payload: NumericId, mutate) {
  mutate(REMOVE_GAME, payload);
}

export function setFetched (payload: Boolean, mutate) {
  mutate(SET_FETCHED, payload);
}

export function setUser (payload: NumericId, mutate) {
  mutate(SET_USER, payload);
}

export function reset (payload, mutate) {
  mutate(RESET);
}

export async function fetchFavoriteGames (payload: NumericId, mutate) {

  mutate(SET_FETCHED, false);

  let page = 0;
  let favoriteGames = [];

  while (true) {
    const offset = page * FAVORITES_LIMIT_MAX;

    const games = await gameApi.listFavoriteGamesForUser(payload, FAVORITES_LIMIT_MAX, offset);

    if(games?.error) {
      mutate(RESET, null);
      return;
    }

    favoriteGames = [...favoriteGames, ...games];

    if(games.length === FAVORITES_LIMIT_MAX) {
      page++;
    } else {
      break;
    }
  }

  mutate(SET_GAMES, { favoriteGames, userId: payload });
  mutate(SET_FETCHED, true);

}

// Export

export const {
  Provider: FavoriteGamesProvider,
  useStore: useFavoriteGamesStore
} = createStore<FavoriteGamesState>({
  defaultState: { ...defaultState },
  cacheKey: 'favoriteGames',
  cacheStrategy: CacheStrategy.LOCAL,
  excludedKeys: ['fetched']
});
