import { useState, useEffect } from 'react';
import { If, For, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import Field, { FieldHelp, FieldLabel } from '@/common/components/controls/Field';
import Control from '@/common/components/controls/Control';

interface CheckboxProps extends IntrinsicProps {
  error?: boolean
  small?: boolean
  medium?: boolean
  large?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  value?: any,
  trueValue?: any,
  falseValue?: any,
  onChange?: (value: any) => void
}

const checkboxStates = [
  'error',
  'small',
  'medium',
  'large'
];

const Checkbox = createComponent<CheckboxProps>('Checkbox', { classStates: checkboxStates }, function Checkbox ({ className, style }, props) {
  const [ checked, setChecked ] = useState(props.value === props.trueValue);
  
  const change = () => {
    let trueValue = props.trueValue;
    if (trueValue === undefined || trueValue === null) trueValue = true;

    let falseValue = props.falseValue;
    if (falseValue === undefined || falseValue === null) falseValue = true;

    const nextChecked = !checked;

    if (props.onChange) {
      if (nextChecked) {
        props.onChange(trueValue);
      } else {
        props.onChange(falseValue);
      }
    }

    setChecked(nextChecked);
  };

  useEffect(() => {
    setChecked(props.value === props.trueValue);
  }, [props.value, props.trueValue, props.falseValue]);

  return (
    <label 
      className={className} 
      style={style}
    >
      <input
        type='checkbox'
        readOnly={props.readOnly}
        disabled={props.disabled}
        onClick={change}
        defaultChecked={checked}
      />
      <span />
      <span>{props.children}</span>
    </label>
  );
});
export default Checkbox;

/* --- */

interface CheckboxFieldProps extends CheckboxProps {
  name: string,
  form: any
}

export const CheckboxField = createComponent<CheckboxFieldProps>('CheckboxField', {}, function CheckboxField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
      <Control>
        <Checkbox
          key={key}
          value={form.data[key]}
          trueValue={schema.trueValue}
          falseValue={schema.falseValue}
          error={!!form.errors[key]} 
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={(value) => form.validateField(key, value)}
        >
          {slots.description}
        </Checkbox>
        {
          If(form.errors[key], () => (
            <FieldHelp error>{form.errors[key]}</FieldHelp>
          ))
          .ElseIf(slots.help, () => (
            <FieldHelp>{slots.help}</FieldHelp>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});

/* --- */

interface CheckboxFieldListProps extends CheckboxFieldProps {
}

export const CheckboxFieldList = createComponent<CheckboxFieldListProps>('CheckboxFieldList', {}, function CheckboxFieldList ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const value = (value) => {
    return form.data[key].includes(value)
      ? value
      : false;
  };

  const change = (value, trueValue) => {
    const list = form.data[key];
    if (value) {
      list.push(trueValue);
    } else {
      const index = list.indexOf(trueValue);
      list.splice(index, 1);
    }
    form.validateField(key, list);
  };

  const disabled = (trueValue) => {
    const list = form.data[key];
    const max = schema.maxAllowed || Number.MAX_SAFE_INTEGER;
    return props.disabled || (!list.includes(trueValue) && list.length >= max);
  };

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
        {
          For(schema.acceptedValues, (item) => (
            <Control key={`${key}_${item.value}`}>
              <Checkbox
                value={value(item.value)}
                trueValue={item.value}
                falseValue={false}
                error={!!form.errors[key]}
                readOnly={props.readOnly}
                disabled={disabled(item.value)}
                onChange={(value) => change(value, item.value)}
              >
                {item.label}
              </Checkbox>
            </Control>
          ))
        }
        <Control>
          {
            If(form.errors[key], () => (
              <FieldHelp error>{form.errors[key]}</FieldHelp>
            ))
            .ElseIf(slots.help, () => (
              <FieldHelp>{slots.help}</FieldHelp>
            ))
            .EndIf()
          }
        </Control>
    </Field>
  )
});
