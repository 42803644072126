import { createStore, CacheStrategy } from '@/common/util/store';

export interface ThemeState {
  theme: 'default' | 'classic'
}

// Mutations

function SET_THEME (state, payload) {
  return {
    theme: payload
  };
}

// Actions

export function setTheme (payload, mutate) {
  mutate(SET_THEME, payload);
}

// Export

export const {
  Provider: ThemeProvider,
  useStore: useThemeStore
} = createStore<ThemeState>({
  defaultState: {
    theme: 'default'
  },
  cacheKey: 'theme',
  cacheStrategy: CacheStrategy.LOCAL
});
