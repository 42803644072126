import { TransitionAttributes } from '@/common/components/Transition';

export function instantOut (): TransitionAttributes {
  return {
    opacity: 0,
    easing: 'linear',
    duration: 0
  }
}

export function instantIn (): TransitionAttributes {
  return {
    opacity: 1,
    easing: 'linear',
    duration: 0
  }
}
