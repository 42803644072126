// Third-party
import { useState } from 'react';
import { useRouter } from 'next/router';
import { faLock } from '@fortawesome/free-solid-svg-icons';

// First-party
import { 
  Slot, 
  If, 
  createComponent,
  IntrinsicProps
} from '@/common/util/templateHelpers';
import authApi from '@/apis/drupal/auth';
import { useForm } from '@/common/hooks/form';
import { useNotifications } from '@/common/hooks/notifications';

// Components
import Block from '@/common/components/Block';
import { IconFA } from '@/common/components/Icon';
import Button from '@/common/components/controls/Button';
import { 
  InputField,
  InputType 
} from '@/common/components/controls/Input';
import { 
  RecaptchaField, 
  RecaptchaTheme
} from '@/common/components/controls/Recaptcha';
import Form, { FieldType } from '@/common/components/forms/Form';

interface ResetPasswordFormProps extends IntrinsicProps {
  captcha?: boolean
  captchaTheme?: RecaptchaTheme,
  buttonText?: string,
  token: string,
  username: string
  onSuccess?: () => void
  onError?: (errors: any) => void
  onSubmit?: () => void
}

interface ResetPasswordFormData {
  password: string
}

export default createComponent<ResetPasswordFormProps>('ResetPasswordForm', {}, function ResetPasswordForm ({ className }, props) {
  const schema = {
    password: {
      value: '',
      schema: {
        type: FieldType.PASSWORD,
        required: true,
        minLength: 8,
        criteria: {
          upperAndLower: true,
          number: true,
          specialChars: false,
        }
      }
    },
    passwordAgain: {
      schema: {
        type: FieldType.PASSWORD_VERIFY,
        checkAgainst: 'password',
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const { notify } = useNotifications();
  const router = useRouter();
  const form = useForm(schema);
  const [ loading, setLoading ] = useState(false);

  const submit = async () => {
    const { data, errors, hasErrors } = form.validate<ResetPasswordFormData>();
    if (hasErrors) return props.onError && props.onError(errors);

    setLoading(true);

    const resetPasswordResult = await authApi.resetPassword(props.token, props.username, data.password);

    if (props.onSubmit) props.onSubmit();

    if (resetPasswordResult.error) {
      notify(resetPasswordResult.error.response.data.message, 'error');
      setLoading(false);
      if (props.onError) props.onError(resetPasswordResult.error);
    } else {
      notify(resetPasswordResult.message, 'success');
      router.push('/account');
      if (props.onSuccess) props.onSuccess();
    }
  };

  return (
    <>
      <Form className={className} onSubmit={submit}>
        <Block>
          <InputField name='password' type={InputType.PASSWORD} form={form} placeholder='Password'>
            <Slot name='icon'><IconFA icon={faLock} /></Slot>
          </InputField>
          <InputField name='passwordAgain' type={InputType.PASSWORD} form={form} placeholder='Password (verify)'>
            <Slot name='icon'><IconFA icon={faLock} /></Slot>
          </InputField>
        </Block>
        <Block>
          <p>
            Passwords must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number, and must be at least 8 characters long.
          </p>
        </Block>
        {
          If(props.captcha, () => (
            <Block>
              <RecaptchaField theme={props.captchaTheme || RecaptchaTheme.LIGHT} name='captchaResult' form={form} siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}>&nbsp;</RecaptchaField>
            </Block>
          )).EndIf()
        }
        <Block>
          <Button 
            primary 
            submit 
            disabled={form.hasErrors} 
            loading={loading}
          >
            {props.buttonText || 'Reset Password'}
          </Button>
        </Block>
      </Form>
    </>
  );
});
