import { useReducer, useState } from 'react';
import { FieldType, validateField, validateForm } from '@/common/util/validateForm';

function formReducer (state, { newState, key, value }: { newState?: {[key: string]: any}, key?: string, value?: any }) {
  if (newState) {
    return newState;
  }

  return {
    ...state,
    [key]: value
  };
}

function stateFromSchema (schema) {
  return Object.keys(schema).reduce((state, key) => {
    state[key] = typeof schema[key].value === 'undefined'
      ? ''
      : schema[key].value;
    return state;
  }, {})
}

function errorsFromSchema (schema) {
  return Object.keys(schema).reduce((state, key) => {
    state[key] = false;
    return state;
  }, {})
}

function checkForErrors (errors) {
  const keys = Object.keys(errors);
  
  let key
  while (key = keys.pop()) {
    if (errors[key]) return true
  }

  return false
}

export function useForm (schema) {
  const [ data, dispatchForm ] = useReducer(formReducer, stateFromSchema(schema));
  const [ errors, dispatchError ] = useReducer(formReducer, errorsFromSchema(schema));
  const [ hasErrors, setHasErrors ] = useState(false);

  const resetForm = () => {
    setHasErrors(false);
    dispatchForm({ newState: stateFromSchema(schema) });
  };

  const setErrors = (errors) => {
    setHasErrors(checkForErrors(errors));
    dispatchError({ newState: errors });
  };
  
  const resetErrors = (softReset?: boolean) => {
    setHasErrors(false);
    if (!softReset) dispatchError({ newState: errorsFromSchema(schema) });
  };

  const setField = (key, value) => {
    dispatchForm({ key, value });
  };

  const setFormData = (data) => {
    setHasErrors(false);
    Object.keys(data).forEach((key) => {
      setField(key, data[key]);
    });
  };

  const _validateField = (key, rawValue) => {
    let result;
    if (schema[key].schema.type === FieldType.PASSWORD_VERIFY) {
      const otherKey = schema[key].schema.checkAgainst;
      result = validateField(rawValue, data[otherKey], schema[key].schema);
    } else {
      result = validateField(rawValue, schema[key].schema);
    }

    const { error, value } = result;
    setHasErrors(!!error);

    dispatchForm({ key, value });
    dispatchError({ key, value: error });
  };

  const validate = <T, >() => {
    const { errors, values } = validateForm(data, schema);
    const hasErrors = checkForErrors(errors);
    setHasErrors(hasErrors);

    dispatchForm({ newState: values });
    dispatchError({ newState: errors });

    return { errors, data: values as T, hasErrors };
  };

  return {
    data,
    setData: setFormData,
    errors,
    schema,
    hasErrors,
    setField,
    resetForm,
    setErrors,
    resetErrors,
    validateField: _validateField,
    validate
  }
}
