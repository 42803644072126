interface ComScoreProps {
  clientId: number
}

export default function ComScore (props: ComScoreProps) {
  const script = `var _comscore = _comscore || [];
_comscore.push({ c1: "2", c2: "${props.clientId}" });
(function() {
  var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
  s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
  el.parentNode.insertBefore(s, el);
})();`;

  return (
    <>
      <script type='text/javascript' dangerouslySetInnerHTML={{ __html: script }} />
      <noscript>
        <img src={`https://sb.scorecardresearch.com/p?c1=2&c2=${props.clientId}&cv=2.0&cj=1`} />
      </noscript>
    </>
  );
}
