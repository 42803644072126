import { GameBasic, NumericId } from '@/util/formatters';
import { createStore, CacheStrategy } from '@/common/util/store';

export interface RecentGamesState {
  games: GameBasic[]
  visible: boolean
}

// Mutations

function ADD_GAME (state: RecentGamesState, payload) {
  let games = state.games;
  games.unshift(payload);

  const ids: NumericId[] = [];
  games = games.filter((game) => {
    const result = !ids.includes(game.nid);
    ids.push(game.nid);
    return result;
  });

  return {
    ...state,
    games: games.slice(0, 12) // max number of games in a row
  };
}

function REMOVE_GAME (state, payload) {
  const games = state.games.filter(game => game.nid !== payload.nid);

  return {
    ...state,
    games
  };
}

function REMOVE_ALL_GAMES (state) {
  return {
    ...state,
    games: []
  };
}

function SET_VISIBLE (state, payload) {
  return {
    ...state,
    visible: payload
  };
}

// Actions

export function addGame (payload: GameBasic, mutate) {
  mutate(ADD_GAME, payload);
}

export function removeGame (payload: GameBasic, mutate) {
  mutate(REMOVE_GAME, payload);
}

export function removeAllGames (payload, mutate) {
  mutate(REMOVE_ALL_GAMES);
}

export function setVisible (payload: boolean, mutate) {
  mutate(SET_VISIBLE, payload);
}

// Export

export const {
  Provider: RecentGamesProvider,
  useStore: useRecentGamesStore
} = createStore<RecentGamesState>({
  defaultState: {
    games: [],
    visible: true
  },
  cacheKey: 'recentGames',
  cacheStrategy: CacheStrategy.LOCAL
});
