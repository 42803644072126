import { createComponent, IntrinsicProps, If, For } from '@/common/util/templateHelpers';
import { useEffect, useState } from 'react';
import Button from './controls/Button';
import Flex from './Flex';

interface PaginatorProps extends IntrinsicProps {
  currentPage: number
  numPages: number
  onPaginate: (currentPage: number, nextPage: number) => void
  numButtons?: number
}

export default createComponent<PaginatorProps>('Paginator', 
{}, 
function Paginator ({ className }, props) {
  const checkHasPrevious = () => {
    return props.currentPage > 0;
  };

  const checkHasNext = () => {
    return props.currentPage < (props.numPages - 1);
  };

  const makePages = () => {
    let pages = [];
    for (let i = 0; i < props.numPages; i++) {
      pages.push(i);
    }

    const numButtons = props.numButtons || 5;
    const radius = Math.floor(numButtons / 2);

    let start = props.currentPage - radius;
    let end = props.currentPage + radius;

    while (start < 0) {
      start++;
      end++;
    }
    while (end - start < numButtons) {
      end++;
    }
    while (end > props.numPages) {
      start--;
      end--;
    }
    while (end - start < numButtons) {
      start--;
    }

    return pages.slice(start, end);
  };

  const [ pages, setPages ] = useState([]);
  const [ hasPrevious, setHasPrevious ] = useState(false);
  const [ hasNext, setHasNext ] = useState(false);

  useEffect(() => {
    setPages(makePages());
    setHasPrevious(checkHasPrevious());
    setHasNext(checkHasNext());
  }, [props.currentPage]);

  const paginate = (page: number) => {
    props.onPaginate(props.currentPage, page);
  };

  return (
    <Flex gap0 container className={className}>
      {
        If(hasPrevious, () => (
          <>
            <Flex><Button onClick={() => paginate(props.currentPage - 1)}>&larr;</Button></Flex>
            {/* <Flex className='Paginator__Ellipsis'><span>&hellip;</span></Flex> */}
          </>
        ))
        .EndIf()
      }
      {
        For(pages, (page, i) => (
          <Flex key={page}>
            {
              If(page === props.currentPage, () => (
                <Button primary>{page + 1}</Button>
              ))
              .Else(() => (
                <Button onClick={() => paginate(page)}>{page + 1}</Button>
              ))
              .EndIf()
            }
          </Flex>
        ))
      }
      {
        If(hasNext, () => (
          <>
            {/* <Flex className='Paginator__Ellipsis'><span>&hellip;</span></Flex> */}
            <Flex><Button onClick={() => paginate(props.currentPage + 1)}>&rarr;</Button></Flex>
          </>
        ))
        .EndIf()
      }
    </Flex>
  );
});
