import { TransitionAttributes } from '@/common/components/Transition';

interface SlideOptions {
  duration?: number
}

export function slideUpOut (opts: SlideOptions = {}): TransitionAttributes {
  return {
    opacity: {
      value: 0,
      easing: 'linear'
    },
    translateY: {
      value: '-50%',
      easing: 'easeOutCubic'
    },
    duration: opts.duration || 350
  }
}

export function slideUpIn (opts: SlideOptions = {}): TransitionAttributes {
  return {
    opacity: {
      value: 1,
      easing: 'linear'
    },
    translateY: {
      value: '0%',
      easing: 'easeOutCubic'
    },
    duration: opts.duration || 350
  }
}

export function slideUpEnterFrom (): TransitionAttributes {
  return {
    opacity: 0,
    translateY: '50%'
  }
}
