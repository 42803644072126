import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface TileProps extends IntrinsicProps {
  container?: boolean
  child?: boolean
  parent?: boolean
  vertical?: boolean
  shrink?: boolean
  gap1?: boolean
  gap2?: boolean
  gap3?: boolean
  gap4?: boolean
  gap5?: boolean
  gap6?: boolean
  size1?: boolean
  size2?: boolean
  size3?: boolean
  size4?: boolean
  size5?: boolean
  size6?: boolean
  size7?: boolean
  size8?: boolean
  size9?: boolean
  size10?: boolean
  size11?: boolean
  size12?: boolean
}

const tileStates = [
  'container',
  'child',
  'parent',
  'vertical',
  'shrink',
  'gap1',
  'gap2',
  'gap3',
  'gap4',
  'gap5',
  'gap6',
  'size1',
  'size2',
  'size3',
  'size4',
  'size5',
  'size6',
  'size7',
  'size8',
  'size9',
  'size10',
  'size11',
  'size12'
];

export default createComponent<TileProps>('Tile', 
{ classStates: tileStates }, 
function Tile ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
