import { createNetworkApi, Response } from '@/common/util/networkRequest';
import ensureMinimum from '@/util/ensureMinimum';
import { formatGameBasic, GameBasic } from '@/util/formatters';

import apiInstance from './instance';

export const HomePageGamesLimit = 24;

interface HomePageApi {
  getMostAddictingGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getNewGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getTrendingGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getClassicGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getExclusiveGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  get10YearsOrOlderGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getRecentTrafficGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>

  getNumPlaysGames: (limit?: number, offset?: number) => Promise<Response<GameBasic[]>>
}

function transform (result, limit = HomePageGamesLimit) {
  return ensureMinimum<GameBasic>(result?.map((data) => formatGameBasic(data)), limit);
}

export default createNetworkApi<HomePageApi> (apiInstance, {
  getMostAddictingGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/most-addicting-games?items_per_page=${limit}&offset=${offset}`,
    transform: (result) => {
      if (Array.isArray(result)) return [];

      const games = [];
      const firstRow = result.mostaddictinggames_first_row;
      const secondRow = result.mostaddictinggames_second_row;

      const length = Math.min(firstRow.length, secondRow.length);
      for (let i = 0; i < length; i++) {
        games.push(formatGameBasic(firstRow[ i ]), formatGameBasic(secondRow[ i ]));
      }

      return games;
    }
  }),

  getNewGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/new-games?items_per_page=${limit}&offset=${offset}`,
    transform: (result: Record<string, Array<Object>>) => {
      const games = Object.values(result).reduce((accum, current) => [ ...accum, ...current ], []);

      return transform(games);
    }
  }),

  getTrendingGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/trending-games?items_per_page=${limit}&offset=${offset}`,
    transform: (result) => transform(result, limit)
  }),

  getClassicGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/classic-games?items_per_page=${limit}&offset=${offset}`,
    transform: (result) => transform(result, limit)
  }),

  getExclusiveGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/exclusive-games?items_per_page=${limit}&offset=${offset}`,
    transform
  }),

  get10YearsOrOlderGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/games-more-than-10-years-ago?items_per_page=${limit}&offset=${offset}`,
    transform
  }),

  getRecentTrafficGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/games-sortby-recent-traffic?items_per_page=${limit}&offset=${offset}`,
    transform
  }),

  getNumPlaysGames: (limit = HomePageGamesLimit, offset = 0) => ({
    method: 'get',
    url: `/rest/block/games-sortby-plays?items_per_page=${limit}&offset=${offset}`,
    transform
  }),
});
