import { createContext, useContext, useEffect, useReducer } from 'react';

import storage, { StorageError } from '@/common/util/storage';

export enum CacheStrategy {
  LOCAL = 'local',
  COOKIE = 'cookie',
  SESSION = 'session'
}

interface CreateStoreOptions {
  cacheKey?: string
  cacheOptions?: {},
  cacheStrategy?: CacheStrategy,
  defaultState?: any,
  excludedKeys?: string[]
}

interface DispatchHandler {
  mutation?: any
  payload?: any
  setState?: any
}

type SetState = <T>(
  newState: T
) => void;

export function createStore <T>(options: CreateStoreOptions = {}) {
  const {
    cacheKey,
    cacheOptions,
    cacheStrategy,
    defaultState,
    excludedKeys
  } = options;

  const store = createContext<{ state: T, setState: any, dispatch: any }>({
    state: defaultState || null,
    setState: null,
    dispatch: null
  });

  return {
    context: store,

    useStore (): [
      state: T, 
      dispatch: (
        action: (payload: any, mutate: (
          mutation: (state: T, payload?: any) => T, 
          payload?: any
        ) => void, state: T) => void, 
        options?: any
      ) => void, 
      setState: (
        newState: T
      ) => void
    ] {
      const { state, setState, dispatch } = useContext(store);
      return [ state, dispatch, setState ];
    },

    Provider (props) {
      // Every time the store is updated, cache the state locally
      let storeLocally
      if (process.browser && cacheStrategy) {
        let serializeDebounce = null;

        storeLocally = (state) => {
          const excludedState = { ...state };

          excludedKeys?.forEach(key => {
            delete excludedState[key];
          });

          clearTimeout(serializeDebounce);
          serializeDebounce = setTimeout(() => {
            switch (cacheStrategy) {
              case CacheStrategy.LOCAL:
                storage.setLocal(cacheKey, excludedState);
                break;
              case CacheStrategy.SESSION:
                storage.setSession(cacheKey, excludedState);
                break;
              case CacheStrategy.COOKIE:
                storage.setCookie(cacheKey, excludedState, cacheOptions);
                break;
            }
          }, process.env.NEXT_PUBLIC_LOCAL_STORE_TIMEOUT || 300);
        }
      }

      // Reducer
      const [ state, dispatchState ] = useReducer((state, { mutation, payload, setState }: DispatchHandler) => {
        let newState
        if (setState) {
          newState = setState
        } else {
          newState = {
            ...state,
            ...mutation(state, payload)
          }
        }

        if (storeLocally) {
          storeLocally(newState)
        }

        return newState
      }, props.initialState || defaultState);
     
      // Dispatchers
      const mutate = (
        mutation: (state: T, payload?: any) => T, 
        payload?: any
      ) => {
        dispatchState({
          mutation,
          payload
        });
      };
      const dispatch = async (
        action: (payload: any, mutate: (
          mutation: (state: T, payload?: any) => T, 
          payload?: any
        ) => void, state: T) => void, 
        options?: any
      ) => {
        return await action(options, mutate, state);
      };

      // Check for cached state locally and populate store if it exists
      if (process.browser && cacheStrategy) {
        useEffect(() => {
          let state: T | StorageError
          switch (cacheStrategy) {
            case CacheStrategy.LOCAL:
              state = storage.getLocal(cacheKey);
              break;
            case CacheStrategy.SESSION:
              state = storage.getSession(cacheKey);
              break;
            case CacheStrategy.COOKIE:
              state = storage.getCookie(cacheKey);
              break;
          }

          if (state && !(state as StorageError).error) {
            dispatchState({
              setState: state
            });
          }
        }, []);
      }

      // It is with great hesitation I make this available.
      // In general you should _never_ use this as it will cause
      // the entire component tree to rerender, which is bad.
      // Please don't use this.
      const setState = function (newState: T) {
        dispatchState({
          setState: newState
        });
      };

      return (
        <store.Provider value={{ state, setState, dispatch }}>
          {props.children}
        </store.Provider>
      );
    }
  }
}
