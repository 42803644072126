import { Block, Content, ModalCard, RouterLink } from '@/common/components';
import { Button, Textarea } from '@/common/components/controls';
import { useModal } from '@/common/hooks/modal';
import { useNotifications } from '@/common/hooks/notifications';
import { createComponent, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import { Game } from '@/util/formatters';

interface EmbedGameModalProps extends IntrinsicProps {
  game: Game
}

export default createComponent<EmbedGameModalProps>('EmbedGameModal', 
  {}, 
  function EmbedGameModal ({}, props) {
    const { closeModal } = useModal();
    const { notify } = useNotifications();

    if (!props.game) return (<></>);

    const embedUrl = `<iframe width="100%" height="100%" src="${process.env.NEXT_PUBLIC_BASE_URL}/embed/html5-games/${props.game.nid}" scrolling="no" style="border:none"></iframe>`;

    const copyToClipboard = async () => {
      try {
        window.focus();
        await navigator.clipboard.writeText(embedUrl);
        closeModal();
        notify('Copied to clipboard!', 'success');
      } catch (err) {
        closeModal();
        notify('Couldn\'t copy to Clipboard, please try again later.', 'error');
      }
    };

    return (
      <ModalCard> 
        <Slot name='title'>Embed {props.game.title} on your site</Slot>

        <Block>
          <Content>
            <Textarea value={embedUrl} />
            <Button primary fullWidth onClick={copyToClipboard}>Copy</Button>
          </Content>
        </Block>
      
        <Block>
          <Content>
            <ul>
              <li>Mobile compatible</li>
              <li>Free to use on your website</li>
            </ul>
          </Content>
        </Block>

        <Block>
          <Content className='--textCenter'>
            <p>
              <RouterLink href='/free-games'>Get more free games for your website!</RouterLink>
            </p>
          </Content>
        </Block>
      </ModalCard>
    );
  });
