import { createComponent, For, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import Button from './Button';
import Dropdown, { DropdownItem, DropdownProps } from './Dropdown';

interface DropdownInputProps extends DropdownProps {
  up?: boolean
  right?: boolean
  currentValue: string
  possibleValues: DropdownInputItem[]
  onChange: (value: any) => void
}

export interface DropdownInputItem {
  value: string
  label: string
}

export default createComponent<DropdownInputProps>('DropdownInput', 
{}, 
function DropdownInput ({ className }, props) {
  const selected = props.possibleValues.find((item) => item.value === props.currentValue)

  return (
    <Dropdown {...props} className={className}>
      <Slot name='trigger'>
        <Button>
          <span style={{opacity: 0.65}}>{props.children}</span>&nbsp;{selected?.label}
        </Button>
      </Slot>
      <Slot name='content'>
        {
          For(props.possibleValues, (item, i) => (
            <DropdownItem 
              key={i} 
              onClick={() => props.onChange(item.value)}
              active={item.value === props.currentValue}
            >
              {item.label}
            </DropdownItem>
          ))
        }
      </Slot>
    </Dropdown>
  );
});
