import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import log from '@/common/util/dev/log';

interface CarouselErrorProps extends IntrinsicProps {
  error?: any
}

export default createComponent<CarouselErrorProps>('CarouselError', 
{},
function({ className }, props) {
  if (props.error) log(props.error);

  return (
    <div className={className}>
      <p className='--textCenter --padMd3'>
        There was an error loading this section :(
        <br />Please try again later!
      </p>
    </div>
  )
});