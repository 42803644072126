import { useEffect, useState } from 'react';
import { createTeleporter } from 'react-teleporter';

import { createStore } from '@/common/util/store';
import { TransitionAttributes } from '../components';

// Mutations

function SET_MODAL (state, modal) {
  return {
    ...state,
    modal
  };
}

function CLEAR_MODAL (state) {
  return {
    ...state,
    modal: null
  };
}

function SET_ENTER (state, transition) {
  return {
    ...state,
    enter: transition
  };
}

function SET_ENTER_FROM (state, transition) {
  return {
    ...state,
    enterFrom: transition
  };
}

function SET_LEAVE (state, transition) {
  return {
    ...state,
    leave: transition
  };
}

// Actions

function openModal (payload, mutate) {
  mutate(SET_MODAL, payload);
}

function closeModal (payload, mutate) {
  mutate(CLEAR_MODAL);
}

function setTransitions ({ enter, leave, enterFrom }, mutate) {
  if (enter) mutate(SET_ENTER, enter);
  if (leave) mutate(SET_LEAVE, leave);
  if (enterFrom) mutate(SET_ENTER_FROM, enterFrom);
}

// Store

interface ModalOptions {
  hideClose?: boolean
  onClose?: (modal: Modal) => void
  onEnter?: (modal: Modal) => Promise<any>
}

interface Modal {
  name: string
  options?: ModalOptions
  data?: any
}

interface ModalState {
  modal: Modal
  enter: TransitionAttributes | null
  enterFrom: TransitionAttributes | null
  leave: TransitionAttributes | null
}

const {
  Provider,
  useStore
} = createStore<ModalState>({
  defaultState: {
    modal: null,
    enter: null,
    enterFrom: null,
    leave: null
  }
});

let ModalTeleporter

// Main export

export function useModal () {
  const [ state, dispatch ] = useStore();

  if (ModalTeleporter === undefined && typeof window !== 'undefined') {
    ModalTeleporter = createTeleporter({ multiSources: true });
  }

  const [ currentModal, setCurrentModal ] = useState(state.modal);
  useEffect(() => {
    setCurrentModal(state.modal);
  }, [state.modal]);

  const _openModal = async (name: string, options: ModalOptions = {}, data?: any) => {
    // Close any currently open modals
    if (state.modal?.options?.onClose) state.modal.options.onClose(state.modal);
    
    // Optionally do something async while opening the modal
    const modal = { name, options, data };
    if (options.onEnter) modal.data = await options.onEnter(modal);

    // Open the modal
    dispatch(openModal, modal);
  };

  const _closeModal = () => {
    if (state.modal?.options?.onClose) state.modal.options.onClose(state.modal);
    dispatch(closeModal);
  };

  const _setTransitions = (enter: TransitionAttributes, leave: TransitionAttributes, enterFrom?: TransitionAttributes) => {
    dispatch(setTransitions, { enter, leave, enterFrom });
  }

  return {
    state,
    currentModal,
    openModal: _openModal,
    closeModal: _closeModal,
    setTransitions: _setTransitions,
    Provider,
    Teleporter: ModalTeleporter
  };
}
