import Cookies from 'js-cookie';
import log, { LogLevels } from './dev/log';

const identifier = process?.env?.IDENTIFIER || 'react-starter-app';

export interface StorageError {
  error: Error
}

function setStorage (storage, key: string, value) {
  try {
    storage.setItem(`${identifier}.storage.${key}`, JSON.stringify(value));
    return true;
  } catch (error) {
    log(`Error serializing value: ${error}`, LogLevels.WARN);

    return { error };
  }
}

function getStorage (storage, key: string) {
  try {
    const cached = storage.getItem(`${identifier}.storage.${key}`);
    return JSON.parse(cached);
  } catch (error) {
    log(`Error serializing value: ${error}`, LogLevels.WARN);

    return { error };
  }
}

export default {
  setLocal (key: string, value) {
    return setStorage(window.localStorage, key, value);
  },
  
  getLocal (key: string) {
    return getStorage(window.localStorage, key);
  },
  
  removeLocal (key: string) {
    return localStorage.removeItem(key);
  },
  
  clearLocal () {
    return localStorage.clear();
  },
  
  setSession (key: string, value) {
    return setStorage(window.sessionStorage, key, value)
  },
  
  getSession (key: string) {
    return getStorage(window.sessionStorage, key)
  },
  
  removeSession (key: string) {
    return sessionStorage.removeItem(key);
  },
  
  clearSession () {
    return sessionStorage.clear();
  },
  
  setCookie (key: string, value, options) {
    try {
      Cookies.set(`${identifier}.storage.${key}`, value, options);
      return true;
    } catch (error) {
      log(`Error serializing value: ${error}`, LogLevels.WARN);

      return { error };
    }
  },
  
  getCookie (key: string) {
    try {
      return Cookies.getJSON(`${identifier}.storage.${key}`);
    } catch (error) {
      log(`Error serializing value: ${error}`, LogLevels.WARN);

      return { error };
    }
  },
  
  removeCookie (key: string, options) {
    Cookies.remove(key, options);
  }
}
