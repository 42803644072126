import { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { createComponent, IntrinsicProps, toClassName } from '@/common/util/templateHelpers';
import { useClickOutside } from '@/common/hooks/clickOutside';

export interface DropdownProps extends IntrinsicProps {
  up?: boolean
  right?: boolean
}

const dropdownStates = [
  'up',
  'right'
];

const Dropdown = createComponent<DropdownProps>('Dropdown', 
{ classStates: dropdownStates }, 
function Dropdown ({ mergeClassNames, style, slots }) {
  const [ menuState, setMenuState ] = useState(false);
  const className = mergeClassNames(toClassName('Dropdown', { active: menuState }));

  // Close when click outside
  const dropdownRef = useRef(null);
  useClickOutside({
    ref: dropdownRef,
    excludeRefs: [
      { matches: 'a.DropdownItem' }
    ]
  }, () => {
    setMenuState(false);
  });

  // Close when clicking a DropdownItem with the 'a' tag
  const handleClick = (evt) => {
    const closest = evt.target.matches('a.DropdownItem')
      ? evt.target
      : evt.target.closest('a.DropdownItem');
    if (closest) setMenuState(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
 
  return (
    <div ref={dropdownRef} className={className} style={style}>
      <div className='Dropdown__Trigger' onClick={() => setMenuState(!menuState)}>
        {slots.trigger}
      </div>
      <div className='Dropdown__Menu' role='menu'>
        <div className='Dropdown__Content'>
          {slots.content}
        </div>
      </div>
    </div>
  );
});
export default Dropdown;

/* --- */

interface DropdownItemProps extends IntrinsicProps {
  active?: boolean
  tag?: string
}

const dropdownItemStates = [
  'active'
];

export const DropdownItem = createComponent<DropdownItemProps>('DropdownItem', 
{ classStates: dropdownItemStates }, 
function DropdownItem ({ className, style }, props) {
  const Tag = props.tag || 'a';
 
  return (
    // @ts-ignore
    <Tag className={className} style={style} onClick={props.onClick}>
      {props.children}
    </Tag>
  );
});

/* --- */

interface DropdownLinkProps extends IntrinsicProps {
  href: string
}

export const DropdownLink = createComponent<DropdownLinkProps>('DropdownItem', 
{ classStates: dropdownItemStates }, 
function DropdownItem ({ className, style }, props) {
  return (
    <Link href={props.href}>
      <a className={className} style={style}>{props.children}</a>
    </Link>
  );
});

/* --- */

export const DropdownDivider = createComponent('DropdownDivider', {}, function DropdownDivider ({ className, style }) {
  return (
    <div className={className} style={style} />
  );
});
