export default function truncateTextToWord(
  text:string, 
  desiredLength:null|number = null,
  ellipse: null|boolean = null
) {

  if(text.length < desiredLength || !desiredLength || desiredLength < 0) return text;

  let truncateLength = -1;

  for(let i = desiredLength; i > 0; i--) {
    if(text[i] === ' ') {
      truncateLength = i;
      break;
    }
  }

  let truncatedText;

  if(truncateLength === -1) {
    truncateLength = desiredLength;
  }
    
  truncatedText = text.substring(0, truncateLength);

  if(/[,:;]/.test(truncatedText.slice(-1))) {
    truncatedText = truncatedText.substring(0, truncatedText.length - 1);
  }

  if(!/[.?]/.test(truncatedText.slice(-1)) &&  ellipse) {
    truncatedText += '...';
  }

  return truncatedText;
}