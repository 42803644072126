import Link from 'next/link';

import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { MouseEventHandler, TouchEventHandler } from 'react';

interface ButtonProps extends IntrinsicProps {
  small?: boolean
  medium?: boolean
  large?: boolean
  fullWidth?: boolean
  hovered?: boolean
  focused?: boolean
  active?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  success?: boolean
  warning?: boolean
  error?: boolean
  light?: boolean
  dark?: boolean
  text?: boolean
  static?: boolean
  loading?: boolean
  rounded?: boolean
  disabled?: boolean
  submit?: boolean 
  ariaLabel?: string
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onMouseDown?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onMouseUp?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onTouchStart?: TouchEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onTouchEnd?: TouchEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

const buttonStates = [
  'small',
  'medium',
  'large',
  'fullWidth',
  'hovered',
  'focused',
  'active',
  'primary',
  'secondary',
  'tertiary',
  'success',
  'warning',
  'error',
  'light',
  'dark',
  'text',
  'static',
  'loading',
  'rounded',
  'disabled'
];

const Button = createComponent<ButtonProps>('Button', 
  { classStates: buttonStates }, 
  function Button ({ className, style }, props) {
    const type = props.submit
      ? 'submit'
      : 'button';

    return (
      <button 
        type={type} 
        className={className} 
        style={style} 
        onClick={props.onClick} 
        onMouseDown={props.onMouseDown} 
        onMouseUp={props.onMouseUp} 
        onTouchStart={props.onTouchStart} 
        onTouchEnd={props.onTouchEnd} 
        disabled={props.disabled}
        aria-label={props.ariaLabel}
      >
        <span>{props.children}</span>
      </button>
    );
  });
export default Button;

/* --- */

interface ButtonLinkProps extends ButtonProps {
  href: string
  target?: '_blank' | '_self'| '_parent' | '_top'
}

export const ButtonLink = createComponent<ButtonLinkProps>('Button', 
  { classStates: buttonStates }, 
  function ButtonLink ({ className, style }, props) {
    const type = props.submit
      ? 'submit'
      : 'button';


    // You can't disable an anchor tag, so we return a div look-alike 
    if(props.disabled) {
      return (
        <div className={className} style={style}>
          <span>{props.children}</span>
        </div>
      );
    }
  
    return (
      <Link href={props.href}>
        <a 
          type={type} 
          className={className} 
          style={style} 
          onClick={props.onClick} 
          onMouseDown={props.onMouseDown} 
          onMouseUp={props.onMouseUp} 
          onTouchStart={props.onTouchStart} 
          onTouchEnd={props.onTouchEnd} 
          target={props.target}
          aria-label={props.ariaLabel}
        >
          <span>{props.children}</span>
        </a>
      </Link>
    );
  });

/* --- */

interface ButtonsProps extends IntrinsicProps {
  small?: boolean
  medium?: boolean
  large?: boolean
  addons?: boolean
  centered?: boolean
  right?: boolean
}

const buttonsStates = [
  'small',
  'medium',
  'large',
  'addons',
  'centered',
  'right'
];

export const Buttons = createComponent<ButtonsProps>('Buttons', 
  { classStates: buttonsStates }, 
  function Buttons ({ className, style }, props) {
    return (
      <div className={className} style={style}>
        {props.children}
      </div>
    );
  });
