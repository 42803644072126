import { Modal, ModalCard } from '@/common/components';
import { LoginForm, RegisterForm, RequestPasswordForm } from '@/common/components/forms';
import { useInactiveUser } from '@/common/hooks/inactiveUser';
import { useModal } from '@/common/hooks/modal';
import { createComponent, If, Slot } from '@/common/util/templateHelpers';
import { useRouter } from 'next/router';
import AvatarPicker from '@/components/AvatarPicker';
import BeforeYouGoUpsell from '@/components/BeforeYouGoUpsell';
import EmbedGameModal from '@/components/modals/EmbedGameModal';
import ReviewGameModal from '@/components/modals/ReviewGameModal';
import ShareGameModal from '@/components/modals/ShareGameModal';
import { useEffect } from 'react';
import UpgradeGamePass from '../UpgradeGamePass';
import { UserAvatar } from '@/models/UserAvatar';

export default createComponent('AppModals', 
  {}, 
  function AppModals () {
    const router = useRouter();
    const { openModal, closeModal, currentModal } = useModal();

    useInactiveUser(() => {
      const notAllowedList = [
        '/[genre]/[slug]',
        '/blog',
        '/blog/[slug]',
        '/about/about-us',
        '/about/advertisers',
        '/about/contact-us',
        '/about/content-ratings',
        '/about/copyright',
        '/about/parents',
        '/about/privacy-policy',
        '/about/terms-use',
        '/about/upload',
        '/about/user-submission-agreement',
        '/subscribe/[plan]',
        '/game-pass',
        '/embed/html5-games/[nid]'
      ];
      if (notAllowedList.includes(router.pathname)) return;
      openModal('beforeYouGo');
    }, 30000, true);

    // If navigation occurs, dismiss any active modals
    useEffect(() => {
      router.events.on('routeChangeStart', closeModal);
      return () => router.events.off('routeChangeStart', closeModal);
    });

    return (
      <>
        {/* Login modal */}
        <Modal name='login'>
          <ModalCard>
            <Slot name='title'>Login</Slot>
            <LoginForm 
              onSuccess={closeModal}
              onRegisterClicked={() => (openModal('register'), true)}
              onRecoverPasswordClicked={() => (openModal('recoverPassword'), true)}
            />
          </ModalCard>
        </Modal>

        {/* Register modal */}
        <Modal name='register'>
          <ModalCard>
            <Slot name='title'>Register</Slot>
            <RegisterForm 
              onSuccess={closeModal} 
              privacyHref='/about/privacy-policy' 
              termsHref='/about/terms-use'
            />
          </ModalCard>
        </Modal>

        {/* Recover password */}
        <Modal name='recoverPassword'>
          <ModalCard>
            <Slot name='title'>Recover Password</Slot>
            <RequestPasswordForm onSuccess={closeModal} />
          </ModalCard>
        </Modal>

        {/* Recover password */}
        <Modal name='pickAvatar'>
          <ModalCard halfHeight>
            <Slot name='title'>Choose Your Avatar</Slot>
            <AvatarPicker onChange={(avatar: UserAvatar) => currentModal.data.onAvatarChanged(avatar)} />
          </ModalCard>
        </Modal>

        {/* Before You Go modal */}
        <Modal name='beforeYouGo'>
          <ModalCard autoWidth>
            <h2>Before you go... why not try these games?</h2>
            <br />
            <BeforeYouGoUpsell />
          </ModalCard>
        </Modal>

        {/* Game Page embed game modal */}
        <Modal name='embedGame'>
          <EmbedGameModal game={currentModal?.data?.game} />
        </Modal>

        {/* Game Page review game modal */}
        <Modal name='reviewGame'>
          <ReviewGameModal game={currentModal?.data?.game} />
        </Modal>

        {/* Game Page share game modal */}
        <Modal name='shareGame'>
          <ShareGameModal game={currentModal?.data?.game} />
        </Modal>

        {/* Update Game Pass modal */}
        <Modal name='gamePassUpdate'>
          <ModalCard autoWidth>
            <UpgradeGamePass plan={currentModal?.data} closeModal={(closeModal)}/>
          </ModalCard>
        </Modal>
      </>
    );
  });
