import { If, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import Flex from '@/common/components/Flex';

interface LoaderProps extends IntrinsicProps {
  large?: boolean
}

const loaderStates = [
  'large'
];

const Loader = createComponent<LoaderProps>('Loader', { classStates: loaderStates }, function Loader ({ className, style }, props) {
  return (
    <Flex center className={className} style={style}>
      {props.children}
    </Flex>
  );
});
export default Loader;

/* --- */

interface LoaderFillProps extends IntrinsicProps {
  large?: boolean
}

export const LoaderFill = createComponent<LoaderFillProps>('LoaderFill', 
{}, 
function LoaderFill ({ className, style }, props) {
  return (
    <Flex fit fill alignCenter justifyCenter className={className} style={style}>
      <Flex center>
        {
          If(props.children, () => (
            <>{props.children}</>
          ))
          .Else(() => (
            <Loader large />
          ))
          .EndIf()
        }
      </Flex>
    </Flex>
  );
});
