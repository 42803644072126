import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface TitleProps extends IntrinsicProps {
  size1?: boolean
  size2?: boolean
  size3?: boolean
  size4?: boolean
  size5?: boolean
  size6?: boolean
  size7?: boolean
  spaced?: boolean
  div?: boolean
  h1?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  h5?: boolean
  h6?: boolean
}

const titleStates = [
  'size1',
  'size2',
  'size3',
  'size4',
  'size5',
  'size6',
  'size7',
  'spaced'
];

export default createComponent<TitleProps>('Title', 
{ classStates: titleStates }, 
function Title ({ className, style }, props) {
  let Tag = 'h1';
  if (props.div) Tag = 'h1';
  if (props.h1) Tag = 'h1';
  if (props.h2) Tag = 'h2';
  if (props.h3) Tag = 'h3';
  if (props.h4) Tag = 'h4';
  if (props.h5) Tag = 'h5';
  if (props.h6) Tag = 'h6';

  return (
    // @ts-ignore
    <Tag className={className} style={style}>
      {props.children}
    </Tag>
  );
});
