import { Block, Flex, Title } from '@/common/components';
import { Button, Checkbox } from '@/common/components/controls';
import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import subscriptionApi from '@/apis/drupal/subscription';
import { useState } from 'react';
import { useNotifications } from '@/common/hooks/notifications';


interface UpgradeGamePassProps extends IntrinsicProps {
  plan: string
  closeModal: any
}

export default createComponent<UpgradeGamePassProps>('UpgradeGamePass', 
  {}, 
  function UpgradeGamePass ({ className }, props) {

    const { notify } = useNotifications();

    const [ confirmUpgrade, setConfirmUpgrade ] = useState(false);

    const changeSubscription = async () => {
      if(confirmUpgrade){
        const subscription = await subscriptionApi.update(props.plan);

        if(subscription.error){
          notify('A problem occured. Please try again or contact support.');
          return;
        }
        props.closeModal();

        notify(subscription);
      }
    };
    return (
      <>
        <Title size4 >Confirm Game Pass Update</Title>
        <Block>
          <Flex>
            <Checkbox 
              value={confirmUpgrade} 
              trueValue={true} 
              falseValue={false} 
              onChange={e => setConfirmUpgrade(!confirmUpgrade)}
            />
            <p>Please confirm you would like to update to { props.plan === 'annual' ? 'an annual' : 'a monthly'} plan by checking the box and hit confirm.</p>
          </Flex>
        </Block>

        <Block>
          <Flex>
            <p>
            Please note that your account plan change will be reflected on your next billing charge.
            </p>
          </Flex>
        </Block>
     
        <Flex  justifyEnd pullRight fit>
          <Button onClick={changeSubscription} primary className='--marSm2__r'>Confirm</Button>
          <Button onClick={props.closeModal}>Cancel</Button>
        </Flex>
      </>
    );
  });
