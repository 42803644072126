export enum LogLevels {
  INFO = 'info',
  DEBUG = 'debug',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error'
}

export default function log (msg, level: LogLevels = LogLevels.LOG) {
  if (process.env.NODE_ENV !== 'development') return;

  let style;

  switch(level) {
    case LogLevels.ERROR:
      style = 'color: #D33;';
      break;
    case LogLevels.WARN:
      style = 'color: #DD3;';
      break;
    case LogLevels.INFO:
      style = 'color: #18F';
      break;
    default:
      style = 'color: #777; font-style: italic';
  }

  if(typeof console[level] === 'function') {
    console[level](`%c [${process.env.PACKAGE_NAME}] ${msg}`,  style);
  } else {
    // eslint-disable-next-line
    console.log(`%c [${process.env.PACKAGE_NAME}] ${msg}`,  style);
  }
}
