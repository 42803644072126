import { If, For, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import Field, { FieldHelp, FieldLabel } from '@/common/components/controls/Field';
import Control from '@/common/components/controls/Control';

interface RadioProps extends IntrinsicProps {
  error?: boolean
  small?: boolean
  medium?: boolean
  large?: boolean
  name?: string
  readOnly?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  value: any
  onChange?: (value: any) => void
}

const radioStates = [
  'error',
  'small',
  'medium',
  'large'
];

const Radio = createComponent<RadioProps>('Radio', 
{ classStates: radioStates }, 
function Radio ({ className, style }, props) {
  const change = (evt) => {
    const value = evt.target.value === 'on'
      ? props.value
      : false;
    if (props.onChange) props.onChange(value);
  };

  return (
    <label 
      className={className} 
      style={style}
    >
      <input
        type='radio'
        name={props.name}
        readOnly={props.readOnly}
        disabled={props.disabled}
        defaultChecked={props.defaultChecked}
        onChange={change}
      />
      <span />
      <span>{props.children}</span>
    </label>
  );
});
export default Radio;

/* --- */

interface RadioFieldProps extends IntrinsicProps {
  name: string
  form: any
  readOnly?: boolean
  disabled?: boolean
}

export const RadioField = createComponent<RadioFieldProps>('RadioField', 
{}, 
function RadioField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const change = (value) => {
    form.validateField(key, value);
  };

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
        {
          For(schema.acceptedValues, (item) => (
            <Control key={`${key}_${item.value}`}>
              <Radio
                name={key}
                value={item.value}
                error={!!form.errors[key]}
                readOnly={props.readOnly}
                disabled={props.disabled}
                defaultChecked={form.data[key] === item.value}
                onChange={change}
              >
                {item.label}
              </Radio>
            </Control>
          ))
        }
        <Control>
          {
            If(form.errors[key], () => (
              <FieldHelp error>{form.errors[key]}</FieldHelp>
            ))
            .ElseIf(slots.help, () => (
              <FieldHelp>{slots.help}</FieldHelp>
            ))
            .EndIf()
          }
        </Control>
    </Field>
  )
});
