import { Slot, If, For, createComponent, toClassName, IntrinsicProps } from '@/common/util/templateHelpers';
import useInput from '@/common/hooks/input';
import Field, { FieldHelp, FieldLabel } from '@/common/components/controls/Field';
import Control from '@/common/components/controls/Control';

interface SelectProps extends IntrinsicProps {
  value: any
  readOnly?: boolean
  disabled?: boolean
  onFocus?: (value: any) => void
  onBlur?: (value: any) => void
  onChange?: (value: any) => void
}

const selectStates = [
  'rounded'
];

const Select = createComponent<SelectProps>('Select', 
{ classStates: selectStates }, 
function Select ({ mergeClassNames, style }, props) {
  const {
    states, 
    focus,
    blur, 
    change
  } = useInput(props);

  const className = mergeClassNames(toClassName('Select', states, props));

  return (
    <div className={className} style={style}>
      <select
        style={style}
        value={props.value}
        disabled={props.disabled}
        onFocus={focus}
        onBlur={blur}
        onChange={change}
      >
        {props.children}
      </select>
    </div>
  );
});
export default Select;

/* --- */

interface SelectFieldProps extends IntrinsicProps {
  name: string
  form: any
  value: any
  readOnly?: boolean
  disabled?: boolean
}

export const SelectField = createComponent<SelectFieldProps>('SelectField', 
{}, 
function SelectField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      { If(slots?.label, () => (<FieldLabel>{slots.label}</FieldLabel>)).EndIf() }
      <Control>
        <Select
          key={key}
          value={form.data[key]} 
          readOnly={props.readOnly}
          disabled={props.disabled}
          onFocus={() => form.resetErrors(true)}
          onChange={(value) => form.setField(key, value)}
          onBlur={(value) => form.validateField(key, value)}
        >
          {slots.defaultSlot}
        </Select>
        {
          If(form.errors[key], () => (
            <FieldHelp error>{form.errors[key]}</FieldHelp>
          ))
          .ElseIf(slots.help, () => (
            <FieldHelp>{slots.help}</FieldHelp>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});

/* --- */

interface SelectFieldListProps extends IntrinsicProps {
  name: string
  form: any
  emptyLabel: string
  value: any
}

export const SelectFieldList = createComponent<SelectFieldListProps>('SelectFieldList', 
{}, 
function SelectFieldList ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  return (
    <SelectField 
      name={key} 
      form={form} 
      className={className} 
      style={style}
      value={props.value}
    >
      <Slot name='label'>{slots.label}</Slot>
      <option value={''} disabled={true}>&mdash; {props.emptyLabel} &mdash;</option>
      {
        For(schema.acceptedValues, (item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))
      }
    </SelectField>
  )
});
