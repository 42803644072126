import { Block, Content, Flex, IconFA, ModalCard, Title } from '@/common/components';
import { Button, ButtonLink, Control, Field, Input, InputType, Textarea } from '@/common/components/controls';
import { useModal } from '@/common/hooks/modal';
import { useNotifications } from '@/common/hooks/notifications';
import { createComponent, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import { Game } from '@/util/formatters';
import { faFacebook, faPinterest, faReddit, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useRouter } from 'next/router';

interface ShareGameModalProps extends IntrinsicProps {
  game: Game
}

export default createComponent<ShareGameModalProps>('ShareGameModal', 
  {}, 
  function ShareGameModal ({}, props) {

    const router = useRouter();
    const { closeModal } = useModal(); 
    const { notify } = useNotifications();

    const gameUrl = `https://${window.location.hostname}${router.asPath}`;
    const pathName = encodeURIComponent(gameUrl);

    const copyToClipboard = async () => {
      try {
        window.focus();
        await navigator.clipboard.writeText(gameUrl);
        closeModal();
        notify('Copied to clipboard!', 'success');
      } catch (err) {
        closeModal();
        notify('Couldn\'t copy to Clipboard, please try again later.', 'error');
      }
    };
  
    const urls = {
      reddit: `https://reddit.com/submit?url=${pathName}`,
      facebook: `https://facebook.com/sharer.php?u=${pathName}`,
      twitter: `https://twitter.com/intent/tweet?url=${pathName}`,
      pinterest: `https://pinterest.com/pin/create/button/?url=${pathName}`
    };

    if (!props.game) return (<></>);

    return (
      <ModalCard>
        <Slot name='title'>Share</Slot>
      
        <Block>
          <Flex wrap gap0 container alignCenter justifyCenter>
            <Flex>
              <a 
                href={urls.facebook} 
                target='_blank' 
                rel='noreferrer noopener'
                onClick={closeModal}
              >
                <img src='/images/facebook.svg' alt='Facebook' />
              </a>
            </Flex>
            <Flex>
              <a 
                href={urls.twitter} 
                target='_blank' 
                rel='noreferrer noopener'
                onClick={closeModal}
              >
                <img src='/images/twitter.svg' alt='Twitter' />
              </a>
            </Flex>
            <Flex>
              <a 
                href={urls.reddit} 
                target='_blank' 
                rel='noreferrer noopener'
                onClick={closeModal}
              >
                <img src='/images/reddit.svg' alt='Reddit' />
              </a>
            </Flex> 
            <Flex>
              <a 
                href={urls.pinterest} 
                target='_blank' 
                rel='noreferrer noopener'
                onClick={closeModal}
              >
                <img src='/images/pinterest.svg' alt='Pinterest' />
              </a>
            </Flex>
          </Flex>
        </Block>

        <Block>
          <Field addons>
            <Control style={{ width: '100%' }}>
              <Input type={InputType.ANY} readOnly value={gameUrl} />
            </Control>
            <Control>
              <Button primary fullWidth onClick={copyToClipboard}>Copy</Button>
            </Control>
          </Field>
        </Block>
      </ModalCard>
    );
  });

