import truncateTextToWord from '@/common/util/truncateText';
import { parse } from '@/util/stripHtml';

export type Id = string
export type NumericId = number

const convertTruthyValue = value => {
  if(typeof value === 'string') {
    return value === '1' || value === 'true';
  } else if(typeof value === 'number') {
    return value !== 0;
  }

  return !!value;
};

// #region Game

export interface GameBasic {
  nid: NumericId // drupal_internal__nid
  title: string // title
  thumbnailUrl: string 
  videoThumbnailUrl?: string
  path: string // path
  published: string // published_at
  supportsMobile: boolean // field_is_mobile_playable || field_is_mobile
  rating: number // field_ratingb
  totalCount: number // field_hits
  flags?: {
    classic?: boolean
    featured?: boolean
    trending?: boolean
  }
}

export interface Game extends GameBasic {
  uid: Id // uid
  description: string // field_body
  descriptionSimple: string
  shortDescriptionSimple: string
  metaDescription: string
  totalVotes: number // field_total_votes
  hits: number // field_hits
  status: boolean // status
  embedUrl: string 
  canEmbed?: boolean // field_embed
  supportsMobile: boolean // field_is_mobile_playable || field_is_mobile
  supportedMobileOrientation: {
    portrait: boolean
    landscape: boolean
  }
  gamePassExclusive: boolean // field_game_pass_only_
  instructions: string // field_instructions
  developer: any // field_game_developer
  genre: Genre // field_genre
  tags?: Tag[], // field_tags
  videoWalkthroughs: any // field_video_walkthroughs
  totalCount: number,
  created: string,
  changed: string,
  alternateGameSuggestion: false | 'random' | string
  featuredMultiplayer?: boolean,
  mmoSlideshowImage?: string | false,
  maturityRating: string | false,
  isFlash: boolean
  externalGameLink?: string
  width?: number
  height?: number
}

/**
 * The function conditionally set taxonomies due to time complexity concerns
 * Setting includeTaxonomy to true should be used sparingly.
 */

const SHORT_DESCRIPTION_LENGTH = 380;

export function formatGameBasic (data, included?: any): GameBasic {
  if (data.attributes) {
    const rating = Number(data.attributes?.field_ratingb) || 0;

    const thumbnailField = included && included.find((field) => field.id === data.relationships?.field_thumbnail?.data?.id);
    const thumbnailUrl = thumbnailField?.attributes?.uri?.url
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
      : '/images/180x117.png';
  
    const videoThumbnailField = included && included.find((field) => field.id === data.relationships?.field_video_thumbnail?.data?.id);
    const videoThumbnailUrl = videoThumbnailField?.attributes?.uri?.url
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + videoThumbnailField.attributes.uri.url
      : null;

    const field_is_mobile = convertTruthyValue(data.attributes?.field_is_mobile);
    const field_is_mobile_playable = convertTruthyValue(data.attributes?.field_is_mobile_playable);

    const flags: GameBasic[ 'flags' ] = {};
    if (data.attributes?.is_classic) flags.classic = true;
    if (data.attributes?.is_featured) flags.featured = true;

    return {
      nid: parseInt(data.attributes.drupal_internal__nid),
      title: data.attributes.title || 'No Title',
      thumbnailUrl,
      videoThumbnailUrl,
      path: data.attributes?.path?.alias || '',
      published: data.attributes.published_at || null,
      supportsMobile: field_is_mobile_playable || field_is_mobile || false,
      rating,
      totalCount: data.attributes.field_hits || data.attributes.total_views || 0,
      flags,
    };
  }

  const rating = Number(data.field_ratingb) || 0;
  const field_is_mobile = convertTruthyValue(data.field_is_mobile);
  const field_is_mobile_playable = convertTruthyValue(data.field_is_mobile_playable);

  const flags: GameBasic[ 'flags' ] = {};
  if (data.is_classic) flags.classic = true;
  if (data.is_featured) flags.featured = true;
  if (data.is_trending) flags.trending = true;
  
  return {
    nid: parseInt(data.nid || data.drupal_internal__nid),
    title: String(data?.title || 'No Title').trim(),
    thumbnailUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + (data.field_thumbnail || ''),
    videoThumbnailUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + (data.field_video_thumbnail || ''),
    path: data.path || data.view_node || '',
    published: data.published_at ?? data.created ?? null,
    supportsMobile: field_is_mobile_playable || field_is_mobile || false,
    rating,
    totalCount: data.field_hits || data.total_views || 0,
    flags,
  };
}

export function formatGame (game, included, meta): Game {
  let genre: Genre | null = null;
  let tags: Tag[] | null = null;
  
  for (const [ key, value ] of Object.entries(game.attributes)) {
    if (value === 'NULL') game.attributes[ key ] = null;
  }

  const genreObj = included && included.find((field) => field.id === game.relationships?.field_genre?.data?.id);

  if (genreObj) {
    const genreThumbnail = included && included.find((field) => field.id === genreObj.relationships?.field_genre_image?.data?.id);
    
    genre = {
      id: genreObj.attributes.name.toLowerCase(),
      name: String(genreObj?.attributes?.name || '').trim(),
      path: genreObj.attributes.path.alias.replace('/genre/', '').toLowerCase(),
      thumbnailUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + genreThumbnail.attributes.uri.url,
      totalCount: -1
    };
  }
  
  const tagIds = game.relationships?.field_tags.data && game.relationships?.field_tags && game.relationships?.field_tags.data
    .map((relationships_tag) => relationships_tag.id);

  if (tagIds) {
    tags = included && included
      .filter((item) => tagIds && tagIds.includes(item.id))
      .map((tag) => {
        const tagThumb = included && included.filter((filePath) => {
          if (filePath.id === tag.relationships.field_tag_image.data.id){
            return filePath;
          }
        });

        const nextTag: Tag = {
          id: tag.id,
          name: String(tag?.attributes?.name || 'Unknown Tag').trim(),
          path: tag.attributes.path.alias.toLowerCase(),
          thumbnailUrl: process.env.NEXT_PUBLIC_IMAGE_DOMAIN + tagThumb[ 0 ].attributes.uri.url,
          totalCount: -1
        };
        return nextTag;
      });
  }

  const thumbnailField = included && included.find((field) => field.id === game.relationships?.field_thumbnail?.data?.id);
  const thumbnailUrl = thumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
    : '/images/180x117.png';

  const videoThumbnailField = included && included.find((field) => field.id === game.relationships?.field_video_thumbnail?.data?.id);
  const videoThumbnailUrl = videoThumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + videoThumbnailField.attributes.uri.url
    : null;

  const description = game.attributes?.field_body?.processed || 'No description available.';
  const descriptionSimple = (description && parse(description).text) || '';
  const shortDescriptionSimple = truncateTextToWord(descriptionSimple, SHORT_DESCRIPTION_LENGTH, true);
  const metaDescription = truncateTextToWord(`${game.attributes.title}: ${descriptionSimple}`, SHORT_DESCRIPTION_LENGTH);
  const rating = Number(game.attributes?.field_ratingb) || 0;
  const totalVotes = Number(game.attributes?.field_total_votes) || 0;
  const hits = Number(game.attributes?.field_hits) || 0;
  const path = game.attributes?.path?.alias || '/page-not-found';
  const status = game.attributes?.status || true;
  const supportsMobile = game.attributes?.field_is_mobile || game.attributes?.field_is_mobile_playable || false;
  
  const supportedMobileOrientation = {
    portrait: false,
    landscape: false
  };

  if (supportsMobile) {
    // If the game supports mobile and field_preferred_orientation isn't set, 
    // allow either orientation
    supportedMobileOrientation.portrait = game.attributes?.field_preferred_orientation === 'portrait' || !game.attributes?.field_preferred_orientation;
    supportedMobileOrientation.landscape = game.attributes?.field_preferred_orientation === 'landscape' || !game.attributes?.field_preferred_orientation;
  }

  const gamePassExclusive = game.attributes?.field_game_pass_only_;
  const instructions = game.attributes?.field_instructions;
  const developer = game.attributes?.field_game_developer;
  const videoWalkthroughs = game.attributes?.field_video_walkthroughs;
  const alternateGameSuggestion = game.relationships?.field_alternate_game_suggestion?.data?.id 
    ? game.relationships.field_alternate_game_suggestion.data.id
    : game.attributes?.field_suggest_a_random_game
      ? 'random'
      : false;

  const featuredMultiplayer = game.attributes?.field_featured_multiplayer || false;

  const mmoSlideshowField = included && included.find((field) => field.id === game.relationships?.field_mmo_slideshow_image?.data?.id);
  const mmoSlideshowImage = mmoSlideshowField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + mmoSlideshowField.attributes.uri.url
    : '/images/180x117.png';

  const maturityRatingFieldId = game.relationships?.field_maturity_rating?.data?.id;
  const maturityRatingField = included && included.find((field) => field.id === maturityRatingFieldId);
  const maturityRating = maturityRatingField
    ? maturityRatingField.attributes?.name || false
    : false;

  const isFlash = Number(game.attributes?.field_is_flash_game) === 1
    ? true
    : false;

  const flashUrl = included && included.find((field) => field.id === game.relationships?.field_flash_file?.data?.id) || '';

  const embedUrl = isFlash
    ? `${process.env.NEXT_PUBLIC_DRUPAL_API_URL}${flashUrl.attributes?.uri?.url}`
    : game.attributes?.field_html5_game_url;

  const totalCount = meta?.count || -1;

  const size: { width?: number, height?: number } = {};
  if (game.attributes.field_display_width) size.width = Number(game.attributes.field_display_width);
  if (game.attributes.field_display_height) size.height = Number(game.attributes.field_display_height);

  return {
    uid: game.id,
    nid: parseInt(game.attributes.drupal_internal__nid),
    created: game.attributes.created,
    changed: game.attributes.changed ?? game.attributes.created ?? null,
    published: game.attributes.published_at ?? game.attributes.created ?? null,
    title: String(game?.attributes?.title || 'Unknown Title').trim(),
    thumbnailUrl,
    videoThumbnailUrl,
    description,
    descriptionSimple,
    shortDescriptionSimple,
    metaDescription,
    rating,
    totalVotes,
    hits,
    path,
    status,
    embedUrl,
    canEmbed: convertTruthyValue(game.attributes?.field_embed),
    supportsMobile,
    supportedMobileOrientation,
    gamePassExclusive,
    instructions,
    developer,
    videoWalkthroughs,
    totalCount,
    genre,
    tags,
    alternateGameSuggestion,
    featuredMultiplayer,
    mmoSlideshowImage,
    maturityRating,
    isFlash,
    externalGameLink: game.attributes.field_external_game_link,
    ...size,
  };
}

export function formatGameTitles (result) {
  const games = [];

  const gameTitles = games.concat(
    result[ '0-9' ],
    result.A,
    result.B,
    result.C,
    result.D,
    result.E,
    result.F,
    result.G,
    result.H,
    result.I,
    result.J,
    result.K,
    result.L,
    result.M,
    result.N,
    result.O,
    result.P,
    result.Q,
    result.R,
    result.S,
    result.T,
    result.U,
    result.V,
    result.W,
    result.X,
    result.Y,
    result.Z
  );
  
  return gameTitles;
}

// #endregion

// #region Genre

export interface Genre {
  id: Id // uid
  thumbnailUrl: string 
  name: string
  totalCount: number
  path: string,
  description?: string
  descriptionLong?: string
}

export function formatGenre (genre, included, meta): Genre {
  const path = genre.attributes.path.alias.replace('/genre/', '').toLowerCase();
  const thumbnailField = included && included.find((field) => field.id === genre?.relationships?.field_genre_image?.data?.id);
  const thumbnailUrl = thumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
    : '/images/defaults/IndexThumbnail.png';
  const totalCount = meta?.count || -1;

  const description = parse(genre.attributes?.description?.processed || 'No description available.').text;
  const descriptionLong = parse(genre.attributes.field_long_description_genre?.value || 'No description available.').text;
  
  return {
    id: genre.id,
    name: String(genre?.attributes?.name || 'Unknown Genre').trim(),
    thumbnailUrl,
    totalCount,
    path,
    description,
    descriptionLong
  };
}

// #endregion

// #region Tag
export interface Tag {
  id: Id // uid
  thumbnailUrl: string 
  name: string
  description?: string
  descriptionLong?: string
  totalCount: number
  path: string
}

export function formatTag (tag, included, meta): Tag {
  const path = tag.attributes.path.alias.toLowerCase();
  const thumbnailField = included && included.find((field) => field.id === tag.relationships?.field_tag_image?.data?.id);
  const thumbnailUrl = thumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
    : '/images/defaults/IndexThumbnail.png';
  const totalCount = meta?.count || -1;

  const description = parse(tag.attributes.description?.value.replace(/(<([^>]+)>)/gi, '') || 'No description available.').text;
  const descriptionLong = parse(tag.attributes.field_long_description_tags?.value.replace(/(<([^>]+)>)/gi, '') || 'No description available.').text;

  return {
    id: tag.id,
    name: String(tag?.attributes?.name || 'Unknown Tag').trim(),
    description,
    descriptionLong,
    thumbnailUrl,
    totalCount,
    path
  };
}

// #endregion

// #region User

export interface User {
  id: Id
  uid: NumericId
  userName: string
  displayName: string
  email: string
  status: boolean
  hasGamePass: boolean
  emailOptIn: boolean
  userPicture: string
  isAdmin: boolean
  created: string
  path: string
}

export function formatUser (user, included): User | { error: any } {
  if(!user) return {
    error: 'notFound'
  };

  const avatarField = included && included.find((field) => field.id === user.relationships?.user_picture?.data?.id);
  const avatarUrl = avatarField?.attributes?.uri?.url ?? null;

  const userRoleIds = user.relationships?.roles?.data?.map(role => role.id);
  const roleFields = included?.filter((field) => userRoleIds?.includes(field.id));

  const isAdmin = roleFields?.some(role => role.attributes?.is_admin) ?? false;
  const hasGamePass = roleFields?.some(role => role.attributes?.drupal_internal__id === 'game_pass_subscriber') ?? false;

  return {
    id: user.id,
    uid: user.attributes.drupal_internal__uid ?? null,
    userName: user.attributes.name ?? null,
    displayName: user.attributes.display_name,
    email: user.attributes.mail ?? null,
    status: user.attributes.status ?? false,
    hasGamePass,
    emailOptIn: user.attributes.field_opt_in,
    userPicture: avatarUrl, 
    isAdmin,
    created: user.attributes.created ?? 0,
    path: `/users/${user.attributes.name}`
  };
}

// #endregion

// #region Review

export interface Review {
  id: Id
  uuid: string,
  rating: (-1 | 1)
  authorProfileUrl: string,
  authorName: string
  authorAvatarUrl: string
  review: string,
  gameTitle: string,
  gameUrl: string,
  gameThumbnailUrl: string,
  gameVideoThumbnailUrl: string,
  created: string
}

export function formatReview (review, included?:{ user?: User, game?: Game | GameBasic }): Review {
  return {
    id: review.comment_id,
    uuid: review.comment_uuid,
    rating: parseInt(review.field_comment_thumb_vote) <= 0 ? -1 : 1,
    authorProfileUrl: review.user_link ? review.user_link : '/',
    authorName: review.username ?? included?.user?.displayName,
    authorAvatarUrl: review.user_picture ?? included?.user?.userPicture,
    review: review.comment_body,
    gameTitle: review.game_title ?? included?.game?.title, 
    gameUrl: review.game_path ?? included?.game?.path,
    gameThumbnailUrl: review.field_thumbnail ?? included?.game?.thumbnailUrl,
    gameVideoThumbnailUrl: review.field_video_thumbnail ?? included?.game?.videoThumbnailUrl,
    created: (new Date(review.created)).toISOString()
  };
}

// #endregion

// #region Playlist

export interface PlaylistBasic {
  id: Id,
  nid: NumericId, 
  title: string,
  description: string,
  longDescriptionSimple: string,
  thumbnailUrl: string,
  created: string,
  path: string
}

export interface Playlist extends PlaylistBasic {
  games: Game[]
}

export function formatPlaylistBasic (playlist, included): PlaylistBasic {
  const longDescriptionSimple = parse(playlist.attributes?.field_long_description?.processed ?? 'No description available.').text ?? 'No description available';

  const description = playlist.attributes?.field_playlist_description || 'No description available';

  const thumbnailField = included && included.find((field) => field.id === playlist.relationships?.field_thumbnail?.data?.id);
  const thumbnailUrl = thumbnailField?.attributes?.uri?.url
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + thumbnailField.attributes.uri.url
    : '/images/180x117.png';

  const path = playlist.attributes?.path?.alias?.replace(/\.jsp$/i, '');

  return {
    id: playlist.id ?? '',
    nid: parseInt(playlist.attributes?.drupal_internal__nid) ?? 0,
    title: String(playlist.attributes?.title ?? 'Untitled Playlist').trim(),
    path,
    created: playlist.attributes?.created,
    description,
    longDescriptionSimple,
    thumbnailUrl
  };
}

export function formatPlaylist (playlist, included): Playlist {
  let games: Game[] = [];

  const gameIds = playlist.relationships?.field_games?.data?.map((relationships_game) => relationships_game.id);

  if (gameIds?.length > 0) {
    games = included?.filter((item) => gameIds.includes(item.id))
      .map((game) => formatGame(game, included, {})) ?? [];
  }

  return {
    ...formatPlaylistBasic(playlist, included),
    games
  };
}

// #endregion
