import { createNetworkApi, Response } from '@/common/util/networkRequest';
import apiInstance from './instance';

export interface SubscriptionPlan {
  name: string,
  price: number,
  description: string
}

interface SubscriptionApi {
  registerSubscribe: (token: string, firstName: string, lastName: string, username: string, email: string, password: string, plan: string, postalCode: string, coupon: string) => Promise<Response<string>>

  authSubscribe: (token: string, firstName: string, lastName: string, plan: string, postalCode: string, coupon: string) => Promise<Response<string>>

  update: (plan: string) => Promise<Response<string>>

  get: () => Promise<Response<string>>

  getPlan: (plan:string) => Promise<Response<SubscriptionPlan>> 

  listPlans: () => Promise<Response<string>> 
}

export default createNetworkApi<SubscriptionApi> (apiInstance, {
  registerSubscribe: (token, firstName, lastName, username, email, password, plan, postalCode, coupon) => ({
    url: '/ag/api/register-subscription',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      token: token,
      first_name: firstName,
      last_name: lastName,
      username: username,
      email: email,
      password: password,
      plan: plan,
      postal_code: postalCode,
      coupon,
    },
    transform: (data) => data
  }),

  authSubscribe: (token, firstName, lastName, plan, postalCode, coupon) => ({
    url: '/ag/api/auth-subscription',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      token: token,
      first_name: firstName,
      last_name: lastName,
      plan: plan,
      postal_code: postalCode,
      coupon,
    },
    transform: (data) => data
  }),

  update: (plan) => ({
    url: '/ag/api/update-subscription',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      plan: plan
    },
    transform: (data) => data
  }),

  get: () => ({
    method: 'get',
    url: '/ag/api/user-subscription',
    transform: (result) => result
  }),

  getPlan: (plan) => ({
    method: 'get',
    url: `/ag/api/plan/${plan}`,
    transform: (result) => ({
      ...result,
      price: parseFloat(result.price) * 100
    })
  }),

  listPlans: () => ({
    method: 'get',
    url: '/ag/api/plans',
    transform: (result) => result.reverse()
  }),
});
