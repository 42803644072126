import { MutableRefObject, useEffect } from 'react';

function validateOpts (refOpts) {
  if (!refOpts) throw new Error(`Must provide a refOptions param in useOutsideClick options.`);
  if (typeof refOpts !== 'object') throw new Error(`ref opts is expecting an object.`);
  let excludeRefs;
  let ref;

  if ('excludeRefs' in refOpts) {
    ({ excludeRefs, ref } = refOpts);
    // ensure excludeRefs stays a 1 dimensional array in case they pass in a single ref object.
    excludeRefs = [ excludeRefs ].flat();
    if (!ref) throw new Error(`If you are providing an options object as the parameter it must include a ref value`);
  } else {
    ref = refOpts;
    excludeRefs = [];
  }
  return { ref, excludeRefs };
}

type ExcludedRef = HTMLElement | { matches: string }

interface ClickOutsideOptions {
  ref: MutableRefObject<HTMLElement | null>
  excludeRefs?: ExcludedRef[]
}

export function useClickOutside (refOpts: ClickOutsideOptions, callback: () => void) {
  const { ref, excludeRefs } = validateOpts(refOpts);

  const handleClick = (evt) => {
    if (
      ref.current && 
      !ref.current.contains(evt.target) && 
      !excludeRefs.find(exRef => {
        if (exRef.matches && evt.target.matches(exRef.matches)) return true;
        return exRef.current === evt.target;
      })
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
