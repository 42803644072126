import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
export { FieldType } from '@/common/util/validateForm';

interface FormProps extends IntrinsicProps {
  onSubmit: () => void
}

const Form = createComponent<FormProps>('Form', {}, function Form ({ className, style }, props) {
  const submit = (evt) => {
    evt.preventDefault();
    if (props.onSubmit) props.onSubmit();
  };

  return (
    <form className={className} style={style} onSubmit={submit} noValidate>
      {props.children}
    </form>
  );
});
export default Form;