import { formatReview, Game, GameBasic, Id, Review, User } from '@/util/formatters';
import { createNetworkApi, Response, toQuery } from '@/common/util/networkRequest';

import apiInstance from './instance';
import truncateTextToWord from '@/common/util/truncateText';

interface ReviewApi {
  
  getReviewByGame: (game: Game | GameBasic, items_per_page?: number, offset?: number) => Promise<Response<Review[]>>;
  
  // The API only returns the game-specific data when querying by user, so we
  // need to inject the user fully-hydrated user object here
  listByUser: (user: User, items_per_page?: number, offset?: number) => Promise<Response<Review[]>>;

  reviewGame: (gameId: Id, rating: -1 | 1, body: string, subject?: string) => Promise<Response<void>>;

}

export const REVIEW_LIMIT = 10;
export const SUBJECT_LENGTH = 120;

export default createNetworkApi<ReviewApi>(apiInstance, {
  getReviewByGame: (game: Game | GameBasic, items_per_page = REVIEW_LIMIT, offset = 0) => ({
    method: 'get',
    url: `/ag/api/game-reviews/${game.nid}/?items_per_page=${items_per_page}&offset=${offset}`,
    transform: (results) => results.map(result => formatReview(result, { game }))
  }),
  
  listByUser: (user: User | null, items_per_page = REVIEW_LIMIT, offset = 0) => ({
    method: 'get',
    headers: {
      'accept': 'application/json'
    },
    url: toQuery(`/ag/api/user-reviews/${user.uid}`, {
      items_per_page,
      offset
    }),
    transform: (results) => results.map(review => formatReview(review, { user }))
  }),

  reviewGame: (gameId: Id, rating: -1 | 1, body: string, subject?: string) => {
    body = body.trim();

    if(!subject || subject?.trim() === '') {
      subject = truncateTextToWord(body, SUBJECT_LENGTH);
    }

    const data = {
      'subject': [
        {
          'value': subject
        }
      ],
      'comment_body': [
        {
          'value': body,
          'format': 'plain_text'
        }
      ],
      'comment_type': [
        {
          'target_id': 'comment_node_game'
        }
      ],
      'entity_type': [
        {
          'value': 'node'
        }
      ],
      'entity_id': [
        {
          'target_id': gameId
        }
      ],
      'field_name': [
        {
          'value': 'comment_node_game'
        }
      ],
      'field_comment_thumb_vote': [
        {
          'value': rating === 1
        }
      ]
    };

    return {
      method: 'post',
      url: '/comment',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        _format: 'json'
      },
      data
    };
  }

});