import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface ControlProps extends IntrinsicProps {
  iconsLeft?: boolean
  iconsRight?: boolean
  loading?: boolean
}

const controlStates = [
  'iconsLeft',
  'iconsRight',
  'loading'
];

export default createComponent<ControlProps>('Control', 
{ classStates: controlStates }, 
function Control ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
