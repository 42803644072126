import { useRef, useEffect } from 'react';

/*
  Utility to have an effect that only gets run under specific circumstances.

  callback - The hook function. If it returns a function, it is used as the deregistration function as normal and the callback will only run once until allowed to reset. Otherwise, the callback's return value is used to tell effectOnce whether to continue running the callback. Returning true cancels further runs of the effect.
  reset - A function that gets run whenever an effect hook would normally run - if it returns true, the callback function will be allowed to run the next time the hook is invoked.
  props - An array of values to watch for changes passed to the effect hook as normal.
*/
export function useEffectOnce (callback, reset, props) {
  const useEffectRef = useRef(false);

  if (!props) {
    props = reset
    reset = null
  }

  useEffect(() => {
    let offEffect;

    if (!useEffectRef.current) {
      const result = callback();
      if (typeof result === 'function') {
        offEffect = result;
        useEffectRef.current = true;
      } else {
        useEffectRef.current = result;
      }
    }

    if (reset && reset()) useEffectRef.current = false;

    if (offEffect) return offEffect;
  }, props);
}
