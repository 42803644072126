import { clamp } from '@/common/util/math';

export enum FieldType {
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  LIST = 'list',
  ANY = 'value',
  STRING = 'string',
  PASSWORD = 'password',
  PASSWORD_VERIFY = 'passwordVerify',
  EMAIL = 'email'
}

// TODO: one day bring these in from somewhere for internationalization 
const messages = {
  required: () => 'Required.',
  invalidNumber: () => 'Must be a number.',
  invalidEmail: () => 'Must be a valid email.',
  invalidCharacters: (characters: string[]) => `Invalid characters: ${characters.map(c => `"${c}"`).join(', ')}`,
  invalidUnicode: () => 'Unicode is not allowed.',
  stringTooShort: (minLength) => `Must be at least ${minLength} characters long.`,
  stringTooLong: (maxLength) => `Must be at most ${maxLength} characters long.`,
  selectAtLeast: (minRequired) => `Select at least ${minRequired} options.`,
  selectAtMost: (maxAllowed) => `Select up to ${maxAllowed} options.`,
  selectBetween: (minRequired, maxAllowed) => `Select between ${minRequired} and ${maxAllowed} options.`,
  passwordMismatch: () => 'Passwords do not match.',
  insecurePassword: () => 'Please choose a more secure password.'
};

// Make a note to update this periodically
// https://en.wikipedia.org/wiki/List_of_the_most_common_passwords
const insecurePasswords = [
  '123456',
  '123456789',
  'qwerty',
  'password',
  '1111111',
  '12345678',
  'abc123',
  '1234567',
  'password1',
  '12345',
  '1234567890',
  '123123',
  '000000',
  'Iloveyou',
  '1234',
  '1q2w3e4r5t',
  'Qwertyuiop',
  '123',
  'Monkey',
  'Dragon'
];

export function validateField (value, otherValue, schema?) /* (value, schema) */ {
  let error: any = false;

  if (!schema) {
    schema = otherValue;
  }
  if (!schema) {
    return { value };
  }

  // Number
  if (schema.type === FieldType.NUMBER) {
    value = Number(value);

    if (isNaN(value) || typeof value !== 'number') {
      error = messages.invalidNumber();
    }

    else if (typeof schema.minValue === 'number' || typeof schema.maxValue === 'number') {
      const minValue = (typeof schema.minValue === 'number')
        ? schema.minValue
        : Number.NEGATIVE_INFINITY;

      const maxValue = (typeof schema.maxValue === 'number')
        ? schema.maxValue
        : Number.POSITIVE_INFINITY;

      value = clamp(value, minValue, maxValue);
    }
  }

  // Boolean (like a checkbox)
  else if (schema.type === FieldType.BOOLEAN) {
    if (schema.required) {
      if (typeof schema.trueValue === 'undefined') {
        if (!value) error = messages.required();
      } else {
        if (value !== schema.trueValue) error = messages.required();
      }
    }
  }

  // Array or Object of values
  else if (schema.type === FieldType.LIST) {
    const minRequired = (typeof schema.minRequired === 'number')
      ? schema.minRequired
      : Number.NEGATIVE_INFINITY;

    const maxAllowed = (typeof schema.maxAllowed === 'number')
      ? schema.maxAllowed
      : Number.POSITIVE_INFINITY;

    const numSelected = Array.isArray(value)
      ? value.length
      : Object.keys(value).length;

    if (numSelected < minRequired && typeof schema.maxAllowed === 'undefined') {
      error = messages.selectAtLeast(minRequired);
    } else if (numSelected > maxAllowed && typeof schema.minRequired === 'undefined') {
      error = messages.selectAtMost(maxAllowed);
    } else if (numSelected < minRequired || numSelected > maxAllowed) {
      error = messages.selectBetween(minRequired, maxAllowed);
    }
  }

  // Generic fallback
  else if (schema.type === FieldType.ANY) {
    if (typeof value === 'string') {
      value = value.trim();

      // Check invalid characters
      if (schema.invalidCharacters) {
        const invalidCharacters = [];

        schema.invalidCharacters.forEach((char) => {
          if (value.includes(char)) {
            if (!invalidCharacters.includes(char)) {
              invalidCharacters.push(char);
            }
          }
        });

        if (invalidCharacters.length > 0) {
          error = messages.invalidCharacters(invalidCharacters);
        }
      }
    }
    if (schema.required && (value === null || value === undefined || value === '')) {
      error = messages.required();
    }
  }

  // Strings of various sorts
  else if (typeof value === 'string') {
    value = value.trim();

    // Check required
    if (schema.required && !value) {
      error = messages.required();
    } else {
      // Check min/max length
      if ((typeof schema.minLength === 'number' || typeof schema.maxLength === 'number') && schema.type !== FieldType.PASSWORD_VERIFY) {
        const minLength = (typeof schema.minLength === 'number')
          ? schema.minLength
          : Number.NEGATIVE_INFINITY;
  
        const maxLength = (typeof schema.maxLength === 'number')
          ? schema.maxLength
          : Number.POSITIVE_INFINITY;

        if (value.length < minLength) {
          error = messages.stringTooShort(minLength);
        } else if (value.length > maxLength) {
          error = messages.stringTooLong(maxLength);
        }
      }

      // Check valid characters
      if (schema.allowedCharacters) {
        const useRegex = new RegExp(`^${schema.allowedCharacters}+$`);
          
        if (!useRegex.test(value)) {
          const invalidCharacters = value.split('').filter((c: string) => !useRegex.test(c) ? c : null);
          error = messages.invalidCharacters(invalidCharacters);
        }
      }

      // Check invalid characters
      if (schema.invalidCharacters) {
        const invalidCharacters = [];

        schema.invalidCharacters.forEach((char) => {
          if (schema.type === FieldType.EMAIL) {
            const emailUsername = value.split('@')[ 0 ];
            if (emailUsername.includes(char)) {
              if (!invalidCharacters.includes(char)) {
                invalidCharacters.push(char);
              }
            }
          }
          else if (value.includes(char)) {
            if (!invalidCharacters.includes(char)) {
              invalidCharacters.push(char);
            }
          }
        });

        if (invalidCharacters.length > 0) {
          error = messages.invalidCharacters(invalidCharacters);
        }
      }

      // Check Unicode
      if (schema.restrictUnicode && value.match(/[^\u0000-\u00ff]/)) {
        error = messages.invalidUnicode();
      }

      if (!error) {
        // Email
        if (schema.type === FieldType.EMAIL) {
          const isValidEmail = !!value.match(/[^\s@]+@[^\s@]+\.[\w]{2,}/g);
          if (!isValidEmail) {
            error = messages.invalidEmail();
          }

          // Check Unicode
          if (value.match(/[^\u0000-\u00ff]/)) {
            error = messages.invalidUnicode();
          }
        }
        
        // Passwords
        else if (schema.type === FieldType.PASSWORD) {
          if (insecurePasswords.includes(value)) {
            error = messages.insecurePassword();
          } else if (value.match(/[^\u0000-\u00ff]/)) {
            error = messages.invalidUnicode();
          } else if (schema.criteria) {
            const message = [];
            const c = schema.criteria;
            
            if (c.specialChars) {
              const match = !!value.match(/[`~!@#$%^&*()_+{}|:"<>?[\]\\;',./_+]/g);
              if (!match) message.push('special character');
            }
            if (c.upperAndLower) {
              const matchLower = value.match(/[a-z]/g);
              const matchUpper = value.match(/[A-Z]/g);
              if (!matchLower || !matchUpper) message.push('lowercase and uppercase character');
            }
            if (c.number) {
              const match = !!value.match(/\d/g);
              if (!match) message.push('number');
            }

            if (message.length) {
              error = 'Must have at least one: ' + message.join(', ') + '.';
            }
          }
        }
        
        // Verify password
        else if (schema.type === FieldType.PASSWORD_VERIFY) {
          if (!value) {
            error = messages.required();
          } else if (value !== otherValue) {
            error = messages.passwordMismatch();
          }
        }
      }
    }
  }

  return { error, value };
}

export function validateForm (formData, formSchema) {
  return Object.keys(formData).reduce((acc, key) => {
    const value = formData[ key ];
    const schema = formSchema[ key ].schema;

    let result;

    if (!schema) {
      result = { value };
    } else {
      let otherValue;
      if (schema.type === FieldType.PASSWORD_VERIFY) {
        otherValue = formData[ schema.checkAgainst ];
      }
      result = validateField(value, otherValue, schema);
    }

    acc.errors[ key ] = result.error;
    acc.values[ key ] = result.value;

    return acc;
  }, { errors: {}, values: {} });
}
