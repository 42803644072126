import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export function useInactiveUser (callback: () => void, timeout: number = 30000, perPage?: boolean) {
  const router = useRouter();
  const lastRouteRef = useRef('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // if (process.env.NODE_ENV === 'development') return;

  const callbackHandler = () => {
    if (perPage) {
      const currentRoute = router.asPath;
      const previousRoute = lastRouteRef.current;

      lastRouteRef.current = currentRoute;

      if (currentRoute !== previousRoute) {
        callback();
      }
    } else {
      callback();
    }
  };

  useEffect(() => {
    const handler = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(callbackHandler, timeout);
    };

    document.addEventListener('mousemove', handler);
    document.addEventListener('keypress', handler);
    document.addEventListener('touchmove', handler);

    return () => {
      document.removeEventListener('mousemove', handler);
      document.removeEventListener('keypress', handler);
      document.removeEventListener('touchmove', handler);
    };
  });
}
