export default function ensureMinimum<T> (games: T[], min: number) {
  let i = 0;
  const length = games.length;

  if (length === 0) return games;

  while (games.length < min) {
    games.push(games[ i % length ]);
    i++;
  }

  return games;
}
