// Third-party
import { useState } from 'react';
import { useRouter } from 'next/router';
import { 
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

// First-party
import { 
  Slot, 
  If, 
  createComponent,
  IntrinsicProps
} from '@/common/util/templateHelpers';
import authApi from '@/apis/drupal/auth';
import { useForm } from '@/common/hooks/form';
import { useNotifications } from '@/common/hooks/notifications';

// Components
import Block from '@/common/components/Block';
import { IconFA } from '@/common/components/Icon';
import Button from '@/common/components/controls/Button';
import { 
  InputField,
  InputType 
} from '@/common/components/controls/Input';
import { 
  RecaptchaField, 
  RecaptchaTheme
} from '@/common/components/controls/Recaptcha';
import Form, { FieldType } from '@/common/components/forms/Form';
import Title from '@/common/components/Title';
import { useAuthStore } from '@/stores/auth';

interface RequestPasswordFormProps extends IntrinsicProps {
  captcha?: boolean
  captchaTheme?: RecaptchaTheme
  buttonText?: string
  onSuccess?: (data: RequestPasswordFormData) => void
  onError?: (errors: any) => void
  onSubmit?: (data: RequestPasswordFormData) => void
}

interface RequestPasswordFormData {
  email: string
}

export default createComponent<RequestPasswordFormProps>('RequestPasswordForm', {}, function RequestPasswordForm ({ className }, props) {
  const [ state ] = useAuthStore();

  const schema = {
    email: {
      value: (state.ready && state.user?.email) || '',
      schema: {
        type: FieldType.EMAIL,
        required: true,
        invalidCharacters: [ '+' ],
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const { notify } = useNotifications();
  const router = useRouter();
  const form = useForm(schema);
  const [ loading, setLoading ] = useState(false);
  const [ resetSent, setResetSend ] = useState(false);

  const submit = async () => {
    const { data, errors, hasErrors } = form.validate<RequestPasswordFormData>();
    if (hasErrors) return props.onError && props.onError(errors);

    setLoading(true);

    const resetPasswordResult = await authApi.requestReset(data.email);

    if (props.onSubmit) props.onSubmit(data)

    if (resetPasswordResult.error) {
      notify(resetPasswordResult.error.response?.data?.message, 'error');
      setLoading(false);
      if (props.onError) props.onError(resetPasswordResult.error)
    } else {
      notify(resetPasswordResult.message, 'success');
      setLoading(false);
      setResetSend(true);
      if (props.onSuccess) props.onSuccess(data);
    }
  };

  return (
    <>
      {
        If(resetSent, () => (
          <Title h4 size5>Your password reset has been sent. Please check your email.</Title>
        )).Else(() => (
          <>
            <Form className={className} onSubmit={submit}>
              <Block>
                <InputField name='email' type={InputType.EMAIL} form={form} placeholder='Email'>
                  <Slot name='icon'><IconFA icon={faEnvelope} /></Slot>
                </InputField>
              </Block>
              {
                If(props.captcha, () => (
                  <Block>
                    <RecaptchaField 
                      theme={props.captchaTheme || RecaptchaTheme.LIGHT} 
                      name='captchaResult' 
                      form={form} 
                      siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}>&nbsp;</RecaptchaField>
                  </Block>
                ))
                .EndIf()
              }
              <Block>
                <Button 
                  primary 
                  submit 
                  disabled={form.hasErrors} 
                  loading={loading}
                >
                  {props.buttonText || 'Request Password Reset'}
                </Button>
              </Block>
            </Form>
          </>
        )).EndIf()
      }
    </>
  );
});
