import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

interface DeleteProps extends IntrinsicProps {
  large?: boolean
}

const deleteStates = [
  'large'
];

export default createComponent<DeleteProps>('Delete', 
{ classStates: deleteStates }, 
function Delete ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onClick}>
      {props.children}
    </div>
  );
});
