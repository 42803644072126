import storage from '@/common/util/storage';
import { useModal } from '@/common/hooks/modal';
import { useNotifications } from '@/common/hooks/notifications';
import { useEffectOnce } from '@/common/hooks/effectOnce';
import { Page, Modals, Notifications } from '@/common/components';
import { useEffect } from 'react';
import { ByteBrew } from 'bytebrew-web-sdk';

export default function App(props) {
  const { useAuthStore, verifyAction, readyAction } = props;
  const [authState, authDispatch] = useAuthStore();

  // ✅ Initialize ByteBrew only on the client side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      ByteBrew.initializeByteBrew(
        'VR607GS9V',
        'ZcuX4VkonS4ZuyL8QwU/iMfl2vkcyNUKQ8jh4+e/EBPJR4oQsTUTtJGo8MPUMhSn',
        '1.0.0'
      );
      console.log('ByteBrew Initialized');
    }
  }, []);

  useEffectOnce(
    () => {
      const cached = storage.getLocal('auth');
      if (authState.auth || cached?.auth) {
        if (authState.auth) {
          authDispatch(verifyAction);
        }
      } else {
        authDispatch(readyAction);
      }
    },
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [authState.auth]
  );

  return (
    <Page theme={props.theme || 'default'}>
      <Modals />
      <Notifications />
      <div className="App">
        {props.children}
      </div>
    </Page>
  );
}

// ✅ Keep sub-components organized under App
App.Main = function AppMain(props) {
  return (
    <main className={['App__Main', props.padding && 'App__Main--padding'].join(' ')}>
      {props.children}
    </main>
  );
};

App.Header = function AppHeader(props) {
  return <header className="App__Header">{props.children}</header>;
};

App.Footer = function AppFooter(props) {
  return <footer className="App__Footer">{props.children}</footer>;
};

App.Provider = function AppProvider(props) {
  const { Provider: ModalProvider } = useModal();
  const { Provider: NotificationsProvider } = useNotifications();

  return (
    <ModalProvider>
      <NotificationsProvider>
        {props.authProvider ? <props.authProvider>{props.children}</props.authProvider> : props.children}
      </NotificationsProvider>
    </ModalProvider>
  );
};
