import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { createComponent, IntrinsicProps, toClassName } from '@/common/util/templateHelpers';

interface RouterLinkProps extends IntrinsicProps {
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  title?: string
  ariaLabel?: string
}

export default createComponent<RouterLinkProps>('RouterLink',
{},
function RouterLink ({ className, mergeClassNames, style }, props) {
  const router = useRouter();

  const [ computedClassName, setComputedClassName ] = useState(className);

  useEffect(() => {
    setComputedClassName(mergeClassNames(toClassName('RouterLink', { active: router.asPath === props.href })));
  });

  return (
    <Link href={props.href}>
      <a 
        className={computedClassName} 
        style={style} 
        onClick={props.onClick}
        target={props.target}
        title={props.title}
        aria-label={props.ariaLabel}
      >{props.children}</a>
    </Link>
  );
});
