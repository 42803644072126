import { formatUser, Id, User, NumericId } from '@/util/formatters';
import { createNetworkApi, Response, toQuery } from '@/common/util/networkRequest';

import apiInstance from './instance';

export const MAX_USERNAME_LENGTH = 60;

interface UserApi {
  list: () => Promise<Response<User[]>>
  getByUid: (userId: NumericId) => Promise<Response<User>>
  update: (userId: Id, user: any) => Promise<Response<any>>
  updateEmail: (email: string, password: string) => Promise<Response<any>>
  register: (userName: string, email: string, password: string, emailOpt: boolean) => Promise<Response<any>>
  plan: (userCSRF: string) => Promise<Response<any>>
}

export default createNetworkApi<UserApi> (apiInstance, {
  list: () => ({
    method: 'get',
    url: '/jsonapi/user/user',
    transform: (data) => data
  }),

  getByUid: (userId: NumericId) => ({
    method: 'get',
    url: toQuery('/jsonapi/user/user', {
      filter: {
        'uid][value': userId
      },
      include: [ 
        'roles',
        'user_picture'
      ]
    }),
    transform: (result) => formatUser(result.data[ 0 ], result.included)
  }),

  update: (userId: Id, opts) => {
    const data: any = {};

    if (opts.userName) data.name = [ { value: opts.userName } ];

    if (opts.email) data.mail = [ { value: opts.email } ];
    if (opts.userPicture) data.user_picture = [ { target_id: opts.userPicture } ];

    if (opts.password) data.pass = [ { existing: opts.password } ];
    if (opts.oldPassword) data.pass = [ { existing: opts.oldPassword }, { value: opts.passwordAgain } ];

    data.field_opt_in = [ { value:opts.agreeToEmail } ];
    
    return {
      method: 'patch',
      headers: {
        'Content-Type': 'application/json'
      },
      url: `/user/${userId}`,
      params: {
        _format: 'json'
      },
      data
    };
  },

  updateEmail: (email, password) => {
    return {
      method: 'post',
      url: '/ag/api/update-user-email',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        'email':email,
        'password': password
      },
      transform: (data) => data
    };
  },

  register: (name, mail, pass, emailOpt) => {
    return {
      method: 'post',
      url: '/user/registerpass',
      params: {
        _format: 'json'
      },
      data: {
        'name':[ { 'value': name } ],
        'mail':[ { 'value': mail } ],
        'pass':[ { 'value': pass } ],
        'field_opt_in': [ { 'value': emailOpt } ]
      },
      transform: (data) => data
    };
  },

  plan: (userCSRF) => ({
    method: 'get',
    headers: {
      'X-CSRF-Token': userCSRF
    },
    url: '/ag/api/user-plan',
    transform: (data) => data
  }),
});