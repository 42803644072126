import { Block, IconFA, ModalCard } from '@/common/components';
import { Button, Buttons, Field, FieldHelp, TextareaField } from '@/common/components/controls';
import { FieldType, Form } from '@/common/components/forms';
import { useForm } from '@/common/hooks/form';
import storage from '@/common/util/storage';
import { createComponent, If, IntrinsicProps, Slot } from '@/common/util/templateHelpers';
import { useAuthStore } from '@/stores/auth';
import { Game } from '@/util/formatters';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import reviewApi from '@/apis/drupal/review';
import { useNotifications } from '@/common/hooks/notifications';
import { useModal } from '@/common/hooks/modal';
import log from '@/common/util/dev/log';

interface ReviewProps {
  rating: number,
  reviewText: string;
}

interface ReviewGameModalProps extends IntrinsicProps {
  game: Game;
}

export default createComponent<ReviewGameModalProps>('ReviewGameModal',
  {}, 
  function ReviewGameModal ({ }, props) {
    const UNRATED = NaN;
    const GOOD = 1;
    const BAD = -1;

    const [ state ] = useAuthStore();
    const { notify } = useNotifications({ lifeSpan: 5000 });
    const { closeModal } = useModal();

    const [ isSendingReview, setIsSendingReview ] = useState(false);
    const [ rating, setRating ] = useState(UNRATED);

    const form = useForm({
      reviewText: {
        value: '',
        schema: {
          type: FieldType.STRING,
          restrictUnicode: true,
          required: true
        }
      },
      rating: {
        value: UNRATED,
        schema: {
          type: FieldType.NUMBER,
          required: true
        }
      }
    });

    const submit = async () => {
      const { errors, data, hasErrors } = form.validate<ReviewProps>();

      if (hasErrors || !(data.rating === 1 || data.rating === -1)) {
        if((data.rating === 1 || data.rating === -1) && !data.reviewText) {
          notify('Review text is required! If you don\'t want to write a review, please use the thumb buttons below the game player instead', 'error');
        } else {
          notify('Unable to submit your review - please check that you have filled out all of the fields', 'error');
        }

        log(`Form validation errors: ${errors}`);

        return;
      }

      setIsSendingReview(true);

      const response = await reviewApi.reviewGame(props.game.nid.toString(), data.rating, data.reviewText);

      setIsSendingReview(false);

      if (response.error) {
        notify('There was a problem submitting your review', 'error');

        log(`Error submitting review: ${response.error}`);

        return;
      }

      notify('Thanks for your review! It has been submitted to our moderators and will appear when it is approved.', 'success');

      closeModal();
    };

    const setRatingGoodOrBad = (goodOrBad) => {
      form.validateField('rating', goodOrBad);

      setRating(goodOrBad);
    };

    // If the user rated this game before opening the review modal, use that rating here
    useEffect(() => {
      if(!props.game) return;

      const userRating = storage.getLocal(`game-rated-${state.user?.id || 0}-${props.game.uid}`);

      if (userRating !== null) {
        setRatingGoodOrBad(Number(userRating));
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ state.user?.id, props.game ]);

    if (!props.game) return (<></>);

    return (
      <ModalCard>
        <Slot name='title'>Review {props.game.title}</Slot>
        <Form onSubmit={submit}>
          <Block>
            <Field>
              <Buttons>
                <Button active={rating === GOOD} disabled={isSendingReview} onClick={() => setRatingGoodOrBad(GOOD)}>
                  <IconFA icon={faThumbsUp} /> <span>I like it</span>
                </Button>
                <Button active={rating === BAD} disabled={isSendingReview} onClick={() => setRatingGoodOrBad(BAD)}>
                  <IconFA icon={faThumbsDown} /> <span>I don't like it</span>
                </Button>
              </Buttons>
              {
                If(form.errors.rating, () => (
                  <FieldHelp error={true}>
                    <span>Required.</span>
                  </FieldHelp>
                )).EndIf()
              }
            </Field>
          </Block>
          <Block>
            <TextareaField form={form} name='reviewText' placeholder='Say something about this game'>
              <Slot name='label'>Leave your review here</Slot>
            </TextareaField>
            <FieldHelp><span>Your review will be visible once it has been approved by a moderator.</span></FieldHelp>
          </Block>
          <Block>
            <Button primary submit disabled={form.hasErrors || isSendingReview} loading={isSendingReview}>Save Review</Button>
          </Block>
        </Form>
      </ModalCard>
    );
  });
