import { useEffect } from 'react';

import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import Flex from '@/common/components/Flex';

interface LayoutProps extends IntrinsicProps {
  fillScreen?: boolean
}

export default createComponent<LayoutProps>('Layout', 
  {}, 
  function Layout ({ className, style }, props) {
    useEffect(() => {
      if (props.fillScreen) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'scroll';
      }
    }, [ props.fillScreen ]);

    return (
      <Flex directionColumn fit className={className} style={style}>
        {props.children}
      </Flex>
    );
  });
